import actions from './actions';
import {
  conversationInsertStatus,
  extractAvatarLetter,
  filterTags,
  getMessageJSON,
  getPersonStatus,
  joinLivePersonRoom,
  orderTeamMatesByName,
  orderTeamsByName,
  setAssignee,
  modifyConversationObject,
  updateCountBasedOnRoles,
  getSortedSnippet,
  removeDuplicateChats,
  prependNonPriorityConversation,
  removeUnderscore,
} from '../../helpers/utility';
import moment from 'moment';
import { maxAttachmentQueueSize } from '../../components/Chat/Messages/constants';
import { message as notifier } from 'antd';
import { MOST_USED_TAGS_LIMIT } from '../../containers/App/constants';
import {
  conditionsDataTypes,
  conditionsMasterList,
  conversationStatusMasterList,
  conversationChannelMasterList,
  conversationRatingMasterList,
} from '../../components/Chat/Filters/constants';
import _, { countBy, cloneDeep } from 'lodash';
import { availableInboxWidgets } from '../../components/Chat/UserProperties/Widgets/constants';
import {
  changeAppData,
  getEnabledAndDisabledWidget,
} from '../../components/Chat/helper';
import { getCurrentPersonId } from '../../components/Chat/Conversations/helper';
import { orderOfColumns } from '../../components/Contacts/UsersListTable/constants';

const inboxViewDefaultData = {
  name: '',
  emoji: '💎',
  conditions: [
    {
      criteria: [
        {
          value: [],
          key: 'conversation_channel',
          condition: 'any',
          selectedType: 'multi_select',
          conditions: conditionsDataTypes.multi_select,
          staticValues: conversationChannelMasterList,
        },
      ],
    },
  ],
};

const initState = {
  // Inbox counts
  conversationInboxCount: {},
  openCount: null,
  pendingCount: null,
  closedCount: null,

  // Shared
  activeConversationId: null,
  activePersonId: null,
  allowMagicType: false,
  articles: [],
  teamMates: [],
  teams: [],
  bot: '', // Bot name, icon and count for Inboxes list
  userTagList: [],
  messageTagList: [],
  isEmptySnippets: true,
  isEmptyArticles: true,
  isEmptyChatRoom: true,
  isEmptyMessages: true,
  isEmptyGif: true,
  isOffline: false, // Check network status for Online/Offline banner
  fireBaseToken: null,
  notificationDetails: null,
  isEventTracked: false, // Check to see if tracking code is installed
  showLeftMenu: localStorage.getItem('filtersCollapsed') === 'true',
  showRightMenu: document.body.clientWidth > 1199,
  showNewConversation: false,

  // Empty state
  channelCurrentStep: 0,

  // Conversation List
  chatRooms: [], // Current Inbox conversations
  pageNo: 0,
  conversationLoadMore: false,
  conversationsMessageData: {},
  contactsOnlineStatuses: {},
  conversationSearchText: '',
  selectAllConversations: null,
  selectedConversations: [],
  inboxFilter: 'IB4',
  statusFilter: '', // Current selected Inbox status
  sortFilter: 'newest',
  priorityProcessingConversations: [],
  removedConversationIdentifier: null, // conversation identifier where the status change overlay is shown
  closedConversationOverlay: false,

  // Chat history
  selectedChatRoom: {}, // Current conversation
  selectedChatRoomMessages: {
    messages: [],
  }, // Current conversation messages
  currentConversationId: null,
  currentConversationAssignee: setAssignee(null),
  isEndUserTyping: false,
  endUserTypingStatus: {},
  agentTypingStatus: [],
  conversationTypingDetail: [],
  transcriptModalVisibility: false,
  transcriptModalLoader: false,
  timeKey: '', // Snooze
  timeValue: '', // Snooze
  deleteMessage: null,
  viewOriginalMessageModalVisibility: false,
  viewOriginalMessageId: null,
  viewOriginalMessageLoader: false,
  viewOriginalMessageData: null,

  // User profile
  userProfileData: {},
  selectedChatRoomPersonProfile: {}, // Current person profile
  currentPersonId: null,
  associatingVisitor: false,
  associateContact: false,
  tags: [], // Contact tags
  segments: [],
  campaigns: [],
  workflows: [],
  mostUsedContactTags: [],
  contactTagModalVisible: false,

  // Message composer
  // => Text editor
  activeComposerTabList: {},
  activeComposerTab: null,
  messageInitiatedFrom: 'null',
  currentReplyComposerValue: '',
  currentNoteComposerValue: '',
  isReplyEditorFocus: false,
  isNoteEditorFocus: false,
  agentTypedReplyMessages: {}, // To retain draft replies while navigating conversations
  agentTypedNoteMessages: {}, // To retain draft notes while navigating conversations
  editMessages: {}, // To retain edit mode content while navigating conversations

  // => GIF picker
  gifResource: [],
  gifOffset: null,

  // => Snippet picker
  snippets: [],
  mostRecentSnippets: [],
  singleSnippetData: {},
  snippetCategories: [],
  snippetDataOrder: 'default',
  snippetQueryString: '',

  // => Meetings picker
  meetingSchedulerList: [],
  meetingAgentId: null,
  agentTimingList: [],

  // => Attachments
  replyAttachmentQueues: {},
  noteAttachmentQueues: {},

  // ==> Article picker
  articleQueryString: '',

  // => Message tag picker
  currentSelectedMessageId: null,
  mostUsedChatTags: [],
  showMessageTagModal: false,

  // => Call widget
  popoverVisible: false,

  // => AI Compose
  isAiBotAssist: false,
  aiAssistReplyResponse: {},
  aiAssistNoteResponse: {},
  aiAssistReplyLoader: {},
  aiAssistNoteLoader: {},

  // Inbox views
  displayRulesPropertiesMetaData: {},
  allKeysOfDisplayRules: [],
  allPropertiesListOfInboxAutomationConditions: [],
  propertiesMetaData: {},
  contactSegmentsData: [],
  contactTagsData: [],
  chatTagsData: [],
  customInboxViewsList: [],
  currentInboxViewData: inboxViewDefaultData,
  isShowCreateViewModal: false,
  inboxViewCount: {},
  tempInboxViewCount: {},
  conversationPropertiesData: [],

  // Inbox Apps
  availableInboxWidgets: availableInboxWidgets,
  enabledInboxWidgets: availableInboxWidgets,
  disabledInboxWidgets: [],
  inboxAppsCanvasData: {},
  inboxAppDataLoader: [],
  inboxWidgetPreference: [],
  inboxHiddenWidgets: [],
  inboxAppsSheetData: {},

  // Loading indicators
  chatRoomSkeletonLoading: true,
  chatInfiniteScrollLoading: false,
  conversationsLoader: {},
  userProfileSkeletonLoading: true,
  replySending: {},
  noteSending: {},
  userPropertiesLoader: [],
  conversationStatusUpdateLoadingState: [], // conversation identifiers where the status change icons are in loading state
  conversationPropertiesDrawerLoader: false,
  viewModalLoader: false,
  fetchSnippetDataLoader: false,
  searchSnippetListLoader: false,
  inboxAppsCanvasLoading: {},
  markNotSpamLoader: false,
  callBtnLoading: false,

  // Previous conversation
  previousConversations: {},
  previousConversationsCount: {},
  prevConversationLoadMore: {},
  searchPastConversationLoader: {},
  isFromPreviousConversation: false,
  selectedPreviousConversation: null,

  selectedPreviousConversationIdentifier: null, // TODO Jitta - Change to active conversation
  activePastConversationComposerTab: 'reply', // TODO Jitta - make it conversation specific

  // AI Copilot
  aiAssistMode: 'default',
  askEmmaMode: 'ask_emma',
  askEmmaMatchFound: {},
  askEmmaGeneratedTexts: {},
  askEmmaLoader: {},
  askEmmaModalVisible: false,
  emmaConversationLoader: false,

  copilotMessages: {},
  copilotInfiniteLoading: false,
  copilotEmmaLoader: {},
  copilotSuggestions: {},
  copilotSuggestionsLoader: {},
  showSuggestions: false,
  isFromCopilot: false,

  // Inbox view/layout
  inboxViewMode: '',
  inboxTableColumns: [],
  allInboxTableColumns: [],
  allPropertyKeys: [],

  isChatRoomsLoading: false,
  redirectingToSearch: false,
  selectedPreviousChatMessages: {},
  assignedToFilter: '',
  participantFilter: '',
  tagFilter: '',
  startedDate: null,
  endDate: null,
  contactsFilter: '',
  channelFilter: '',
  conversationDrawerVisible: false,
  isPreviousConversationDrawerVisible: false,
  lastConversationId: null,
  selectedTableConversations: [],
  searchStatus: false,

  conversationSourceDetails: {},
  conversationSourceVisible: false,
  conversationSourceLoading: false,
  conversationSourceError: null,
};

function constructContactsTable(value, key) {
  let columns = {
    title: removeUnderscore(key),
    dataIndex: key,
  };
  return columns;
}
function getStaticValue(key) {
  if (key === 'conversation_channel') {
    return conversationChannelMasterList;
  } else if (key === 'conversation_status') {
    return conversationStatusMasterList;
  } else if (key === 'conversation_rating') {
    return conversationRatingMasterList;
  } else {
    return [];
  }
}

export default function chatReducer(state = initState, action) {
  switch (action.type) {
    case actions.LOADER_FOR_CHAT_ROOMS:
      return {
        ...state,
        chatRoomSkeletonLoading: action.payload,
      };
    case actions.LOADER_FOR_CHAT_HISTORY:
      let { cid, status } = action.payload;
      return {
        ...state,
        conversationsLoader: { ...state.conversationsLoader, [cid]: status },
      };
    case actions.LOADER_FOR_USER_PROFILE:
      return {
        ...state,
        userProfileSkeletonLoading: action.payload,
      };
    case actions.FETCH_CONVERSATIONS:
      return {
        ...state,
        conversationLoadMore: !action.avoidLoading,
        isChatRoomsLoading: true,
      };
    case actions.FETCH_CONVERSATIONS_FAILURE:
      return {
        ...state,
        conversationLoadMore: false,
        isChatRoomsLoading: false,
      };
    case actions.FETCH_TIME_BASED_CONVERSATIONS_SUCCESS: {
      const { conversations } = action.payload.data;
      if (action.filter === state.inboxFilter) {
        if (conversations.some(({ id }) => id === state.activeConversationId)) {
          return {
            ...state,
            chatRooms: conversations,
          };
        }
        let chatRooms = [
          ...conversations,
          state.chatRooms.find(
            (conversation) =>
              conversation.conversation_identifier ===
              state.activeConversationId,
          ),
        ];
        chatRooms = removeDuplicateChats(chatRooms);
        return {
          ...state,
          chatRooms,
        };
      }
      break;
    }
    case actions.FETCH_CONVERSATIONS_SUCCESS: {
      let statusFilter = '',
        {inboxFilter} = state,
        activeConversation = state.chatRooms.find(
          (conversation) =>
            conversation.conversation_identifier === state.activeConversationId,
        );
      if (
        action.payload.data.status !== '' &&
        action.payload.data.status !== undefined &&
        action.payload.data.status !== null
      ) {
        statusFilter = action.payload.data.status;
      }

      const { conversations, only_spam } = action.payload.data,
        project_secret = action.project_secret,
        isAllowedLiveView = action.isAllowedLiveView;

      let contactsOnlineStatuses = _.cloneDeep(state.contactsOnlineStatuses);

      if (only_spam) {
        inboxFilter = 'IB5';
      }

      if (conversations.length && isAllowedLiveView) {
        conversations.forEach((conversation) => {
          if (conversation.person) {
            let contactOnlineStatus = {
              id: conversation.person.id,
              status: getPersonStatus(conversation.person.last_pinged_at),
            };

            if (!contactsOnlineStatuses[conversation.person.id]) {
              contactsOnlineStatuses[conversation.person.id] =
                contactOnlineStatus.status;
              joinLivePersonRoom(conversation.person.id, project_secret);
            }
          }
        });
      }
      let chatRooms = [
        ...state.chatRooms,
        ...action.payload.data.conversations,
      ];
      chatRooms = removeDuplicateChats(chatRooms);
      return {
        ...state,
        inboxFilter,
        chatRooms,
        removedConversationIdentifier: null,
        openCount: action.payload.data.open_count,
        pendingCount: action.payload.data.pending_count,
        closedCount: action.payload.data.closed_count,
        currentPersonId: getCurrentPersonId(
          action.payload.data.current_person_id,
          activeConversation,
        ),
        chatRoomSkeletonLoading: false,
        isEmptyChatRoom: action.payload.data.conversations.length === 0,
        statusFilter,
        allowMagicType: action.payload.data.allow_magic_type,
        isEventTracked: action.payload.data.event_tracked,
        conversationLoadMore: false,
        contactsOnlineStatuses: contactsOnlineStatuses,
        isChatRoomsLoading: false,
        assignedToFilter: action.inbox_search_view_filters.assigned_to_filter,
        tagFilter: action.inbox_search_view_filters.tag_filter,
        participantFilter: action.inbox_search_view_filters.participant_id,
        startedDate: action.inbox_search_view_filters.started_date,
        endDate: action.inbox_search_view_filters.end_date,
        contactsFilter: action.inbox_search_view_filters.contacts_filter,
        channelFilter: action.inbox_search_view_filters.channel_filter,
      };
    }
    case actions.CLEAR_CONVERSATION:
      return {
        ...state,
        inboxFilter: '',
        statusFilter: '',
        conversationSearchText: '',
        redirectingToSearch: state.redirectingToSearch,
        assignedToFilter: '',
        tagFilter: '',
        sortFilter: 'newest',
        chatRooms: [],
        lastConversationId: null,
      };
    case actions.FETCH_MESSAGES_SUCCESS: {
      let conversationsMessageData = _.cloneDeep(
        state.conversationsMessageData,
      );
      conversationsMessageData[
        action.payload.data.messages[0].conversation_identifier
        ] = action.payload.data;
      return {
        ...state,
        selectedChatRoomMessages: action.payload.data,
        currentConversationAssignee: setAssignee(
          action.payload.data.assigned_to,
          action.currentUserId,
        ),
        isEmptyMessages: action.payload.data.messages.length < 30,
        conversationsMessageData: conversationsMessageData,
      };
    }
    case actions.PREPEND_MESSAGES: {
      let conversationsMessageData = _.cloneDeep(state.conversationsMessageData);
      conversationsMessageData[
        action.payload.data.messages[0].conversation_identifier
        ].messages = action.payload.data.messages.concat(
        conversationsMessageData[
          action.payload.data.messages[0].conversation_identifier
          ].messages,
      );

      return {
        ...state,
        selectedChatRoomMessages:
          conversationsMessageData[
            action.payload.data.messages[0].conversation_identifier
            ],
        isEmptyMessages: action.payload.data.messages.length < 30,
        conversationsMessageData: conversationsMessageData,
      };
    }
    case actions.FETCH_PROFILE_SUCCESS: {
      let userProfileData = _.cloneDeep(state.userProfileData);

      userProfileData[action.payload.data.profile.person_id] =
        action.payload.data;
      return {
        ...state,
        selectedChatRoomPersonProfile: action.payload.data,
        userProfileSkeletonLoading: false,
        userProfileData: userProfileData,
        currentPersonId: action.payload.data.profile.person_id,
      };
    }
    case actions.FETCH_PROFILE_FAILURE:
      return {
        ...state,
        selectedChatRoomPersonProfile: {},
      };
    case actions.SET_REPLY_COMPOSERS_VALUE:
      return {
        ...state,
        agentTypedReplyMessages: {
          ...state.agentTypedReplyMessages,
          [action.conversationId]: action.payload,
        },
      };
    case actions.SET_NOTE_COMPOSERS_VALUE:
      return {
        ...state,
        agentTypedNoteMessages: {
          ...state.agentTypedNoteMessages,
          [action.conversationId]: action.payload,
        },
      };
    case actions.SET_CURRENT_COMPOSERS_VALUE:
      return {
        ...state,
        currentReplyComposerValue: action.payload.reply,
        currentNoteComposerValue: action.payload.note,
      };
    case actions.SET_SELECTED_CHAT_ROOM: {
      let removedConversationIdentifierTemp =
          state.removedConversationIdentifier,
        closedConversationOverlay = state.closedConversationOverlay;

      if (
        action.chatRoom.conversation_identifier !==
        removedConversationIdentifierTemp
      ) {
        removedConversationIdentifierTemp = null;
        closedConversationOverlay = false;
      }
      return {
        ...state,
        selectedChatRoom: action.chatRoom,
        currentConversationId: action.chatRoom.conversation
          ? action.chatRoom.conversation.conversation_identifier
          : action.chatRoom.conversation_identifier,
        activeConversationId: action.chatRoom.conversation
          ? action.chatRoom.conversation.conversation_identifier
          : action.chatRoom.conversation_identifier,
        currentPersonId: action.chatRoom.conversation
          ? action.chatRoom.conversation.person_id
          : action.chatRoom.person_id,
        messageInitiatedFrom: action.chatRoom.message_initiated_from,
        removedConversationIdentifier: removedConversationIdentifierTemp,
        chatRooms: state.chatRooms.filter(
          (chatRoom) =>
            chatRoom.conversation_identifier !==
            state.removedConversationIdentifier,
        ),
        closedConversationOverlay,
        showNewConversation: false,
      };
    }
    case actions.EMPTY_CHAT_ROOM:
      return {
        ...state,
        chatRooms: [],
      };
    case actions.EMPTY_CHAT_HISTORY:
      return {
        ...state,
        activeConversationId: null,
        showNewConversation: false,
      };
    case actions.DELETE_CONVERSATION:
      return {
        ...state,
        activeConversationId: null,
        activePersonId: null,
        showNewConversation: false,
      };
    case actions.UPDATE_CONVERSATION_STATUS:
      const selectedChatRoom = state.selectedChatRoom;
      const previousConversationsStatus =
        state.previousConversations[state.currentPersonId];
      if (state.isFromPreviousConversation) {
        const previousConversationIndex = previousConversationsStatus.findIndex(
          (chatRoom) => chatRoom.conversation_identifier === action.cid,
        );
        if (previousConversationIndex !== -1) {
          previousConversationsStatus[previousConversationIndex].status =
            action.status;
        }
      }
      let conversationIdentifer = selectedChatRoom.conversation
        ? selectedChatRoom.conversation?.last_conversation_message
            ?.conversation_identifier
        : selectedChatRoom.last_conversation_message?.conversation_identifier;
      const selectedChatRoomStatus =
        conversationIdentifer === action.cid
          ? action.status
          : selectedChatRoom.last_conversation_message?.status;
      if (
        previousConversationsStatus &&
        previousConversationsStatus.length > 0
      ) {
        return {
          ...state,
          selectedChatRoom: {
            ...state.selectedChatRoom,
            status: selectedChatRoomStatus,
          },
          previousConversations: {
            ...state.previousConversations,
            [state.currentPersonId]: previousConversationsStatus,
          },
        };
      }
      return {
        ...state,
        selectedChatRoom: {
          ...state.selectedChatRoom,
          status: selectedChatRoomStatus,
        },
      };
    case actions.UPDATE_CHAT_ROOMS_CONVERSATION_STATUS: {
      const localChatRooms = cloneDeep(state.chatRooms);
      const chatRoomIndex = localChatRooms.findIndex(
        (chatRoom) =>
          chatRoom.conversation_identifier ===
          action.payload.conversation_identifier,
      );
      const previousConversation = state.previousConversations;
      const localPreviousConversationChatRooms =
        previousConversation[state.currentPersonId];
      if (state.isFromPreviousConversation) {
        const previousConversationIndex = localPreviousConversationChatRooms.findIndex(
          chatRoom =>
            chatRoom.conversation_identifier ===
            action.payload.conversation_identifier,
        );
        if (previousConversationIndex !== -1) {
          localPreviousConversationChatRooms[previousConversationIndex].status =
            action.payload.status;
        }
      }
      if (chatRoomIndex !== -1) {
        localChatRooms[chatRoomIndex].status = action.payload.status;
      }
      if (
        localPreviousConversationChatRooms &&
        localPreviousConversationChatRooms.length > 0
      ) {
        return {
          ...state,
          chatRooms: localChatRooms,
          previousConversations: {
            ...state.previousConversations,
            [state.currentPersonId]: localPreviousConversationChatRooms,
          },
        };
      }
      return {
        ...state,
        chatRooms: localChatRooms,
      };
    }
    case actions.PREVIOUS_CONVERSATION_ENABLED:
      return {
        ...state,
        isFromPreviousConversation: action.payload,
      };
    case actions.SELECTED_PREVIOUS_CONVERSATION:
      return {
        ...state,
        selectedPreviousConversation: action.payload,
      };
    case actions.UPDATE_REMOVED_CONVERSATION_IDENTIFIER:
      return {
        ...state,
        removedConversationIdentifier: action.payload,
        closedConversationOverlay: action.closedConversationOverlay,
      };
    case actions.UPDATE_CONVERSATIONS:
      return {
        ...state,
        chatRooms: action.payload.data,
      };
    case actions.UPDATE_SOCKET_ASSIGNMENT: {
      const messageData = action.payload.data;
      let conversationsMessageData = _.cloneDeep(
        state.conversationsMessageData,
      );

      if (
        messageData.message_type === 'conversation_assignment' &&
        conversationsMessageData[action.payload.data.conversation_identifier]
      ) {
        conversationsMessageData[
          action.payload.data.conversation_identifier
        ].assigned_to = messageData.assigned_to;
      }

      return {
        ...state,
        conversationsMessageData: conversationsMessageData,
      };
    }
    case actions.UPDATE_ALL_CONVERSATION_DATA: {
      let conversationsMessageData = _.cloneDeep(
        state.conversationsMessageData,
      );
      const messageData = _.cloneDeep(action.payload.data);

      // whether conversationsMessageData already has the conversation data
      if (
        conversationsMessageData &&
        conversationsMessageData[messageData.conversation_identifier] &&
        conversationsMessageData[messageData.conversation_identifier].messages
      ) {
        let messageIndex = conversationsMessageData[
          messageData.conversation_identifier
          ].messages.findIndex(
          (message) =>
            (messageData.id && message.id === messageData.id) ||
            (messageData.message_temp_id &&
              message.message_temp_id === messageData.message_temp_id),
        );

        if (messageIndex !== -1) {
          conversationsMessageData[
            messageData.conversation_identifier
          ].messages[messageIndex] = getMessageJSON(messageData);
        } else {
          conversationsMessageData[
            messageData.conversation_identifier
          ].messages.push(getMessageJSON(messageData));
        }
      }

      return {
        ...state,
        conversationsMessageData: conversationsMessageData,
      };
    }
    case actions.DELETE_FROM_ALL_CONVERSATION_DATA: {
      let conversationsMessageData = _.cloneDeep(
        state.conversationsMessageData,
      );
      const messageData = _.cloneDeep(action.payload);
      // whether conversationsMessageData already has the conversation data
      if (
        conversationsMessageData &&
        conversationsMessageData[messageData.conversation_identifier]
      ) {
        delete conversationsMessageData[messageData.conversation_identifier];
      }
      return {
        ...state,
        conversationsMessageData: conversationsMessageData,
      };
    }
    case actions.UPDATE_PARTICIPANTS_IN_CURRENT_CONVERSATION_DATA: {
      let selectedChatRoomMessagesTemp = _.cloneDeep(
        state.selectedChatRoomMessages,
      );
      const messageData = action.payload.data;

      let userId =
        messageData.user && messageData.user.id
          ? messageData.user.id
          : messageData.user_id;

      if (
        selectedChatRoomMessagesTemp.participant_ids &&
        !selectedChatRoomMessagesTemp.participant_ids.includes(userId)
      ) {
        selectedChatRoomMessagesTemp.participant_ids.push(userId);
      }

      return {
        ...state,
        selectedChatRoomMessages: selectedChatRoomMessagesTemp,
      };
    }
    case actions.UPDATE_PARTICIPANTS_IN_ALL_CONVERSATION_DATA: {
      let conversationsMessageData = _.cloneDeep(
        state.conversationsMessageData,
      );
      const messageData = action.payload.data;
      let userId;
      if (
        conversationsMessageData &&
        conversationsMessageData[messageData.conversation_identifier]
      ) {
        // Wheather converation data is present or not
        userId =
          messageData.user && messageData.user.id
            ? messageData.user.id
            : messageData.user_id;

        if (
          !conversationsMessageData[
            messageData.conversation_identifier
          ].participant_ids.includes(userId)
        ) {
          conversationsMessageData[
            messageData.conversation_identifier
          ].participant_ids.push(userId);
        }
      }

      return {
        ...state,
        conversationsMessageData,
      };
    }
    case actions.UPDATE_MESSAGE: {
      const modifiedChatRooms = cloneDeep(state.chatRooms).map((chatRoom) => {
        if (
          chatRoom.conversation_identifier ===
            action.payload.data.conversation_identifier &&
          action.payload.data.status === 'sent' &&
          [null, 'note', 'assignment_rule_response'].includes(
            action.payload.data.message_type,
          )
        ) {
          chatRoom.last_conversation_message = action.payload.data;
        }
        if (
          chatRoom.conversation_identifier ===
            action.payload.data.conversation_identifier &&
          action.payload.data.conversation &&
          chatRoom.conversation_type !==
            action.payload.data.conversation.conversation_type
        ) {
          chatRoom.conversation_type =
            action.payload.data.conversation.conversation_type;
        }
        return chatRoom;
      });
      const selectedConversationData = state.selectedChatRoomMessages;
      const messageIndex = selectedConversationData.messages.findIndex(
        (message) =>
          (action.payload.data.id && message.id === action.payload.data.id) ||
          (action.payload.data.message_temp_id &&
            message.message_temp_id === action.payload.data.message_temp_id),
      );
      let messages = selectedConversationData.messages;
      if (messageIndex !== -1) {
        messages[messageIndex] = action.payload.data;
      } else {
        messages = messages.concat([action.payload.data]);
      }
      const assignedTo = action.payload.data.hasOwnProperty('assigned_to')
        ? action.payload.data.assigned_to
        : selectedConversationData.assigned_to;

      return {
        ...state,
        selectedChatRoomMessages: {
          ...state.selectedChatRoomMessages,
          assigned_to: assignedTo,
          messages: messages,
        },
        currentConversationAssignee: setAssignee(
          assignedTo,
          action.currentUserId,
        ),
        selectedChatRoom: {
          ...state.selectedChatRoom,
          last_conversation_message: action.payload.data,
        },
        chatRooms: modifiedChatRooms,
      };
    }
    case actions.SET_SELECT_ALL_CONVERSATION: {
      let selectedConversation = state.chatRooms.flatMap((chatRoom) =>
        chatRoom.conversation_identifier !== state.removedConversationIdentifier
          ? [chatRoom.conversation_identifier]
          : [],
      );
      return {
        ...state,
        selectAllConversations: action.checkAll,
        selectedConversations:
          action.checkAll === true ? selectedConversation : [],
      };
    }
    case actions.ON_SELECT_CONVERSATION: {
      let selectedConversations = cloneDeep(state.selectedConversations);
      let selected = action.selectedConversation;
      let conversationIndex = selectedConversations.indexOf(
        selected.conversation_identifier,
      );
      action.checked
        ? selectedConversations.push(selected.conversation_identifier)
        : selectedConversations.splice(conversationIndex, 1);
      return {
        ...state,
        selectedConversations: selectedConversations,
        selectAllConversations: null,
      };
    }
    case actions.SET_SELECTED_TABLE_CONVERSATIONS: {
      return {
        ...state,
        selectedTableConversations: action.selectedTableConversations,
      };
    }
    case actions.SET_EDIT_MESSAGE: {
      let content = action.message.message_content,
        replyContent = '',
        noteContent = '';
      const typedCurrentConversation = state.currentConversationId;
      if (action.messageType === 'note') {
        noteContent = content;
      } else {
        replyContent = content;
      }

      return {
        ...state,
        editMessages: { [typedCurrentConversation]: action.message },
        agentTypedReplyMessages: {
          ...state.agentTypedReplyMessages,
          [typedCurrentConversation]: replyContent,
        },
        agentTypedNoteMessages: {
          ...state.agentTypedNoteMessages,
          [typedCurrentConversation]: noteContent,
        },
        currentReplyComposerValue:
          action.messageType !== 'note' ? action.message.message_content : '',
        currentNoteComposerValue:
          action.messageType === 'note' ? action.message.message_content : '',
      };
    }
    case actions.SET_SNIPPET_MESSAGE: {
      const setSnippetCoversationId = state.currentConversationId;
      if (action.isNote) {
        return {
          ...state,
          agentTypedNoteMessages: {
            ...state.agentTypedNoteMessages,
            [setSnippetCoversationId]: state.currentNoteComposerValue.concat(
              action.payload.data.message,
            ),
          },
          currentNoteComposerValue: state.currentNoteComposerValue.concat(
            action.payload.data.message,
          ),
        };
      } else {
        return {
          ...state,
          agentTypedReplyMessages: {
            ...state.agentTypedReplyMessages,
            [setSnippetCoversationId]: state.currentReplyComposerValue.concat(
              action.payload.data.message,
            ),
          },
          currentReplyComposerValue: state.currentReplyComposerValue.concat(
            action.payload.data.message,
          ),
        };
      }
    }
    case actions.EDIT_MESSAGE_SUCCESS: {
      const editedChatRoom = state.chatRooms.map((chatRoom) => {
        if (chatRoom.last_conversation_message.id === action.payload.data.id) {
          chatRoom.last_conversation_message = action.payload.data;
        }
        return chatRoom;
      });

      return {
        ...state,
        editMessages: {},
        chatRooms: editedChatRoom,
        selectedChatRoomMessages: {
          ...state.selectedChatRoomMessages,
          last_conversation_message: action.payload.data,
          messages: state.selectedChatRoomMessages.messages.map((message) => {
            if (message.id === action.payload.data.id) {
              message = action.payload.data;
            }
            return message;
          }),
        },
      };
    }
    case actions.EMMA_CONVERSATION_MESSAGES:
      return {
        ...state,
        emmaConversationLoader: true,
      };
    case actions.EMMA_CONVERSATION_MESSAGES_SUCCESS:
      return {
        ...state,
        selectedPreviousChatMessages: action.payload,
        emmaConversationLoader: false,
      };
    case actions.EMMA_CONVERSATION_MESSAGES_FAILURE:
      return {
        ...state,
        emmaConversationLoader: false,
      };
    case actions.DELETE_GIF:
      return {
        ...state,
        gifResource: [],
      };
    case actions.FETCH_GIF_SUCCESS:
      return {
        ...state,
        gifResource: state.gifResource.concat(action.payload.results),
        isEmptyGif: action.payload.results.length === 0,
        gifOffset: action.payload.next,
      };
    case actions.DELETE_SNIPPETS:
      return {
        ...state,
        snippets: [],
      };
    case actions.UPDATE_SNIPPET_DATA_SORT_ORDER: {
      const snippets = getSortedSnippet(action.order_by, state.snippets);
      return {
        ...state,
        snippetDataOrder: action.order_by,
        snippets,
      };
    }
    case actions.FETCH_SINGLE_SNIPPET_DATA_INBOX: {
      return {
        ...state,
        fetchSnippetDataLoader: true,
      };
    }
    case actions.FETCH_SINGLE_SNIPPET_DATA_INBOX_SUCCESS: {
      return {
        ...state,
        fetchSnippetDataLoader: false,
        singleSnippetData: action.snippetData,
      };
    }
    case actions.FETCH_SINGLE_SNIPPET_DATA_INBOX_FAILURE: {
      return {
        ...state,
        fetchSnippetDataLoader: false,
      };
    }
    case actions.FETCH_SNIPPETS_SUCCESS: {
      let snippetListData = cloneDeep(
        action.offset
          ? state.snippets.concat(action.snippets)
          : action.snippets,
      );
      let snippetCategoriesTemp = cloneDeep(action.snippetCategories);
      snippetListData = getSortedSnippet(
        state.snippetDataOrder,
        snippetListData,
      );

      let categoriesSnippetCounts = countBy(
        snippetListData,
        (snippet) => snippet.snippet_category_id || false,
      );

      if (snippetCategoriesTemp) {
        snippetCategoriesTemp.forEach((category, index) => {
          snippetCategoriesTemp[index].snippet_count =
            categoriesSnippetCounts[category.id];
        });
      }

      return {
        ...state,
        snippets: snippetListData,
        snippetCategories: snippetCategoriesTemp || state.snippetCategories,
        isEmptySnippets: action.snippets.length === 0,
        snippetQueryString: action.queryString,
        mostRecentSnippets: action.mostRecentSnippets,
        searchSnippetListLoader: false,
      };
    }
    case actions.FETCH_SNIPPETS_FAILURE:
      return {
        ...state,
        searchSnippetListLoader: false,
      };
    case actions.FETCH_ARTICLES_SUCCESS:
      return {
        ...state,
        articles: action.payload,
        isEmptyArticles: action.payload.length === 0,
        articleQueryString: action.queryString,
      };
    case actions.UPDATE_QUALIFICATION_PROPERTIES_SUCCESS:
      // TODO : update the converation data
      const responseData = action.payload.data;
      responseData.qualification.map((data) => {
        state.selectedChatRoomPersonProfile.profile[data.key] &&
          (state.selectedChatRoomPersonProfile.profile[data.key] = data.value);
        return null;
      });
      return {
        ...state,
        selectedChatRoomPersonProfile: {
          ...state.selectedChatRoomPersonProfile,
          profile: {
            ...state.selectedChatRoomPersonProfile.profile,
            person_chat_name: responseData.profile.person_chat_name,
            avatar_letter: responseData.profile.avatar_letter,
            avatar_url: responseData.profile.avatar_url,
          },
          qualification: responseData.qualification,
        },
        chatRooms: state.chatRooms.map((data) => {
          if (data.person_id === action.personId) {
            data.person.person_chat_name =
              responseData.profile.person_chat_name;
            data.person.avatar_url = responseData.profile.avatar_url;
            data.person.avatar_letter = responseData.profile.avatar_letter;
          }
          return data;
        }),
      };
    case actions.GET_USER_TAGS_SUCCESS:
      let mostUsedContactTagIds = action.payload.data.most_used_tags,
        allContactTagsList = action.payload.data.tags;
      let mostUsedContactTags = [];

      if (mostUsedContactTagIds.length) {
        for (let i = 0; i < mostUsedContactTagIds.length; i++) {
          mostUsedContactTags.push(
            allContactTagsList.find(
              (tag) => tag.id === mostUsedContactTagIds[i],
            ),
          );
        }
      }

      return {
        ...state,
        userTagList: allContactTagsList,
        mostUsedContactTags: mostUsedContactTags.slice(0, MOST_USED_TAGS_LIMIT),
      };
    case actions.ADD_USER_TAGS_SUCCESS: {
      // TODO : update the data to reducer
      const {
          userTagList,
          contactTagsData,
          selectedChatRoomPersonProfile,
          tags: stateTags,
        } = state,
        { profile } = selectedChatRoomPersonProfile,
        { tags } = action.payload.data,
        newTags = tags.filter(
          (data) => !userTagList.some(({ id }) => id === data.id),
        );
      let updatedTags = _.unionBy([...stateTags, ...tags], 'id');
      return {
        ...state,
        userTagList: [...newTags, ...userTagList],
        contactTagsData: [...newTags, ...contactTagsData],
        tags: updatedTags,
      };
    }
    case actions.REMOVE_USER_TAGS_SUCCESS:
      const { tags } = state,
        { tagId } = action;
      return {
        ...state,
        tags: tags.filter((tag) => tag.id !== tagId),
      };
    case actions.GET_CHAT_TAG_SUCCESS:
      let mostUsedChatTagIds = action.payload.mostUsedChatTags,
        allChatTagsList = action.payload.chatTags;
      let mostUsedChatTags = [];

      if (mostUsedChatTagIds.length) {
        for (let i = 0; i < mostUsedChatTagIds.length; i++) {
          mostUsedChatTags.push(
            allChatTagsList.find((tag) => tag.id === mostUsedChatTagIds[i]),
          );
        }
      }
      return {
        ...state,
        chatTagsData: allChatTagsList,
        messageTagList: action.payload.messageTag,
        mostUsedChatTags: mostUsedChatTags.slice(0, MOST_USED_TAGS_LIMIT),
      };
    case actions.UPDATE_MESSAGE_STATUS_SUCCESS:
      const unreadCountModifiedChatRooms = state.chatRooms.map((chatRoom) => {
        if (chatRoom.conversation_identifier === action.cid) {
          chatRoom.user_unread_message_count = 0;
        }
        return chatRoom;
      });
      return {
        ...state,
        chatRooms: unreadCountModifiedChatRooms,
        selectedChatRoom: {
          ...state.selectedChatRoom,
          user_unread_message_count: 0,
        },
        selectedChatRoomMessages: {
          ...state.selectedChatRoomMessages,
          messages: state.selectedChatRoomMessages.messages.map((message) => {
            if (message.id === action.payload.id) {
              message.status = action.payload.status;
            }
            return message;
          }),
        },
      };
    case actions.FETCH_ASSIGNEE_SUCCESS:
      let teams, teamMates;
      teams = orderTeamsByName(
        action.payload.teams,
        state.conversationInboxCount,
      );
      teamMates = orderTeamMatesByName(
        action.payload.team_mates,
        state.conversationInboxCount,
      );
      return {
        ...state,
        teamMates: teamMates,
        teams: teams,
        meetingSchedulerList: action.payload.scheduler,
        bot: { ...state.bot, ...action.payload.bot },
      };
    case actions.SET_FILTER:
      return {
        ...state,
        inboxFilter:
          action.payload.inbox !== undefined
            ? action.payload.inbox
            : state.inboxFilter,
        statusFilter:
          action.payload.status !== undefined
            ? action.payload.status
            : state.statusFilter,
        sortFilter: action.payload.sort
          ? action.payload.sort
          : state.sortFilter,
      };
    case actions.END_USER_TYPING_START:
      let endUserDetail = _.cloneDeep(state.conversationTypingDetail);
      const isFound = endUserDetail.findIndex(
        (result) =>
          result.cid === action.payload.cid && result.type === 'person',
      );
      if (isFound === -1) {
        endUserDetail.push(action.payload);
      } else {
        endUserDetail[isFound] = action.payload;
      }
      return {
        ...state,
        conversationTypingDetail: endUserDetail,
      };
    case actions.END_USER_TYPING_STOP:
      return {
        ...state,
        conversationTypingDetail: state.conversationTypingDetail.filter(
          (result) =>
            result.type === 'agent' || result.cid !== action.payload.cid,
        ),
      };
    case actions.AGENT_TYPING_START:
      let agentTypingDetails = _.cloneDeep(state.conversationTypingDetail);
      const isFoundAgent = agentTypingDetails.findIndex(function (result) {
        return (
          result.cid === action.payload.cid &&
          result.type === 'agent' &&
          result.data &&
          result.data.user_id === action.payload.data.user_id
        );
      });

      if (isFoundAgent === -1) {
        agentTypingDetails.push(action.payload);
      } else {
        agentTypingDetails[isFoundAgent] = action.payload;
      }
      return {
        ...state,
        conversationTypingDetail: agentTypingDetails,
      };
    case actions.AGENT_TYPING_STOP:
      return {
        ...state,
        conversationTypingDetail: state.conversationTypingDetail.filter(
          (result) =>
            result.type === 'person' ||
            (result.cid !== action.payload.cid &&
              result.data &&
              result.data.user_id !== action.payload.data.user_id),
        ),
      };

    case actions.CLEAR_TYPING_DETAIL:
      let detail = _.cloneDeep(state.conversationTypingDetail);
      if (action.payload.type === 'person') {
        detail = detail.filter(
          (result) =>
            result.type === 'agent' || result.cid !== action.payload.cid,
        );
      } else {
        detail = detail.filter(
          (result) =>
            result.type === 'person' ||
            (result.cid !== action.payload.cid &&
              result.data &&
              action.payload.data &&
              result.data.user_id !== action.payload.data.user_id),
        );
      }
      return {
        ...state,
        conversationTypingDetail: detail,
      };
    case actions.SET_CLEAR_ALL:
      if (state.chatRooms.length === 0) {
        return {
          ...state,
          selectedChatRoom: {},
          selectedChatRoomMessages: {
            messages: [],
          },
          selectedChatRoomPersonProfile: {},
          showNewConversation: false,
        };
      } else {
        return state;
      }
    case actions.PREPEND_CONVERSATION: {
      let chatRooms = [action.payload.data, ...state.chatRooms];
      chatRooms = removeDuplicateChats(chatRooms);
      return {
        ...state,
        chatRooms,
      };
    }
    case actions.APPEND_CONVERSATION: {
      let chatRooms = [...state.chatRooms, action.payload.data];
      chatRooms = removeDuplicateChats(chatRooms);
      return {
        ...state,
        chatRooms,
      };
    }
    case actions.APPEND_UNREPLIED_CONVERSATION: {
      const indexOfFirstReplied = state.chatRooms.findIndex(
        (chatRoom) => chatRoom.first_unreplied_person_message_time === null,
      );
      let chatRooms = _.cloneDeep(state.chatRooms);
      if (indexOfFirstReplied >= 0) {
        chatRooms.splice(indexOfFirstReplied, 0, action.payload.data);
      } else if (conversationInsertStatus(state)) {
        chatRooms.push(action.payload.data);
      }
      chatRooms = removeDuplicateChats(chatRooms);
      return {
        ...state,
        chatRooms,
      };
    }
    case actions.PREPEND_NON_PRIORITY_CONVERSATION: {
      const indexOfFirstNonPriority = state.chatRooms.findIndex(
        (chatRoom) => chatRoom.priority !== true,
      );
      let chatRooms = _.cloneDeep(state.chatRooms);
      chatRooms = prependNonPriorityConversation(
        chatRooms,
        action.payload.data,
        state,
      );
      chatRooms = removeDuplicateChats(chatRooms);
      return {
        ...state,
        chatRooms,
      };
    }
    case actions.BLOCK_CONVERSATION: {
      let userProfileData = _.cloneDeep(state.userProfileData),
        conversationsMessageData = _.cloneDeep(state.conversationsMessageData),
        unblockedUserAndIP, personID;

      let {
        conversation_identifier,
        blocked_people_id,
        person_id,
        blocked_ip_id,
        blocked_ip, // Blocked IP
        ip_address, // Unblocked IP
      } = action.payload.data;

      const ipAddress = blocked_ip ? blocked_ip : ip_address;

      if (ipAddress) {
        unblockedUserAndIP = Object.keys(conversationsMessageData).filter(
          (conversation) =>
            conversationsMessageData[conversation].ip_address === ipAddress,
        );
      } else {
        unblockedUserAndIP = Object.keys(conversationsMessageData).filter(
          (conversation) =>
            conversationsMessageData[conversation].messages.length &&
            conversationsMessageData[conversation].messages[0].person_id ===
              person_id,
        );
      }

      // Update ProfileData in reducer
      if (typeof blocked_ip_id !== 'undefined') {
        unblockedUserAndIP.map((data) => {
          conversationsMessageData[data].blocked_ip_id = blocked_ip_id;
          return null;
        });
      } else if (
        !conversation_identifier &&
        person_id &&
        Object.keys(userProfileData).length
      ) {
        userProfileData[person_id].profile.blocked_people_id =
          blocked_people_id;
      }

      // Update ConversationMessagesData in reducer
      if (
        conversation_identifier &&
        conversationsMessageData[conversation_identifier]
      ) {
        const messageObject = _.find(
          conversationsMessageData[conversation_identifier].messages,
          'person_id',
        );
        personID = messageObject.person_id;
        if (
          userProfileData[personID] &&
          typeof blocked_people_id !== 'undefined'
        ) {
          userProfileData[personID].profile.blocked_people_id =
            blocked_people_id;
        }
      }
      return {
        ...state,
        conversationsMessageData,
        userProfileData,
      };
    }
    case actions.DETECT_NETWORK_STATUS:
      return {
        ...state,
        isOffline: action.status !== 'online',
      };
    case actions.SET_EMPTY_EDIT_MESSAGE:
      return {
        ...state,
        editMessages: {},
      };
    case actions.UPDATE_PERSON_IN_CHAT_ROOMS: {
      let conversationsMessageData = _.cloneDeep(
        state.conversationsMessageData,
      );
      let conversationMessageDataTemp = {};

      _.forOwn(
        state.conversationsMessageData,
        (conversation, conversationId) => {
          const personTemp = _.find(conversation.messages, 'person_id');
          if (personTemp && personTemp.person_id === action.payload.person_id) {
            conversationMessageDataTemp[conversationId] = conversation;
          }
        },
      );

      _.forOwn(conversationMessageDataTemp, (conversation, conversationId) => {
        const messagesTemp = conversation.messages.map((message) => {
          if (message.person_id) {
            if (action.payload.person_new_id) {
              message.person_id = action.payload.person_new_id;
            }
            message.person['person_chat_name'] = action.payload.chat_email;
            message.person['avatar_url'] = action.payload.avatar_url;
            message.person['avatar_background'] =
              action.payload.avatar_background;
            message.person['avatar_letter'] = action.payload.avatar_letter;
          }
          return message;
        });
        conversationsMessageData[conversationId].messages = messagesTemp;
        return conversation;
      });

      return {
        ...state,
        chatRooms: state.chatRooms.map((chatRoom) => {
          if (chatRoom.person_id === action.payload.person_id) {
            if (action.payload.person_new_id) {
              chatRoom.person_id = action.payload.person_new_id;
              chatRoom.person['id'] = action.payload.person_new_id;
            }
            chatRoom.person['person_chat_name'] = action.payload.chat_email;
            chatRoom.person['avatar_url'] = action.payload.avatar_url;
            chatRoom.person['avatar_background'] =
              action.payload.avatar_background;
            chatRoom.person['avatar_letter'] = action.payload.avatar_letter;
          }
          return chatRoom;
        }),
        conversationsMessageData,
      };
    }
    case actions.UPDATE_PERSON_IN_SELECTED_CHAT_ROOM_MESSAGES:
      return {
        ...state,
        selectedChatRoomMessages: {
          ...state.selectedChatRoomMessages,
          messages: state.selectedChatRoomMessages.messages.map((message) => {
            if (message.person_id === action.payload.person_id) {
              if (action.payload.person_new_id) {
                message.person_id = action.payload.person_new_id;
              }
              message.person['person_chat_name'] = action.payload.chat_email;
              message.person['avatar_url'] = action.payload.avatar_url;
              message.person['avatar_background'] =
                action.payload.avatar_background;
              message.person['avatar_letter'] = action.payload.avatar_letter;
            }
            return message;
          }),
        },
        currentPersonId: action.payload.person_new_id
          ? action.payload.person_new_id
          : state.currentPersonId,
      };
    case actions.UPDATE_CONVERSATION_STATUS_COUNT:
      return {
        ...state,
        openCount: action.payload.open,
        closedCount: action.payload.closed,
        pendingCount: action.payload.pending,
      };
    case actions.ASSIGN_MULTIPLE_CONVERSATION_SUCCESS:
      return {
        ...state,
        selectAllConversations: false,
        selectedConversations: [],
        selectedTableConversations: [],
      };
    case actions.ASSIGN_CONVERSATION_SUCCESS:
      const assignTo = action.payload ? { id: action.payload } : null;
      let conversationMessageData = _.cloneDeep(state.conversationsMessageData);
      const conversationIdentifiersArray = action.assignData
        ? action.assignData.conversation_identifiers
        : [];

      conversationIdentifiersArray.map((conversation_identifier) => {
        if (conversationMessageData.hasOwnProperty(conversation_identifier)) {
          conversationMessageData[conversation_identifier].assigned_to = {
            id: action.payload,
          };
        }
      });
      let newPreviousConversationsChatRoom = [];
      const previousConversationChatRoom =
        state.previousConversations[state.currentPersonId];
      if (state.isFromPreviousConversation) {
        let index;
        conversationIdentifiersArray.map((conversation_identifier) => {
          index = previousConversationChatRoom.findIndex(
            (chatRoom) =>
              chatRoom?.conversation_identifier === conversation_identifier,
          );
          if (
            previousConversationChatRoom.hasOwnProperty(conversation_identifier)
          ) {
            previousConversationChatRoom[conversation_identifier].assigned_to =
              {
                id: action.payload,
              };
          }
        });
        newPreviousConversationsChatRoom = [
          previousConversationChatRoom[index],
          ...previousConversationChatRoom.slice(0, index),
          ...previousConversationChatRoom.slice(index + 1),
        ];
      }
      if (newPreviousConversationsChatRoom.length > 0) {
        return {
          ...state,
          selectedChatRoomMessages: {
            ...state.selectedChatRoomMessages,
            assigned_to: assignTo,
          },
          currentConversationAssignee: setAssignee(
            assignTo,
            action.currentUserId,
          ),
          conversationsMessageData: conversationMessageData,
          previousConversations: {
            ...state.previousConversations,
            [state.currentPersonId]: newPreviousConversationsChatRoom,
          },
        };
      }
      return {
        ...state,
        selectedChatRoomMessages: {
          ...state.selectedChatRoomMessages,
          assigned_to: assignTo,
        },
        currentConversationAssignee: setAssignee(
            assignTo,
            action.currentUserId,
        ),
        conversationsMessageData: conversationMessageData,
      };
    case actions.REPLY_LOADER: {
      const { status, cid } = action.payload;
      return {
        ...state,
        replySending: {
          ...state.replySending,
          [cid]: status,
        },
      };
    }
    case actions.NOTE_LOADER: {
      const { status, cid } = action.payload;
      return {
        ...state,
        noteSending: {
          ...state.noteSending,
          [cid]: status,
        },
      };
    }
    case actions.POST_SUCCESS:
      let actualChatRooms = state.chatRooms.filter(
        (chatRoom) => chatRoom.conversation_identifier !== action.payload,
      );
      let lastUpdatedConversation = state.chatRooms.filter(
        (chatRoom) => chatRoom.conversation_identifier === action.payload,
      );
      if (lastUpdatedConversation[0]) {
        lastUpdatedConversation[0].updated_at = moment();
        const { message } = action;
        if (!!message && !!message.conversation) {
          lastUpdatedConversation[0].priority = message.conversation.priority;
        }
        const { sortFilter } = state;
        if (sortFilter === 'newest') {
          actualChatRooms.unshift(lastUpdatedConversation[0]);
        } else if (sortFilter === 'oldest') {
          if (state.redirectingToSearch) {
            actualChatRooms = state.chatRooms;
          } else {
            if (conversationInsertStatus(state)) {
              actualChatRooms.push(lastUpdatedConversation[0]);
            } else {
              actualChatRooms = state.chatRooms;
            }
          }
        } else if (sortFilter === 'oldest_unreplied') {
          if (state.redirectingToSearch) {
            actualChatRooms = state.chatRooms;
          } else {
            const { message } = action;
            if (message) {
              if (message.conversation) {
                lastUpdatedConversation[0].first_unreplied_person_message_time =
                  message.conversation.first_unreplied_person_message_time;
              }
              if (
                !!message.conversation &&
                !!message.conversation.first_unreplied_person_message_time
              ) {
                const indexOfFirstReplied = actualChatRooms.findIndex(
                  (chatRoom) =>
                    chatRoom.first_unreplied_person_message_time === null,
                );
                if (
                  message.message_display_type === 'agent_message' &&
                  message.message_type !== 'note' &&
                  indexOfFirstReplied >= 0
                ) {
                  actualChatRooms.splice(
                    indexOfFirstReplied,
                    0,
                    lastUpdatedConversation[0],
                  );
                } else {
                  actualChatRooms = state.chatRooms;
                }
              } else if (
                message.message_display_type === 'agent_message' &&
                message.message_type !== 'note'
              ) {
                if (conversationInsertStatus(state)) {
                  actualChatRooms.push(lastUpdatedConversation[0]);
                }
              } else {
                actualChatRooms = state.chatRooms;
              }
            } else {
              actualChatRooms = state.chatRooms;
            }
          }
        } else if (sortFilter === 'priority') {
          // const { message } = action;
          // if (message) {
          //   if (!!message.conversation && !message.conversation.priority) {
          //     const indexOfFirstNonPriority = actualChatRooms.findIndex(
          //       (chatRoom) => chatRoom.priority !== true,
          //     );
          //     if (indexOfFirstNonPriority >= 0) {
          //       actualChatRooms.splice(
          //         indexOfFirstNonPriority,
          //         0,
          //         lastUpdatedConversation[0],
          //       );
          //     } else if (conversationInsertStatus(state)) {
          //       actualChatRooms.push(lastUpdatedConversation[0]);
          //     }
          //   } else {
          //     actualChatRooms.unshift(lastUpdatedConversation[0]);
          //   }
          // } else {
          actualChatRooms = state.chatRooms;
          // }
        }
      }
      actualChatRooms = removeDuplicateChats(actualChatRooms);
      return {
        ...state,
        chatRooms: actualChatRooms,
      };
    case actions.USER_PROPERTIES_UPDATE:
      let updateProperties = cloneDeep(
        state.selectedChatRoomPersonProfile.properties,
      );
      let updateProperty = action.payload.data[0];
      updateProperties = updateProperties?.map((property) => {
        if (property.key === updateProperty.key) {
          property.value = updateProperty.value;
        }
        return property;
      });
      let updatedQualification =
        state.selectedChatRoomPersonProfile.qualification.map(
          (qualification) => {
            if (qualification.key === updateProperty.key) {
              qualification.value = updateProperty.value;
            }
            return qualification;
          },
        );
      let updatedName = _.find(action.payload.data, { key: 'name' });
      return {
        ...state,
        selectedChatRoomPersonProfile: {
          ...state.selectedChatRoomPersonProfile,
          profile: {
            ...state.selectedChatRoomPersonProfile.profile,
            person_id: action.payload.person_id,
            person_chat_name:
              updateProperty.key === 'full_name'
                ? updateProperty.value
                : state.selectedChatRoomPersonProfile.profile.person_chat_name,
          },
          properties: updateProperties,
          qualification: updatedQualification,
        },
        chatRooms: state.chatRooms.map((data) => {
          if (data.person_id === action.payload.person_id && !!updatedName) {
            data.person.person_chat_name = updatedName.value;
            return data;
          }
          return data;
        }),
      };
    case actions.CHANGE_FACEBOOK_CONNECTION_STATUS:
      return {
        ...state,
        selectedChatRoomMessages: {
          ...state.selectedChatRoomMessages,
          fb_block: action.payload,
        },
      };
    case actions.ADD_MESSAGE_TAG_SUCCESS:
      const selectedChatMessages = state.selectedPreviousConversationIdentifier
          ? state.selectedPreviousChatMessages
          : state.selectedChatRoomMessages,
        updatedChatTagsData = _.uniqBy(
          [...state.chatTagsData, ...action.payload.tags],
          'id',
        );
      let actualChatRoom = state.chatRooms.map((chatRoom) => {
        if (chatRoom.conversation_identifier === action.payload.cid) {
          if (chatRoom.tags) {
            chatRoom.tags = _.unionBy(action.payload.tags, chatRoom.tags, 'id');
          } else {
            chatRoom.tags = action.payload.tags;
          }
        }
        return chatRoom;
      });
      let chatRoomMessagesList = selectedChatMessages.messages.map(
        (message) => {
          if (action.payload.messageId === message.id) {
            message.tags = _.unionBy(action.payload.tags, message.tags, 'id');
          }
          return message;
        },
      );

      if (state.selectedPreviousConversationIdentifier) {
        return {
          ...state,
          chatRooms: actualChatRoom,
          selectedPreviousChatMessages: {
            ...state.selectedPreviousChatMessages,
            messages: chatRoomMessagesList,
          },
          chatTagsData: updatedChatTagsData,
        };
      }
      return {
        ...state,
        chatRooms: actualChatRoom,
        selectedChatRoomMessages: {
          ...state.selectedChatRoomMessages,
          messages: chatRoomMessagesList,
        },
        conversationsMessageData: {
          ...state.conversationsMessageData,
          [action.payload.cid]: {
            ...state.conversationsMessageData[action.payload.cid],
            messages: chatRoomMessagesList,
          },
        },
        chatTagsData: updatedChatTagsData,
      };
    case actions.CLEAR_CHAT:
      return {
        ...state,
        chatRooms: [],
        selectedChatRoom: {},
        selectedChatRoomMessages: {
          messages: [],
        },
        selectedChatRoomPersonProfile: {},
      };
    case actions.REMOVE_CONVERSATION_TAGS_SUCCESS:
      let chatRoomTemp = filterTags(
        cloneDeep(state.chatRooms),
        action.tagId,
        action.conversationID,
      );
      let chatRoomMessages = state.selectedChatRoomMessages.messages;
      if (action.conversationID === state.currentConversationId) {
        chatRoomMessages = chatRoomMessages.map((message) => {
          message.tags = message.tags.filter((tag) => tag.id !== action.tagId);
          return message;
        });
      }
      return {
        ...state,
        chatRooms: chatRoomTemp,
        selectedChatRoomMessages: {
          ...state.selectedChatRoomMessages,
          messages: chatRoomMessages,
        },
      };
    case actions.REMOVE_MESSAGE_TAGS_SUCCESS:
      const selectChatMessages = cloneDeep(state.conversationsMessageData)[
        state.currentConversationId
      ];
      let selectedChatRoomMessages = selectChatMessages.messages.map(
          (message) => {
            if (message.id === action.messageID) {
              message.tags = message.tags.filter(
                (tag) => tag.id !== action.tagID,
              );
            }
            return message;
          },
        ),
        conversationRooms = cloneDeep(state.chatRooms);
      if (action.removeConversationTag) {
        const currentId =
          state.selectedPreviousConversationIdentifier ||
          state.currentConversationId;
        conversationRooms = filterTags(
          cloneDeep(state.chatRooms),
          action.tagID,
          currentId,
        );
      }
      if (state.selectedPreviousConversationIdentifier) {
        return {
          ...state,
          chatRooms: conversationRooms,
        };
      }
      const conversationId = state.currentConversationId;
      return {
        ...state,
        selectedChatRoomMessages: {
          ...state.selectedChatRoomMessages,
          messages: selectedChatRoomMessages,
        },
        chatRooms: conversationRooms,
        conversationsMessageData: {
          ...state.conversationsMessageData,
          [conversationId]: {
            ...state.conversationsMessageData[conversationId],
            messages: selectedChatRoomMessages,
          },
        },
      };

    case actions.CONVERSATION_STATUS_UPDATE_LOADER:
      return {
        ...state,
        conversationStatusUpdateLoadingState: [
          ...state.conversationStatusUpdateLoadingState,
          ...action.payload,
        ],
      };
    case actions.CONVERSATION_STATUS_UPDATE_LOADER_SUCCESS:
      return {
        ...state,
        conversationStatusUpdateLoadingState:
          state.conversationStatusUpdateLoadingState.filter(
            (item) => !action.payload.includes(item),
          ),
      };
    case actions.CHANGE_CONVERSATION_STATUS: {
      const { status } = action.payload;
      return {
        ...state,
        statusFilter: status,
        sortFilter:
          status !== 'open' && state.sortFilter === 'oldest_unreplied'
            ? 'newest'
            : state.sortFilter,
      };
    }
    case actions.ASSOCIATE_VISITOR:
      return {
        ...state,
        associatingVisitor: true,
      };
    case actions.ASSOCIATE_VISITOR_SUCCESS:
      // TODO:
      let personName = action.payload.data.person_name;
      personName = personName ? personName : 'Site Visitor';
      return {
        ...state,
        associatingVisitor: false,
        selectedChatRoomPersonProfile: {
          ...state.selectedChatRoomPersonProfile,
          profile: {
            ...state.selectedChatRoomPersonProfile.profile,
            person_chat_name: personName,
            avatar_letter: extractAvatarLetter(personName),
          },
        },
        associateContact: true,
      };
    case actions.ASSOCIATE_VISITOR_FAILURE:
      return {
        ...state,
        associatingVisitor: false,
        associateContact: false,
      };
    case actions.ASSOCIATE_VISITOR_CANCEL:
      // TODO
      let personChatName;
      if (state.chatRooms && state.chatRooms.length > 0) {
        state.chatRooms.forEach((chatRoom) => {
          if (
            chatRoom.conversation_identifier === state.currentConversationId
          ) {
            if (chatRoom && chatRoom.person) {
              personChatName = chatRoom.person.person_chat_name;
            }
          }
        });
      }
      return {
        ...state,
        selectedChatRoomPersonProfile: {
          ...state.selectedChatRoomPersonProfile,
          profile: {
            ...state.selectedChatRoomPersonProfile.profile,
            person_chat_name: personChatName,
            avatar_letter: extractAvatarLetter(personChatName),
          },
        },
        associateContact: false,
      };
    case actions.SET_CONVERSATION_SEARCH_TEXT:
      return {
        ...state,
        conversationSearchText: action.payload,
      };
    case actions.SET_CONVERSATION_INBOX_COUNT: {
      let { conversationCount, inboxViewCount } = action.payload;
      const {
        currentUserId,
        inboxRoles,
        canManageUnassignedInbox,
        assignedTeams,
      } = action;
      let teams = orderTeamsByName(state.teams, conversationCount);
      let teamMates = orderTeamMatesByName(state.teamMates, conversationCount);
      const allCount = updateCountBasedOnRoles(
        conversationCount,
        assignedTeams,
        currentUserId,
        inboxRoles,
        canManageUnassignedInbox,
      );
      if (allCount) {
        conversationCount.all = allCount;
      }
      return {
        ...state,
        conversationInboxCount: conversationCount,
        openCount: action.payload.open,
        closedCount: action.payload.closed,
        pendingCount: action.payload.pending,
        teams,
        teamMates,
        bot: { ...state.bot, count: conversationCount.bot },
        inboxViewCount: inboxViewCount,
      };
    }
    case actions.SET_CURRENT_CONVERSATION_ID:
      return {
        ...state,
        currentConversationId: action.payload,
      };
    case actions.FETCH_AGENT_TIMINGS_SUCCESS:
      return {
        ...state,
        agentTimingList: action.payload.meeting_links,
      };
    case actions.FETCH_AGENT_TIMINGS:
      return {
        ...state,
        meetingAgentId: action.id,
      };
    case actions.SEND_FIRE_BASE_TOKEN:
      return {
        ...state,
        fireBaseToken: action.token,
      };
    case actions.DELETE_CONVERSATION_MESSAGE:
      return {
        ...state,
        deleteMessage: { id: action.messageID, type: action.messageType },
      };
    case actions.DELETE_CONVERSATION_MESSAGE_SUCCESS:
      const currentChatMessages = state.selectedChatRoomMessages;
      let index = currentChatMessages.messages.findIndex(
        (message) => message.id === action.id,
      );
      let message = currentChatMessages.messages;
      if (index >= 0) {
        message[index].deleted_at = moment();
      }

      return {
        ...state,
        selectedChatRoomMessages: {
          ...state.selectedChatRoomMessages,
          messages: message,
        },
        deleteMessage: null,
      };
    case actions.UPDATE_AVAILABILITY_STATUS:
      let updatedTeamMates = state.teamMates.map((value) => {
        if (value.id === action.payload.user_id) {
          value.status = action.payload.status;
        }
        return value;
      });
      return {
        ...state,
        teamMates: updatedTeamMates,
      };
    case actions.GET_NOTIFICATION_SETTINGS_DETAILS_SUCCESS:
      return {
        ...state,
        notificationDetails: action.data.send_notification,
      };
    case actions.PRIORITIZE_CONVERSATION: {
      return {
        ...state,
        priorityProcessingConversations: [action.payload.cid].concat(
          state.priorityProcessingConversations,
        ),
      };
    }
    case actions.PRIORITIZE_CONVERSATION_SUCCESS: {
      const { cid, priority, isFromPreviousConversation } = action.data;
      let newPreviousConversationsChatRoom = [];
      const previousConversationChatRoom =
        state.previousConversations[state.currentPersonId];
      if (isFromPreviousConversation) {
        const index = previousConversationChatRoom.findIndex(
          (chatRoom) => chatRoom?.conversation_identifier === cid,
        );
        if (index >= 0) {
          previousConversationChatRoom[index].priority = priority;
        }
        newPreviousConversationsChatRoom = [
          previousConversationChatRoom[index],
          ...previousConversationChatRoom.slice(0, index),
          ...previousConversationChatRoom.slice(index + 1),
        ];
      }
      const chatRoomIndex = state.chatRooms.findIndex(
        (chatRoom) => chatRoom.conversation_identifier === cid,
      );
      const chatRooms = state.chatRooms;
      if (chatRoomIndex >= 0) {
        chatRooms[chatRoomIndex].priority = priority;
      }
      const priorityProcessingConversations =
        state.priorityProcessingConversations.filter((item) => item !== cid);
      if (state.selectedChatRoom.conversation_identifier === cid) {
        return {
          ...state,
          chatRooms,
          selectedChatRoom: {
            ...state.selectedChatRoom,
            priority,
          },
          priorityProcessingConversations,
        };
      }
      if (newPreviousConversationsChatRoom.length > 0) {
        return {
          ...state,
          chatRooms,
          priorityProcessingConversations,
          previousConversations: {
            ...state.previousConversations,
            [state.currentPersonId]: newPreviousConversationsChatRoom,
          },
        };
      }
      return {
        ...state,
        chatRooms,
        priorityProcessingConversations,
      };
    }
    case actions.PRIORITIZE_CONVERSATION_FAILURE: {
      const { cid } = action.data;
      const priorityProcessingConversations =
        state.priorityProcessingConversations.filter((item) => item !== cid);
      return {
        ...state,
        priorityProcessingConversations,
      };
    }
    case actions.PRIORITIZE_CURRENT_CONVERSATION_SOCKET: {
      const { priority } = action.data;
      return {
        ...state,
        selectedChatRoom: {
          ...state.selectedChatRoom,
          priority,
        },
      };
    }
    case actions.CHANGE_TWITTER_CONNECTION_STATUS:
      return {
        ...state,
        selectedChatRoomMessages: {
          ...state.selectedChatRoomMessages,
          twitter_block: action.payload,
        },
      };
    case actions.CHANGE_ASSOCIATE_STATUS:
      return {
        ...state,
        associateContact: action.payload.associateContact,
      };
    case actions.UPDATE_CHAT_MAGIC_TYPE_SETTINGS:
      return {
        ...state,
        allowMagicType: action.payload,
      };
    case actions.CHANGE_CURRENT_CHANNEL_STEP:
      return {
        ...state,
        channelCurrentStep: action.payload.currentStep,
      };
    case actions.ENQUEUE_REPLY_ATTACHMENT: {
      const { attachmentData, cid } = action.payload,
        replyAttachmentQueues = _.cloneDeep(state.replyAttachmentQueues);
      if (replyAttachmentQueues[cid]) {
        if (replyAttachmentQueues[cid].length >= maxAttachmentQueueSize) {
          notifier.warning(`Limit is ${maxAttachmentQueueSize}.`);
          return state;
        }
        replyAttachmentQueues[cid].push(attachmentData);
      } else {
        replyAttachmentQueues[cid] = [attachmentData];
      }
      replyAttachmentQueues[cid] = _.uniqBy(
        replyAttachmentQueues[cid],
        'file_name',
      );
      return {
        ...state,
        replyAttachmentQueues,
      };
    }
    case actions.DEQUEUE_REPLY_ATTACHMENT: {
      const { cid } = action.payload,
        replyAttachmentQueues = _.cloneDeep(state.replyAttachmentQueues);
      replyAttachmentQueues[cid].shift();
      return {
        ...state,
        replyAttachmentQueues,
      };
    }
    case actions.RESTORE_REPLY_ATTACHMENT_QUEUE: {
      const { cid, attachmentData } = action.payload,
        replyAttachmentQueues = _.cloneDeep(state.replyAttachmentQueues);
      replyAttachmentQueues[cid].unshift(attachmentData);
      return {
        ...state,
        replyAttachmentQueues,
      };
    }
    case actions.REMOVE_REPLY_ATTACHMENT: {
      const { cid, replyIndex } = action.payload,
        replyAttachmentQueues = _.cloneDeep(state.replyAttachmentQueues);
      if (replyAttachmentQueues[cid]) {
        replyAttachmentQueues[cid].splice(replyIndex, 1);
      }
      return {
        ...state,
        replyAttachmentQueues,
      };
    }
    case actions.ENQUEUE_NOTE_ATTACHMENT: {
      const { attachmentData, cid } = action.payload,
        noteAttachmentQueues = _.cloneDeep(state.noteAttachmentQueues);
      if (noteAttachmentQueues[cid]) {
        if (noteAttachmentQueues[cid].length >= maxAttachmentQueueSize) {
          notifier.warning(`Limit is ${maxAttachmentQueueSize}.`);
          return state;
        }
        noteAttachmentQueues[cid].push(attachmentData);
      } else {
        noteAttachmentQueues[cid] = [attachmentData];
      }
      noteAttachmentQueues[cid] = _.uniqBy(
        noteAttachmentQueues[cid],
        'file_name',
      );
      return {
        ...state,
        noteAttachmentQueues,
      };
    }
    case actions.DEQUEUE_NOTE_ATTACHMENT: {
      const { cid } = action.payload,
        noteAttachmentQueues = _.cloneDeep(state.noteAttachmentQueues);
      noteAttachmentQueues[cid].shift();
      return {
        ...state,
        noteAttachmentQueues,
      };
    }
    case actions.RESTORE_NOTE_ATTACHMENT_QUEUE: {
      const { cid, attachmentData } = action.payload,
        noteAttachmentQueues = _.cloneDeep(state.noteAttachmentQueues);
      noteAttachmentQueues[cid].unshift(attachmentData);
      return {
        ...state,
        noteAttachmentQueues,
      };
    }
    case actions.REMOVE_NOTE_ATTACHMENT: {
      const { cid, noteIndex } = action.payload,
        noteAttachmentQueues = _.cloneDeep(state.noteAttachmentQueues);
      if (noteAttachmentQueues[cid]) {
        noteAttachmentQueues[cid].splice(noteIndex, 1);
      }
      return {
        ...state,
        noteAttachmentQueues,
      };
    }
    case actions.UPDATE_SATISFACTION_RATING_IN_LIST:
      let chatMessages = state.selectedChatRoomMessages.messages,
        satisfactionRatingIndex = chatMessages.findIndex(
          (message) => message.message_type === 'satisfaction_rating',
        );
      if (satisfactionRatingIndex > -1) {
        chatMessages[satisfactionRatingIndex] = action.payload;
      } else {
        chatMessages.push(action.payload);
      }
      return {
        ...state,
        selectedChatRoomMessages: {
          ...state.selectedChatRoomMessages,
          messages: chatMessages,
        },
      };
    case actions.UPDATE_SATISFACTION_RATING_IN_ALL_CONVERSATION_DATA: {
      let conversationsMessageData = _.cloneDeep(
        state.conversationsMessageData,
      );
      const conversationIdentifier = action.payload.conversation_identifier;
      // whether conversationsMessageData already has the conversation data
      if (
        conversationsMessageData &&
        conversationsMessageData[conversationIdentifier]
        && conversationsMessageData[conversationIdentifier].messages
      ) {
        let messageIndex = conversationsMessageData[
          conversationIdentifier
        ].messages.findIndex(
          (message) => message.message_type === 'satisfaction_rating',
        );

        if (messageIndex !== -1) {
          conversationsMessageData[conversationIdentifier].messages[
            messageIndex
          ] = action.payload;
        } else {
          conversationsMessageData[conversationIdentifier].messages.push(
            action.payload,
          );
        }
      }
      return {
        ...state,
        conversationsMessageData: conversationsMessageData,
      };
    }
    case actions.UPDATE_CURRENT_ASSIGNEE:
      if (state.selectedPreviousConversationIdentifier) {
        return {
          ...state,
          previousConversationAssignee: action.payload,
        };
      }
      let conversationsMessageDatas = _.cloneDeep(
        state.conversationsMessageData,
      );
      conversationsMessageDatas[
        action.currentConversationIdentifier
      ].assigned_to = {
        id: action.payload,
      };
      return {
        ...state,
        conversationsMessageData: conversationsMessageDatas,
        currentConversationAssignee: action.payload,
      };
    case actions.APPEND_STATUS_MESSAGE: {
      let conversationsMessageData = _.cloneDeep(
        state.conversationsMessageData,
      );
      conversationsMessageData[
        action.payload.conversation_identifier
      ].messages = conversationsMessageData[
        action.payload.conversation_identifier
      ].messages.concat(action.payload.message);

      return {
        ...state,
        selectedChatRoomMessages: {
          ...state.selectedChatRoomMessages,
          messages: state.selectedChatRoomMessages.messages.concat(
            action.payload.message,
          ),
        },
        conversationsMessageData: conversationsMessageData,
      };
    }
    case actions.UPDATE_JOIN_CALL_MESSAGES: {
      const { payload } = action,
        { chatRooms } = state;
      const chatRoomMessages = state.selectedChatRoomMessages.messages;
      let conversationsMessageData = _.cloneDeep(
        state.conversationsMessageData,
      );
      if (chatRoomMessages.some(({ id }) => id === payload.id)) {
        const editedChatRoom = chatRooms.map((chatRoom) => {
          if (chatRoom.last_conversation_message.id === payload.id) {
            chatRoom.last_conversation_message = payload;
            chatRoom.updated_at = new Date();
          }
          return chatRoom;
        });
        let selectedChatRoomMessages = chatRoomMessages.map((message) => {
          if (message.id === payload.id) {
            return payload;
          }
          return message;
        });

      if (conversationsMessageData[payload.conversation_identifier]) {
        conversationsMessageData[payload.conversation_identifier].messages =
            selectedChatRoomMessages;
      }
      if (state.selectedPreviousConversationIdentifier) {
        return {
          ...state,
          chatRooms: editedChatRoom,
          conversationsMessageData: conversationsMessageData,
          };
        }
        return {
          ...state,
          chatRooms: editedChatRoom,
          conversationsMessageData: conversationsMessageData,
          selectedChatRoomMessages: {
            ...chatRoomMessages,
            messages: selectedChatRoomMessages,
          },
        };
      }
      const editedChatRoom = chatRooms.map((chatRoom) => {
        if (
          chatRoom.conversation_identifier === payload.conversation_identifier
        ) {
          chatRoom.last_conversation_message = payload;
          chatRoom.updated_at = new Date();
        }
        return chatRoom;
      });
      if (conversationsMessageData[payload.conversation_identifier]) {
        conversationsMessageData[payload.conversation_identifier].messages = [
          ...chatRoomMessages,
          payload,
        ];
      }

      const selectedConversation =
        state.selectedPreviousConversationIdentifier ||
        state.currentConversationId;
      const isCurrentConversation =
        selectedConversation === payload.conversation_identifier;

      if (state.selectedPreviousConversationIdentifier) {
        return {
          ...state,
          chatRooms: editedChatRoom,
          conversationsMessageData: conversationsMessageData,
        };
      }

      return {
        ...state,
        chatRooms: editedChatRoom,
        conversationsMessageData: conversationsMessageData,
        selectedChatRoomMessages: {
          ...state.selectedChatRoomMessages,
          messages: isCurrentConversation
            ? [...chatRoomMessages, payload]
            : chatRoomMessages,
        },
      };
    }
    case actions.ACTIVE_COMPOSER_TAB: {
      const { cid, activeKey } = action.payload;
      return {
        ...state,
        activeComposerTabList: {
          ...state.activeComposerTabList,
          [cid]: activeKey,
        },
        activeComposerTab: !action.isFromPreviousConversation
          ? activeKey
          : state.activeComposerTab,
        activePastConversationComposerTab: action.isFromPreviousConversation
          ? activeKey
          : state.activePastConversationComposerTab,
      };
    }
    case actions.TRANSCRIPT_MODAL_VISIBILITY:
      return {
        ...state,
        transcriptModalVisibility: action.payload,
      };
    case actions.SEND_TRANSCRIPT:
      return {
        ...state,
        transcriptModalLoader: true,
      };
    case actions.SEND_TRANSCRIPT_SUCCESS:
      return {
        ...state,
        transcriptModalLoader: false,
        transcriptModalVisibility: false,
      };
    case actions.SEND_TRANSCRIPT_FAILURE:
      return {
        ...state,
        transcriptModalLoader: false,
      };
    case actions.APPEND_STATUS_MESSAGE:
      conversationsMessageData = _.cloneDeep(state.conversationsMessageData);
      conversationsMessageData[
        action.payload.conversation_identifier
      ].messages = conversationsMessageData[
        action.payload.conversation_identifier
      ].messages.concat(action.payload.message);

      return {
        ...state,
        selectedChatRoomMessages: {
          ...state.selectedChatRoomMessages,
          messages: state.selectedChatRoomMessages.messages.concat(
            action.payload.message,
          ),
        },
        conversationsMessageData: conversationsMessageData,
      };
    case actions.ACTIVE_COMPOSER_TAB:
      return {
        ...state,
        activeComposerTab: !action.isFromPreviousConversation
          ? action.payload
          : state.activeComposerTab,
        activePastConversationComposerTab: action.isFromPreviousConversation
          ? action.payload
          : state.activePastConversationComposerTab,
      };
    case actions.SEND_AND_SNOOZE_CONVERSATION:
      return {
        ...state,
        timeKey: action.time_key,
        timeValue: action.time_value,
      };
    case actions.FETCH_PREVIOUS_CONVERSATIONS: {
      return {
        ...state,
        prevConversationLoadMore: !action.isDisableLoader,
        searchPastConversationLoader: !!action.isDisableLoader,
      };
    }
    case actions.FETCH_PREVIOUS_CONVERSATIONS_SUCCESS: {
      let prevConversation = [];
      const { count, previous_conversations, person_conversations } =
          action.payload,
        { personId, isAppend } = action;
      if (isAppend) {
        prevConversation = state.previousConversations[personId].concat(
          previous_conversations || person_conversations,
        );
      } else {
        prevConversation = previous_conversations || person_conversations;
      }

      return {
        ...state,
        chatRoomSkeletonLoading: false,
        previousConversations: {
          ...state.previousConversations,
          [personId]: prevConversation,
        },
        previousConversationsCount: {
          ...state.previousConversationsCount,
          [personId]: count,
        },
        prevConversationLoadMore: {
          ...state.prevConversationLoadMore,
          [personId]: false,
        },
        searchPastConversationLoader: {
          ...state.searchPastConversationLoader,
          [personId]: false,
        },
      };
    }
    case actions.FETCH_PREVIOUS_CONVERSATIONS_FAILURE: {
      const { personId } = action;
      return {
        ...state,
        prevConversationLoadMore: {
          ...state.prevConversationLoadMore,
          [personId]: false,
        },
        searchPastConversationLoader: {
          ...state.searchPastConversationLoader,
          [personId]: false,
        },
      };
    }
    case actions.GET_USER_PROPERTIES_SUCCESS: {
      const tags = action.payload.tags ? action.payload.tags : state.tags,
        segments = action.payload.segments
          ? action.payload.segments
          : state.segments,
        campaigns = action.payload.campaigns
          ? action.payload.campaigns
          : state.campaigns,
        workflows = action.payload.workflows
          ? action.payload.workflows
          : state.workflows;
      return {
        ...state,
        tags,
        segments,
        campaigns,
        workflows,
      };
    }
    case actions.SET_USER_PROPERTIES_LOADER:
      return {
        ...state,
        userPropertiesLoader: [
          ...state.userPropertiesLoader,
          ...action.payload,
        ],
      };
    case actions.REMOVE_USER_PROPERTIES_LOADER: {
      let userPropertiesLoader = [];
      if (action.payload) {
        userPropertiesLoader = state.userPropertiesLoader.filter(
          (item) => !action.payload.includes(item),
        );
      }
      return {
        ...state,
        userPropertiesLoader,
      };
    }
    case actions.UNSUBSCRIBE_CURRENT_PERSON_SUCCESS:
      return {
        ...state,
        selectedChatRoomPersonProfile: {
          ...state.selectedChatRoomPersonProfile,
          profile: {
            ...state.selectedChatRoomPersonProfile.profile,
            unsubscribed_from_all_email: action.payload,
          },
        },
      };
    case actions.UPDATE_LIVE_PERSON_DATA: {
      let contactsOnlineStatuses = _.cloneDeep(state.contactsOnlineStatuses);

      let person_id;

      if (action.status === 'online') {
        person_id = action.payload.live_person.person_id;
        contactsOnlineStatuses[person_id] = 'online';
      } else {
        person_id = action.payload.person_id;
        contactsOnlineStatuses[person_id] = 'offline';
      }

      return {
        ...state,
        contactsOnlineStatuses: contactsOnlineStatuses,
      };
    }
    case actions.SHOW_HIDE_MESSAGE_TAGS_MODAL:
      let messageId = action.payload.messageId;

      // Apply message tag to the last end-user message - for Keyboard shortcuts
      if (action.payload.status && !messageId) {
        let { messages } = state.selectedChatRoomMessages;
        messages = messages.filter(
          (message) =>
            ['person_message', 'agent_message'].includes(
              message.message_display_type,
            ) && !message.deleted_at,
        );
        const messagesCount = messages.length;
        messageId = messagesCount > 0 ? messages[messagesCount - 1].id : null;
        if (!messageId) {
          action.payload.status = false;
        }
      }

      return {
        ...state,
        showMessageTagModal: action.payload.status,
        currentSelectedMessageId: messageId,
      };
    case actions.CHANGE_REPLY_EDITOR_FOCUS:
      return {
        ...state,
        isReplyEditorFocus: action.payload,
      };
    case actions.CHANGE_NOTE_EDITOR_FOCUS:
      return {
        ...state,
        isNoteEditorFocus: action.payload,
      };
    case actions.SCHEDULE_CALL:
      return {
        ...state,
        callBtnLoading: true,
      };
    case actions.SCHEDULE_CALL_SUCCESS:
      return {
        ...state,
        callBtnLoading: false,
        popoverVisible: false,
      };
    case actions.SCHEDULE_CALL_FAILURE:
      return {
        ...state,
        callBtnLoading: false,
      };
    case actions.EMPTY_CONVERSATION_REDUCER_DATA:
      return {
        ...state,
        conversationsMessageData: {},
        userProfileData: {},
      };
    case actions.UPDATE_CONVERSATION_TITLE_SUCCESS:
      const chatRoomsModified = state.chatRooms.map((chatRoom) => {
        if (chatRoom.conversation_identifier === action.conversationId) {
          chatRoom.title = action.payload;
        }
        return chatRoom;
      });
      let pastConversationModified =
        state.previousConversations[state.currentPersonId] || [];
      if (state.isFromPreviousConversation) {
        pastConversationModified = pastConversationModified.map((chatRoom) => {
          if (chatRoom.conversation_identifier === action.conversationId) {
            chatRoom.title = action.payload;
          }
          return chatRoom;
        });
      }
      return {
        ...state,
        chatRooms: chatRoomsModified,
        selectedChatRoom: {
          ...state.selectedChatRoom,
          title:
            action.conversationId === state.currentConversationId
              ? action.payload
              : state.selectedChatRoom.title,
        },
        previousConversations: {
          ...state.previousConversations,
          [state.currentPersonId]: pastConversationModified,
        },
      };

    // INBOX CUSTOM VIEWS
    case actions.ADD_INBOX_VIEW_CONDITION_BLOCK: {
      let conditionBlock = {
        criteria: [
          {
            value: '',
            key: state.allKeysOfDisplayRules[0].properties[0].value,
            condition:
              state.displayRulesPropertiesMetaData[
                state.allKeysOfDisplayRules[0].properties[0].property_type
              ][0].value,
            selectedType:
              state.allKeysOfDisplayRules[0].properties[0].property_type,
            conditions:
              state.displayRulesPropertiesMetaData[
                state.allKeysOfDisplayRules[0].properties[0].property_type
              ],
            unit: state.allKeysOfDisplayRules[0].properties[0].unit,
          },
        ],
      };

      if (
        [
          'conversation_channel',
          'conversation_status',
          'conversation_rating',
        ].includes(conditionBlock.criteria[0].key)
      ) {
        conditionBlock.criteria[0].staticValues = getStaticValue(
          conditionBlock.criteria[0].key,
        );
        if (conditionBlock.criteria[0].value === '')
          conditionBlock.criteria[0].value = [];
      }

      return {
        ...state,
        currentInboxViewData: {
          ...state.currentInboxViewData,
          conditions: [
            ...state.currentInboxViewData.conditions,
            conditionBlock,
          ],
        },
      };
    }

    case actions.ADD_INBOX_VIEW_CONDITION_ITEM: {
      let segment = state.allKeysOfDisplayRules[0].properties[0];
      let conditionItem = {
        key: segment.value,
        condition:
          state.displayRulesPropertiesMetaData[segment.property_type][0].value,
        value: '',
        selectedType: segment.property_type,
        conditions: state.displayRulesPropertiesMetaData[segment.property_type],
        unit: segment.unit,
      };

      if (
        [
          'conversation_channel',
          'conversation_status',
          'conversation_rating',
          'business_hours',
          'day_of_week',
        ].includes(conditionItem.key)
      ) {
        conditionItem.staticValues = getStaticValue(conditionItem.key);
        if (conditionItem.value === '') conditionItem.value = [];
      }

      let updatedCurrentInboxViewData = _.cloneDeep(state.currentInboxViewData);
      let index = action.payload.index;

      updatedCurrentInboxViewData.conditions[index].criteria.push(
        conditionItem,
      );

      return {
        ...state,
        currentInboxViewData: updatedCurrentInboxViewData,
      };
    }

    case actions.DELETE_INBOX_VIEW_CONDITION_ITEM: {
      const { externalIndex, index } = action.payload;

      let updatedCurrentInboxViewData = _.cloneDeep(state.currentInboxViewData);

      if (index > -1) {
        updatedCurrentInboxViewData.conditions[externalIndex].criteria.splice(
          index,
          1,
        );
      }

      if (
        updatedCurrentInboxViewData.conditions[externalIndex].criteria
          .length === 0
      ) {
        updatedCurrentInboxViewData.conditions.splice(externalIndex, 1);
      }

      return {
        ...state,
        currentInboxViewData: updatedCurrentInboxViewData,
      };
    }

    case actions.UPDATE_INBOX_VIEW_META: {
      let updatedCurrentInboxViewData = _.cloneDeep(state.currentInboxViewData);
      updatedCurrentInboxViewData.name = action.payload.name;
      updatedCurrentInboxViewData.emoji = action.payload.emoji;

      return {
        ...state,
        currentInboxViewData: updatedCurrentInboxViewData,
      };
    }

    case actions.UPDATE_INBOX_VIEW_NAME: {
      let updatedCurrentInboxViewData = _.cloneDeep(state.currentInboxViewData);
      updatedCurrentInboxViewData.name = action.payload.name;

      return {
        ...state,
        currentInboxViewData: updatedCurrentInboxViewData,
      };
    }

    case actions.UPDATE_INBOX_VIEW_EMOJI: {
      let updatedCurrentInboxViewData = _.cloneDeep(state.currentInboxViewData);
      updatedCurrentInboxViewData.emoji = action.payload.emoji;

      return {
        ...state,
        currentInboxViewData: updatedCurrentInboxViewData,
      };
    }

    case actions.UPDATE_INBOX_VIEW_CONDITION_DATA: {
      let updatedCurrentInboxViewData = _.cloneDeep(state.currentInboxViewData);
      let conditionsData = _.cloneDeep(state.currentInboxViewData.conditions);

      let { externalIndex, index, key, value, condition } = action.payload;

      let getProperty = _.find(
        state.allPropertiesListOfInboxAutomationConditions,
        function (property) {
          return (
            property.value ===
            (key ? key : conditionsData[externalIndex].criteria[index].key)
          );
        },
      );

      // Primary condition data
      if (key) {
        const { displayRulesPropertiesMetaData } = state;
        const isConversationProperty = !state.allKeysOfDisplayRules.some(
          ({ properties }) => properties.some(({ value }) => value === key),
        );

        if (isConversationProperty) {
          const conversationProperty = state.conversationPropertiesData.find(
            ({ name }) => name === key,
          );
          const { propertiesMetaData } = state;
          const metaData = {
              ...displayRulesPropertiesMetaData,
              date: propertiesMetaData.date,
              list: propertiesMetaData.list,
            },
            selectedType = conversationProperty.data_type;
          conditionsData[externalIndex].criteria[index] = {
            filter_type: 'conversation_property',
            key,
            selectedType,
            condition:
              selectedType === 'boolean'
                ? 'equals'
                : selectedType === 'date'
                ? metaData[selectedType].relative[0].value
                : metaData[selectedType][0].value,
            conditions:
              selectedType === 'boolean' ? '' : metaData[selectedType],
            value: selectedType === 'boolean' ? 'true' : undefined,
          };
        } else {
          conditionsData[externalIndex].criteria[index] = {
            key: key,
            condition:
              displayRulesPropertiesMetaData[getProperty.property_type][0]
                .value,
            value: '',
            unit: getProperty.unit,
            selectedType: getProperty.property_type,
            conditions:
              displayRulesPropertiesMetaData[getProperty.property_type],
          };
        }
        if (
          key === 'time_between_hours' &&
          _.isEmpty(conditionsData[externalIndex].criteria[index].value)
        ) {
          conditionsData[externalIndex].criteria[index].value = {
            from: '09:00',
            to: '17:00',
          };
        }
      }

      // Secondary condition data
      if (condition || condition === false) {
        if (
          ['has_any_value', 'has_no_value'].includes(condition) &&
          conditionsData[externalIndex].criteria[index].filter_type !==
            'conversation_property'
        ) {
          conditionsData[externalIndex].criteria[index] = {
            key: conditionsData[externalIndex].criteria[index].key,
            value: conditionsData[externalIndex].criteria[index].value,
            condition: condition,
            unit: getProperty.unit,
            selectedType: getProperty.property_type,
            conditions:
              state.displayRulesPropertiesMetaData[getProperty.property_type],
          };
        } else if (
          getProperty &&
          getProperty.length !== 0 &&
          getProperty.property_type === 'boolean'
        ) {
          conditionsData[externalIndex].criteria[index] = {
            key: conditionsData[externalIndex].criteria[index].key,
            value: condition,
            condition: 'equals',
            unit: getProperty.unit,
            selectedType: getProperty.property_type,
            conditions:
              state.displayRulesPropertiesMetaData[getProperty.property_type],
          };
        } else {
          if (
            conditionsData[externalIndex].criteria[index].selectedType ===
            'date'
          ) {
            const { propertiesMetaData } = state;
            conditionsData[externalIndex].criteria[index].isAbsoluteDate =
              propertiesMetaData.date.absolute.some(
                ({ value }) => value === condition,
              );
          }
          conditionsData[externalIndex].criteria[index].condition = condition;
        }
      }

      if (![undefined, null].includes(value)) {
        if (
          conditionsData[externalIndex].criteria[index].selectedType ===
          'boolean'
        ) {
          if (!['true', 'false'].includes(value)) {
            conditionsData[externalIndex].criteria[index].condition = value;
          } else {
            conditionsData[externalIndex].criteria[index].condition = 'equals';
          }
        }
        conditionsData[externalIndex].criteria[index].value = value;
      }

      if (
        [
          'conversation_channel',
          'conversation_status',
          'conversation_rating',
          'business_hours',
          'day_of_week',
        ].includes(conditionsData[externalIndex].criteria[index].key)
      ) {
        conditionsData[externalIndex].criteria[index].staticValues =
          getStaticValue(getProperty.value);
        if (conditionsData[externalIndex].criteria[index].value === '')
          conditionsData[externalIndex].criteria[index].value = [];
      }

      updatedCurrentInboxViewData.conditions = conditionsData;

      return {
        ...state,
        currentInboxViewData: updatedCurrentInboxViewData,
      };
    }

    case actions.SET_INBOX_VIEW_CONDITION_INITIAL_DATA: {
      let properties = [];
      for (let category of conditionsMasterList) {
        properties = _.concat(properties, category.properties);
      }

      return {
        ...state,
        allKeysOfDisplayRules: conditionsMasterList,
        displayRulesPropertiesMetaData: conditionsDataTypes,
        allPropertiesListOfInboxAutomationConditions: properties,
      };
    }

    case actions.FETCH_INBOX_VIEW_CONTACT_PROPERTIES_META_DATA_SUCCESS:
      return {
        ...state,
        propertiesMetaData: action.payload.data,
      };
    case actions.FETCH_INBOX_VIEW_CONTACT_TAGS_LIST_SUCCESS:
      return {
        ...state,
        contactTagsData: action.payload.data,
      };
    case actions.FETCH_INBOX_VIEW_CONTACT_SEGMENTS_LIST_SUCCESS:
      return {
        ...state,
        contactSegmentsData: action.payload.data,
      };
    case actions.CHANGE_POPOVER_VISIBLE:
      return {
        ...state,
        popoverVisible: action.payload,
      };
    case actions.INITIALIZE_INBOX_APP_DATA_SUCCESS:
      let inboxAppsCanvasData = _.cloneDeep(state.inboxAppsCanvasData);
      inboxAppsCanvasData[action.payload.inbox_app_id] = action.payload.data;
      let removeInboxAppDataLoader = state.inboxAppDataLoader.filter(
        (inboxId) => inboxId !== action.payload.inbox_app_id,
      );

      return {
        ...state,
        inboxAppsCanvasData: inboxAppsCanvasData,
        inboxAppDataLoader: removeInboxAppDataLoader,
        inboxAppsCanvasLoading: {
          ...state.inboxAppsCanvasLoading,
          [action.payload.inbox_app_id]: {},
        },
      };
    case actions.INITIALIZE_INBOX_APP_DATA_FAILURE:
      inboxAppsCanvasData = _.cloneDeep(state.inboxAppsCanvasData);
      inboxAppsCanvasData[action.payload.inbox_app_id] = {};
      removeInboxAppDataLoader = state.inboxAppDataLoader.filter(
        (inboxId) => inboxId !== action.payload.inbox_app_id,
      );

      return {
        ...state,
        inboxAppsCanvasData: inboxAppsCanvasData,
        inboxAppDataLoader: removeInboxAppDataLoader,
        inboxAppsCanvasLoading: {
          ...state.inboxAppsCanvasLoading,
          [action.payload.inbox_app_id]: {},
        },
      };
    case actions.SUBMIT_INBOX_APP_DATA_SUCCESS:
      inboxAppsCanvasData = _.cloneDeep(state.inboxAppsCanvasData);
      inboxAppsCanvasData[action.payload.inbox_app_id] = action.payload.data;

      return {
        ...state,
        inboxAppsCanvasData: inboxAppsCanvasData,
        inboxAppsCanvasLoading: {
          ...state.inboxAppsCanvasLoading,
          [action.payload.inbox_app_id]: {},
        },
      };
    case actions.SET_INBOX_VISIBLE_AND_HIDDEN_WIDGETS:
      return {
        ...state,
        enabledInboxWidgets: _.cloneDeep(action.enabledInboxWidgets),
        disabledInboxWidgets: _.cloneDeep(action.disabledInboxWidgets),
      };
    case actions.CHANGE_SHOW_CREATE_VIEW_MODAL_STATUS:
      return {
        ...state,
        isShowCreateViewModal: action.payload,
        currentInboxViewData: action.isCreateNewView
          ? inboxViewDefaultData
          : state.currentInboxViewData,
      };
    case actions.CREATE_CUSTOM_INBOX_VIEW:
      return {
        ...state,
        viewModalLoader: true,
      };
    case actions.CREATE_CUSTOM_INBOX_VIEW_SUCCESS:
      return {
        ...state,
        isShowCreateViewModal: false,
        viewModalLoader: false,
      };
    case actions.CREATE_CUSTOM_INBOX_VIEW_FAILURE:
      return {
        ...state,
        viewModalLoader: false,
      };
    case actions.GET_CUSTOM_INBOX_VIEWS_LIST_SUCCESS:
      return {
        ...state,
        customInboxViewsList: action.payload,
      };
    case actions.SET_CURRENT_INBOX_VIEW_DATA:
      let updatedInboxViewData;
      state.customInboxViewsList.forEach((view) => {
        if (view.id === action.id) {
          updatedInboxViewData = {
            id: action.id,
            name: view.name,
            emoji: view.icon,
            conditions: view.filters,
          };
        }
      });

      return {
        ...state,
        currentInboxViewData: updatedInboxViewData,
      };
    case actions.UPDATE_CUSTOM_INBOX_VIEW:
      return {
        ...state,
        viewModalLoader: true,
      };
    case actions.UPDATE_CUSTOM_INBOX_VIEW_SUCCESS:
      return {
        ...state,
        isShowCreateViewModal: false,
        viewModalLoader: false,
      };
    case actions.UPDATE_CUSTOM_INBOX_VIEW_FAILURE:
      return {
        ...state,
        viewModalLoader: false,
      };
    case actions.INCREMENT_PAGE_NO:
      return {
        ...state,
        pageNo: state.pageNo + 1,
        isChatRoomsLoading: false,
      };
    case actions.RESET_PAGE_NO:
      return {
        ...state,
        pageNo: 0,
      };
    case actions.UPDATE_CUSTOM_INBOX_CONVERSATION_COUNT: {
      const { inbox_view } = action.payload,
        { conversationInboxCount } = state;
      return {
        ...state,
        conversationInboxCount: {
          ...conversationInboxCount,
          inbox_views: {
            ...conversationInboxCount.inbox_views,
            [inbox_view.id]: inbox_view.conversation_count.open,
          },
        },
        tempInboxViewCount: {
          ...state.tempInboxViewCount,
          [inbox_view.id]: inbox_view.conversation_count.open,
        },
      };
    }
    case actions.FETCH_CONTACT_CONVERSATION_PROPERTIES_SUCCESS: {
      let allProperties = [];
      for (let category of action.conversationPropertiesData) {
        allProperties = _.concat(allProperties, category);
      }
      allProperties = _.reverse(_.sortBy(allProperties, orderOfColumns));
      let allKeyArrayList = _.map(allProperties, (value) =>
        constructContactsTable(value.data_type, value.name),
      );
      return {
        ...state,
        conversationPropertiesData: action.conversationPropertiesData,
        conversationPropertiesDrawerLoader: false,
        allPropertyKeys: allProperties,
        allInboxTableColumns: allKeyArrayList,
      };
    }
    case actions.SET_ALL_CONVERSATIONS_COUNT: {
      let conversationInboxCount = {
        ...state.conversationInboxCount,
        all: action.payload,
      };
      return {
        ...state,
        conversationInboxCount,
      };
    }
    case actions.FETCH_CONTACT_CONVERSATION_PROPERTIES_FAILURE:
      return {
        ...state,
        conversationPropertiesDrawerLoader: false,
      };
    case actions.UPDATE_SNIPPET_DETAILS_SUCCESS: {
      const snippets = state.snippets.map((data) => {
          if (data.id === action.payload.id) {
            return action.payload;
          }
          return data;
        }),
        singleSnippetData = {
          ...state.singleSnippetData,
          usage_count:
            state.singleSnippetData.id === action.payload.id
              ? state.singleSnippetData.usage_count + 1
              : state.singleSnippetData.usage_count,
        };
      return {
        ...state,
        singleSnippetData,
        snippets: getSortedSnippet(state.snippetDataOrder, snippets),
        mostRecentSnippets: [
          ...new Set([action.payload.id, ...state.mostRecentSnippets]),
        ],
      };
    }
    case actions.FETCH_SNIPPETS:
      return {
        ...state,
        searchSnippetListLoader: true,
      };
    case actions.INITIALIZE_INBOX_APP_DATA:
      return {
        ...state,
        inboxAppDataLoader: [
          ...state.inboxAppDataLoader,
          action.payload.inbox_app_id,
        ],
      };
    case actions.UPDATE_ENABLED_INBOX_WIDGETS_SUCCESS:
      const { inbox_widget_preference, inbox_hidden_widgets } = action.payload;
      let visibleInboxWidgets = inbox_widget_preference
        ? inbox_widget_preference.split(',')
        : [];
      let hiddenInboxWidgets = inbox_hidden_widgets
        ? inbox_hidden_widgets.split(',')
        : [];
      return {
        ...state,
        ...getEnabledAndDisabledWidget(
          visibleInboxWidgets,
          hiddenInboxWidgets,
          state.availableInboxWidgets,
        ),
        inboxWidgetPreference: visibleInboxWidgets,
        inboxHiddenWidgets: hiddenInboxWidgets,
      };
    case actions.FETCH_INSTALLED_APPS_SUCCESS:
      return {
        ...state,
        availableInboxWidgets: [
          ...availableInboxWidgets,
          ...action.payload.map(changeAppData),
        ],
        ...getEnabledAndDisabledWidget(
          state.inboxWidgetPreference,
          state.inboxHiddenWidgets,
          [...availableInboxWidgets, ...action.payload.map(changeAppData)],
        ),
      };
    case actions.SHOW_HIDE_CONTACT_TAG_MODAL:
      return {
        ...state,
        contactTagModalVisible: action.payload,
      };
    case actions.CHANGE_PREVIOUS_CONVERSATION_DRAWER_STATUS:
      return {
        ...state,
        selectedPreviousChatMessages: {
          messages: [],
        },
        selectedPreviousConversationData: {},
        selectedPreviousConversationIdentifier: null,
      };
    case actions.SUBMIT_INBOX_APP_DATA: {
      const { inbox_app_id, component_id } = action.payload;
      return {
        ...state,
        inboxAppsCanvasLoading: {
          ...state.inboxAppsCanvasLoading,
          [inbox_app_id]: {
            ...state.inboxAppsCanvasLoading[inbox_app_id],
            [component_id]: true,
          },
        },
      };
    }
    case actions.OPEN_INBOX_APP_SHEET: {
      return {
        ...state,
        inboxAppsSheetData: action.payload,
      };
    }
    case actions.TOGGLE_INBOX_LEFT_MENU:
      return {
        ...state,
        showLeftMenu: !state.showLeftMenu,
      };
    case actions.TOGGLE_INBOX_RIGHT_MENU:
      return {
        ...state,
        showRightMenu: !state.showRightMenu,
      };
    case actions.UPDATE_SELECTED_CHAT_ROOM_CONTACT_OWNER: {
      return {
        ...state,
        selectedChatRoomPersonProfile: {
          ...state.selectedChatRoomPersonProfile,
          profile: {
            ...state.selectedChatRoomPersonProfile.profile,
            owner_data: action.payload.person.owner_data,
            person_info: {
              ...state.selectedChatRoomPersonProfile.profile.person_info,
              contact_owner: action.payload.person.contact_owner,
            },
          },
        },
      };
    }
    case actions.UPDATE_MARK_AS_NOT_SPAM_CONVERSATION: {
      return {
        ...state,
        markNotSpamLoader: true,
      };
    }
    case actions.UPDATE_MARK_AS_NOT_SPAM_CONVERSATION_SUCCESS: {
      const { conversationId } = action.payload,
        { isFromPreviousConversation } = action;
      let updatedChatRooms = cloneDeep(state.chatRooms);
      updatedChatRooms = updatedChatRooms.filter(
        (data) => data.conversation_identifier !== conversationId,
      );
      if (isFromPreviousConversation) {
        return {
          ...state,
          markNotSpamLoader: false,
          chatRooms: updatedChatRooms,
        };
      }
      return {
        ...state,
        markNotSpamLoader: false,
        chatRooms: updatedChatRooms,
        selectedChatRoom: updatedChatRooms.length ? updatedChatRooms[0] : {},
        currentConversationId: updatedChatRooms.length
          ? updatedChatRooms[0] && updatedChatRooms[0].conversation_identifier
          : null,
        currentPersonId: updatedChatRooms.length
          ? updatedChatRooms[0] && updatedChatRooms[0].person_id
          : null,
        messageInitiatedFrom: updatedChatRooms.length
          ? updatedChatRooms[0] && updatedChatRooms[0].message_initiated_from
          : 'null',
      };
    }
    case actions.UPDATE_MARK_AS_NOT_SPAM_CONVERSATION_FAILURE: {
      return {
        ...state,
        markNotSpamLoader: false,
      };
    }
    case actions.SET_VIEW_ORIGINAL_MESSAGE_MODAL_VISIBILITY: {
      return {
        ...state,
        viewOriginalMessageModalVisibility: action.payload.visible,
        viewOriginalMessageId: action.payload.messageId,
        viewOriginalMessageDate: action.payload.messageDate,
      };
    }
    case actions.GET_VIEW_ORIGINAL_MESSAGE_DETAILS: {
      return {
        ...state,
        viewOriginalMessageLoader: true,
      };
    }
    case actions.GET_VIEW_ORIGINAL_MESSAGE_DETAILS_SUCCESS: {
      return {
        ...state,
        viewOriginalMessageData: action.payload,
        viewOriginalMessageLoader: false,
      };
    }
    case actions.GET_VIEW_ORIGINAL_MESSAGE_DETAILS_FAILURE: {
      return {
        ...state,
        viewOriginalMessageData: null,
        viewOriginalMessageLoader: false,
      };
    }
    case actions.TOGGLE_NEW_CONVERSATION_SCREEN:
      return {
        ...state,
        // selectedChatRoom: {},
        // selectedChatRoomMessages: {
        //   messages: [],
        // },
        selectedChatRoomPersonProfile: {},
        showNewConversation: action.payload,
        userProfileData: {},
        activeConversationId: null,
        activePersonId: null,
        currentPersonId: null,
        selectedPreviousConversationIdentifier: null,
        currentConversationId: null,
      };
    case actions.SHOW_TO_USER:
      return {
        ...state,
        showNewConversation: action.payload
          ? state.showNewConversation
          : action.payload,
      };
    case actions.LOADER_FOR_CHAT_INFINITE_SCROLL:
      return {
        ...state,
        chatInfiniteScrollLoading: action.payload,
      };
    case actions.APPEND_INBOX_CHAT_TAGS:
      const newChatTag = [
        {
          id: action.payload.id,
          tag_name: action.payload.tag_name,
        },
      ];
      return {
        ...state,
        chatTagsData: [...newChatTag, ...state.chatTagsData],
      };
    case actions.SET_AI_ASSIST:
      return {
        ...state,
        isAiBotAssist: !state.isAiBotAssist,
      };
    case actions.UPDATE_AI_ASSIST_MODE:
      return {
        ...state,
        aiAssistMode: action.payload,
      };
    case actions.FETCH_AI_ASSIST_SUCCESS:
      return {
        ...state,
        ...(action.replyResponse !== undefined
          ? {
              aiAssistReplyResponse: {
                ...state.aiAssistReplyResponse,
                [action.conversationId]: action.replyResponse,
              },
            }
          : {
              aiAssistNoteResponse: {
                ...state.aiAssistNoteResponse,
                [action.conversationId]: action.noteResponse,
              },
            }),
      };
    case actions.AI_ASSIST_REPLY_LOADER:
      return {
        ...state,
        aiAssistReplyLoader: {
          ...state.aiAssistReplyLoader,
          [action.conversationId]: action.payload,
        },
      };
    case actions.AI_ASSIST_NOTE_LOADER:
      return {
        ...state,
        aiAssistNoteLoader: {
          ...state.aiAssistNoteLoader,
          [action.conversationId]: action.payload,
        },
      };
    case actions.SET_ACTIVE_CONVERSATION_IDENTIFIER:
      return {
        ...state,
        activeConversationId: action.cid,
      };
    case actions.SET_ACTIVE_PERSON_ID:
      return {
        ...state,
        activePersonId: action.pid,
      };
    case actions.FETCH_AI_ANSWER_FOUND_SUCCESS:
      return {
        ...state,
        askEmmaMatchFound: {
          ...state.askEmmaMatchFound,
          [action.conversationId]: action.payload,
        },
      };
    case actions.RESET_AI_ANSWER_FOUND:
      return {
        ...state,
        askEmmaMatchFound: {
          ...state.askEmmaMatchFound,
          [action.conversationId]: action.payload,
        },
      };
    case actions.AI_ASSIST_CUSTOM_ANSWER_LOADER:
      return {
        ...state,
        askEmmaLoader: {
          ...state.askEmmaLoader,
          [action.conversationId]: action.payload,
        },
      };
    case actions.FETCH_AI_CUSTOM_ANSWER_SUCCESS:
      return {
        ...state,
        askEmmaGeneratedTexts: {
          ...state.askEmmaGeneratedTexts,
          [action.conversationId]: { ...action.payload },
        },
      };
    case actions.UPDATE_AI_CUSTOM_ANSWER_MODAL_VISIBILITY:
      return {
        ...state,
        askEmmaModalVisible: action.payload.visible,
        askEmmaMode: action.payload.mode,
      };
    case actions.UPDATE_CONVERSATION_VERIFICATION_STATUS: {
      let conversations = _.cloneDeep(state.chatRooms);
      let conversationIndex = conversations.findIndex(
        (conversation) =>
          conversation.conversation_identifier ===
          action.payload.conversation_identifier,
      );
      conversations[conversationIndex] = {
        ...conversations[conversationIndex],
        is_verified: action.payload.is_verified,
      };
      return {
        ...state,
        chatRooms: conversations,
      };
    }
    case actions.SHOW_ADVANCED_SEARCH:
      return {
        ...state,
        redirectingToSearch: action.payload,
      };
    case actions.SET_INBOX_MODE:
      return {
        ...state,
        inboxViewMode: action.payload,
        selectedChatRoomPersonProfile: {},
        userProfileData: {},
        activeConversationId: null,
        activePersonId: null,
        currentPersonId: null,
        selectedPreviousConversationIdentifier: null,
        currentConversationId: null,
        showNewConversation: false,
        conversationDrawerVisible: action.payload !== 'Table',
      };
    case actions.CONVERSATION_DRAWER_STATUS:
      return {
        ...state,
        conversationDrawerVisible: action.payload,
        isPreviousConversationDrawerVisible:
          action.isPreviousConversationDrawerVisible,
      };
    case actions.LAST_CONVERSATION_ID:
      return {
        ...state,
        lastConversationId: action.payload,
      };
    case actions.SET_SEARCH_STATUS:
      return {
        ...state,
        searchStatus: action.payload,
      }

    // Copilot
    case actions.UPDATE_COPILOT_MESSAGES:
      const messages = action.payload.messages.sort((a, b) => a.id - b.id)
      return {
        ...state,
        copilotMessages: {
          ...state.copilotMessages,
          [action.payload.cid]: messages,
        },
      };
      
    case actions.APPEND_COPILOT_MESSAGES:
      const combinedMessages = state.copilotMessages[action.payload.cid].concat(action.payload.messages).sort((a, b) => a.id - b.id)
      return{
        ...state,
        copilotMessages: {
          ...state.copilotMessages,
          [action.payload.cid]: combinedMessages,
        }
      }
      
    case actions.FETCH_COPILOT_CUSTOM_ANSWER_SUCCESS:
      return {
        ...state,
        copilotEmmaLoader: {
          ...state.copilotEmmaLoader,
          [action.conversationId]: false,
        },
        copilotMessages: {
          ...state.copilotMessages,
          [action.conversationId]: [
            ...(state.copilotMessages[action.conversationId] || []),
            action.payload,
          ],
        },
      }
      
    case actions.SET_COPILOT_INFINITE_LOADING:
      return{
        ...state,
        copilotInfiniteLoading: action.payload,
      }
      
    case actions.COPILOT_CUSTOM_ANSWER_LOADER: {
      return{
        ...state,
        copilotEmmaLoader: {
          ...state.copilotEmmaLoader,
          [action.conversationId]: action.payload,
        },
      }
    }
    
    case actions.COPILOT_SUGGESTIONS_LOADER:
      return{
        ...state,
        copilotSuggestionsLoader: {
          ...state.copilotSuggestionsLoader,
          [action.conversationId]: action.payload,
        }
      }
    case actions.UPDATE_COPILOT_SUGGESTIONS:
      return{
        ...state,
        copilotSuggestions: {
          ...state.copilotSuggestions,
          [action.conversationId]: action.payload,
        }
      }
    case actions.UPDATE_SUGGESTION_VISIBILITY:
      return{
        ...state,
        showSuggestions: action.payload
      }
    case actions.FETCH_CONVERSATION_SOURCE_DETAILS:
      return {
        ...state,
        conversationSourceLoading: true,
      };
    case actions.FETCH_CONVERSATION_SOURCE_DETAILS_SUCCESS:
      return {
        ...state,
        previousConversations: {
          ...state.previousConversations,
          [action.payload.data?.person?.id]: [action.payload.data]
        },
        currentConversationId: action.payload.sourceId,
        conversationSourceLoading: false,
        currentPersonId: action.payload.data?.person?.id
      };
    case actions.FETCH_CONVERSATION_SOURCE_DETAILS_ERROR:
      return {
        ...state,
        conversationSourceLoading: false,
      };
    case actions.FROM_COPILOT_ENABLED:
      return{
        ...state,
        isFromCopilot: action.payload
      }
    default:
      return state;
  }
}
