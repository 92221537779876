import { store } from '../../redux/store';
import emailActions from '../../redux/email/actions';
import { convertInlineStyle } from '../../helpers/utility';

let Liquid = require('liquidjs');
let engine = new Liquid();

export function setPreviewEmailData(
  mailText,
  mailSubject,
  selectedPreviewUser,
) {
  engine
    .parseAndRender(replaceSpecialCharacters(mailText), selectedPreviewUser)
    .then((html) => {
      store.dispatch({
        type: emailActions.SET_PREVIEW_EMAIL_DATA,
        payload: convertInlineStyle(html),
      });
    });
  engine.parseAndRender(mailSubject, selectedPreviewUser).then((html) => {
    store.dispatch({
      type: emailActions.SET_MAIL_SUBJECT_DATA,
      payload: html,
    });
  });
}
export const replaceSpecialCharacters = (text) =>
  text.replaceAll('&lt;', '<').replaceAll('&gt;', '>');

export const removePreFixFromId = id => {
  if (id) {
    return id
      .replaceAll('m-', '')
      .replaceAll('t-', '')
      .replaceAll('c-', '')
      .replaceAll('u-', '');
  }
  return '';
};

export const getReplyToAddressPayload = address => {
  if (address.type !== 'reply_to_gist') {
    return {
      ...address,
      data: {
        assignee_id: removePreFixFromId(address.data.assignee_id),
      },
    };
  }
  let assignee_type =
    address.data.assignee_type && address.data.assignee_type.includes('m-')
      ? 'teammate'
      : address.data.assignee_type && address.data.assignee_type.includes('t-')
        ? 'team'
        : 'unassign';
  return {
    ...address,
    data: {
      ...(assignee_type !== 'unassign' && {
        assignee_id: removePreFixFromId(address.data.assignee_type),
      }),
      ...(assignee_type === 'team' && {
        assignment_method: address.data.assignment_method,
      }),
      assignee_type,
    },
  };
};

export const getFromAddressPayload = address => ({
  ...address,
  from_user_id: removePreFixFromId(address.from_user_id),
});

export const getReplyToAddress = (
  allFields,
  replyToUserId,
  isFromContactOwner,
  emailComposerData,
) => {
  if (replyToUserId && replyToUserId.includes('u-')) {
    return {
      type: `reply_to_${isFromContactOwner ? 'contact_owner_or_' : ''}user`,
      data: {
        assignee_id: replyToUserId,
      },
    };
  } else if (replyToUserId && replyToUserId.includes('c-')) {
    return {
      type: `reply_to_${
        isFromContactOwner ? 'contact_owner_or_' : ''
      }custom_team_mate`,
      data: {
        assignee_id: replyToUserId,
      },
    };
  }
  if (isFromContactOwner) {
    const emailReplyToUserId = emailComposerData.out_bound_address[0]
      ? {
        type: 'reply_to_contact_owner_or_custom_team_mate',
        data: { assignee_id: emailComposerData.out_bound_address[0].id },
      }
      : {
        type: 'reply_to_contact_owner_or_user',
        data: {
          assignee_id: emailComposerData.team_mates
            ? emailComposerData.team_mates[0].id
            : emailComposerData.team_mate[0].id,
        },
      };
    return {
      ...emailReplyToUserId,
    };
  } else {
    return {
      type: 'reply_to_gist',
      data: {
        assignee_id: allFields.reply_to_user_id,
        assignment_method:
          allFields.assignee_type && allFields.assignee_type.includes('t-')
            ? allFields.assignment_method || 'team_inbox'
            : undefined,
        assignee_type: allFields.assignee_type || 'unassign',
      },
    };
  }
};

export const getFromAddress = (fromUserId, isFromContactOwner) => {
  if (fromUserId && fromUserId.includes('u-')) {
    return {
      type: `from_${isFromContactOwner ? 'contact_owner_or_' : ''}user`,
      from_user_id: fromUserId,
    };
  } else if (fromUserId && fromUserId.includes('c-')) {
    return {
      type: `from_${
        isFromContactOwner ? 'contact_owner_or_' : ''
      }custom_team_mate`,
      from_user_id: fromUserId,
    };
  }
};

export const getToUserData = data => {
  if (data.reply_to_address && data.reply_to_address.type) {
    if (data.reply_to_address.type !== 'reply_to_gist') {
      return {
        ...data.reply_to_address,
        data: {
          assignee_id: `${
            data.reply_to_address.type.includes('custom_team_mate') ? 'c' : 'u'
          }-${data.reply_to_address.data.assignee_id}`,
        },
      };
    }
    return {
      ...data.reply_to_address,
      data: {
        assignee_id: 'team_inbox',
        assignment_method: data.reply_to_address.data.assignment_method,
        assignee_type:
          data.reply_to_address.data.assignee_type === 'unassign'
            ? 'unassign'
            : `${
                data.reply_to_address.data.assignee_type === 'team' ? 't' : 'm'
              }-${data.reply_to_address.data.assignee_id}`,
      },
    };
  }
  return '';
};

export const getFromUserData = data => {
  if (data.from_address && data.from_address.type) {
    return {
      ...data.from_address,
      from_user_id: `${
        data.from_address.type.includes('custom_team_mate') ? 'c' : 'u'
      }-${data.from_address.from_user_id}`,
    };
  }
  return '';
};

export const getCriteriaValue = (criteria, userList = []) => {
  if (criteria) {
    if (
      typeof criteria.value === 'object' &&
      criteria.value.length &&
      criteria.key === 'contact_owner'
    ) {
      return userList
        .filter(user => criteria.value.includes(user.id.toString()))
        .map(user => user.full_name);
    }
    return criteria.value;
  }
  return criteria;
};
