import React, { Fragment } from 'react';
import { Layout, Menu, Row, Col, Dropdown, Tooltip, Progress, Badge } from 'antd';
import './style.scss';
import { NavLink } from 'react-router-dom';
import {
  extractConversationIdentifier,
  extractUserProfileData,
  fetchCurrentContent,
  fetchProjectName,
  fetchProjectSecretKey,
  fetchRestrictedFeatures,
  getAppDetail,
  isGistDomain,
  isRestricted,
  isLiveViewRestricted,
  isTemplateEnabled,
  isPrimeCouponWorkspace,
  fetchAddonContent, fetchHiddenFeatures,
} from '../../helpers/utility';
import actions from '../../redux/auth/actions';
import { store } from '../../redux/store';
import RestrictedFeatureNotification from './RestrictedFeatureNotification';
import { menu, restrictedPage } from './constants';
import { freePlan } from '../Settings/constant';
import settings from '../../settings/index';
import isElectron from 'is-electron';
import SubscriptionAddonNotification from './SubscriptionAddonNotification';

import { displayRestrictedFeatureNotification } from '../../helpers/utility';
const { Header } = Layout;
const SubMenu = Menu.SubMenu;

class TopNavBar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentMenu: '',
      path: '',
      restrictedMenu: '',
      hasUnreadNews: window.gistHasUnreadNews || false,
    };
    if (!this.props.projectBlocked) {
      store.dispatch({
        type: actions.FETCH_GET_STARTED_DATA,
      });
    }
    store.dispatch({
      type: actions.GET_ALL_PROJECTS,
    });
  }

  componentDidUpdate(prevProps) {
    const { pathname } = window.location,
      { path } = this.state,
      { modalContactId } = this.props;
    if (path !== pathname && !modalContactId) {
      this.setState({ path: pathname });
      if (
        pathname.match('reports/support') ||
        pathname.match('reports/marketing')
      ) {
        this.setState({ currentMenu: 'reports' });
      } else if (
        pathname.match('conversations') ||
        pathname.match('connect-channel')
      ) {
        this.setState({ currentMenu: 'conversations' });
      } else if (pathname.match('behavioral')) {
        this.setState({ currentMenu: 'outbound' });
      } else if (pathname.match('broadcast')) {
        this.setState({ currentMenu: 'outbound' });
      } else if (pathname.match('workflows')) {
        this.setState({ currentMenu: 'outbound' });
      } else if (pathname.match('workflow-templates')) {
        this.setState({ currentMenu: 'workflow-templates' });
      } else if (pathname.match('workflow/choose-template')) {
        this.setState({ currentMenu: 'outbound' });
      } else if (
        pathname.match('chatbot-templates') ||
        pathname.match('bot-templates')
      ) {
        this.setState({ currentMenu: 'chatbot-templates' });
      } else if (pathname.match('chat-templates')) {
        this.setState({ currentMenu: 'chat-templates' });
      } else if (pathname.match('post-templates')) {
        this.setState({ currentMenu: 'post-templates' });
      } else if (pathname.match('survey-templates')) {
        this.setState({ currentMenu: 'survey-templates' });
      } else if (pathname.match('templates')) {
        this.setState({ currentMenu: 'templates' });
      } else if (pathname.match('chatbot/choose-template')) {
        this.setState({ currentMenu: 'outbound' });
      } else if (pathname.match('survey/choose-template')) {
        this.setState({ currentMenu: 'outbound' });
      } else if (pathname.match('chat/choose-template')) {
        this.setState({ currentMenu: 'outbound' });
      } else if (pathname.match('post/choose-template')) {
        this.setState({ currentMenu: 'outbound' });
      } else if (pathname.match('settings')) {
        this.setState({ currentMenu: 'settings' });
      } else if (pathname.match('rules')) {
        this.setState({ currentMenu: 'rules' });
      } else if (pathname.match('knowledge-base')) {
        this.setState({
          currentMenu: 'knowledgeBase',
          restrictedMenu: menu.knowledgeBase,
        });
      } else if (pathname.match('support/emma')) {
        this.setState({ currentMenu: 'emma' });
      } else if (pathname.match('meetings')) {
        this.setState({ currentMenu: 'meetings' });
      } else if (pathname.match('forms')) {
        this.setState({ currentMenu: 'outbound' });
      } else if (pathname.match('events')) {
        this.setState({ currentMenu: 'events' });
      } else if (pathname.match('profile')) {
        this.setState({ currentMenu: 'settings' });
      } else if (
        pathname.match('referral') ||
        pathname.match('apps') ||
        pathname.match('oauth/authorize')
      ) {
        this.setState({ currentMenu: 'settings' });
      } else if (pathname.match('live-view')) {
        this.setState({ currentMenu: 'liveView' });
      } else if (
        pathname.match('message') ||
        pathname.match('campaigns') ||
        pathname.match('chats') ||
        pathname.match('emails') ||
        pathname.match('bots') ||
        pathname.match('surveys') ||
        pathname.match('tours')
      ) {
        this.setState({ currentMenu: 'outbound' });
      } else if (pathname.match('deals') || pathname.match('deal')) {
        this.setState({ currentMenu: 'deals' });
      } else if (pathname.match('companies') || pathname.match('company')) {
        this.setState({ currentMenu: 'companies' });
      } else {
        this.setState({ currentMenu: 'contacts' });
      }
    }

    if (
      prevProps.allConversationsCount !== this.props.allConversationsCount &&
      isElectron
    ) {
      this.pushConversationCount(this.props.allConversationsCount);
    }
  }

  componentDidMount() {
    if (isElectron) {
      this.pushConversationCount(this.props.allConversationsCount);
    }
    document.addEventListener('gistHasUnreadNewsUpdated', this.handleNewsUpdate);
  }

  pushConversationCount = count => {
    window.postMessage({
      type: 'update_open_conversation_count',
      data: count,
    });
  };
  changeActiveMenu = (key) => {
    this.setState({ currentMenu: key });
  };

  signOut = () => {
    store.dispatch({
      type: actions.SIGN_OUT,
    });
  };
  
  handleNewsUpdate = () => {
    this.setState({ hasUnreadNews: window.gistHasUnreadNews });
  };
  
  componentWillUnmount() {
    document.removeEventListener('gistHasUnreadNewsUpdated', this.handleNewsUpdate);
  }
  
  
  handleGistUpdates = () => {
    window.gist && window.gist.chat('navigate', 'newsCenter');
  }

  render() {
    const {
      projects,
      profile,
      isGetStarted,
      completedCount,
      projectBlocked,
      plan,
      owner,
      openConversationCount,
    } = this.props;
    const { name, logo } = getAppDetail();
    let filteredProjects = projects.filter(
        (project) => project.secret_key !== fetchProjectSecretKey(),
      ),
      currentContent = fetchCurrentContent(window.location.pathname),
      addonContent = fetchAddonContent(window.location.pathname);
    const { gtContactData, messengerTabsData } = window;
    const userType = gtContactData?.user_type === 0 ? 'visitors' : 'contacts';
    const hasNewsTab = messengerTabsData?.[userType].some(tab => tab?.key === 'news');
    const subMenu = (
      <Menu>
        <Menu.Item>
          <NavLink to={`/projects/${fetchProjectSecretKey()}/account`}>
            <i className="fal fa-cog" />
            Account Settings
          </NavLink>
        </Menu.Item>
        <Menu.Divider />
        <SubMenu
          title={
            <div>
              <Tooltip title={fetchProjectName()} placement="topLeft">
                <p>{fetchProjectName()}</p>
              </Tooltip>
              <p>{fetchProjectSecretKey()}</p>
            </div>
          }
          popupClassName="user-profile-submenu assign-dropdown"
        >
          <Menu.Item>
            <NavLink to={'/workspaces'}>See all workspaces</NavLink>
          </Menu.Item>
          {Object.keys(filteredProjects).length > 0 && <Menu.Divider />}
          {filteredProjects.slice(0, 10).map((project) => (
            <Menu.Item key={project.id}>
              <a href={`/projects/${project.secret_key}/contacts`}>
                <Tooltip title={project.project_name} placement="left">
                  <span>{project.project_name}</span>
                </Tooltip>
                - {project.secret_key}
              </a>
            </Menu.Item>
          ))}
        </SubMenu>
        <Menu.Divider />
        <Menu.Item>
          <NavLink to={`/projects/${fetchProjectSecretKey()}/apps`}>
            <i className="fal fa-store" />
            Apps & Integrations
          </NavLink>
        </Menu.Item>
        <Menu.Item>
          <a
            href="https://updates.getgist.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            <i className="fal fa-bullhorn" /> Product Updates{' '}
            <i className="fal fa-external-link" style={{ marginLeft: '5px' }} />
          </a>
        </Menu.Item>
        {isGistDomain() && (
          <Menu.Item>
            <a
              target="_blank"
              href="https://ideas.getgist.com/public"
              rel="noopener noreferrer"
            >
              <i className="fal fa-lightbulb" />
              Suggest a Feature
            </a>
          </Menu.Item>
        )}
        {freePlan.includes(plan) &&
          isGistDomain() &&
          owner && (
            <Menu.Item>
              <NavLink to={`/projects/${fetchProjectSecretKey()}/referral`}>
                Refer a friend
              </NavLink>
            </Menu.Item>
          )}
        <Menu.Item onClick={() => this.signOut()}>
          <i className="fal fa-sign-out-alt" />
          Sign Out
        </Menu.Item>
      </Menu>
    );
    const cid = extractConversationIdentifier();
    const isSupportBotVisible = !fetchHiddenFeatures().includes(menu.supportBot) &&
      (isPrimeCouponWorkspace()
      ? !fetchRestrictedFeatures().includes(menu.supportBot)
      : true);
    return (
      <Fragment>
        <Header className="top-navbar">
          <Row>
            <Col span={17} className="left">
              <NavLink
                to={`/projects/${fetchProjectSecretKey()}/getting-started`}
              >
                <img alt={name} src={logo} />
              </NavLink>
              <Menu
                theme="light"
                mode="horizontal"
                selectedKeys={[this.state.currentMenu]}
              >
                <SubMenu
                  className="item navbar-submenu"
                  key="contacts"
                  title={<span>Contacts</span>}
                  popupClassName="top-navbar-dropdown"
                >
                  <Menu.Item
                    className="item"
                    key="contacts"
                    onClick={() => this.changeActiveMenu('contacts')}
                  >
                    <NavLink
                      to={`/projects/${fetchProjectSecretKey()}/contacts`}
                    >
                      <i className="fal fa-user" /> Contacts
                    </NavLink>
                  </Menu.Item>
                  <Menu.Item
                    className="item"
                    key="companies"
                    onClick={() => this.changeActiveMenu('companies')}
                  >
                    <NavLink
                      to={`/projects/${fetchProjectSecretKey()}/companies`}
                    >
                      <i className="fal fa-users" /> Companies
                    </NavLink>
                  </Menu.Item>
                </SubMenu>
                <Menu.Item
                  className="item"
                  key="conversations"
                  onClick={() => this.changeActiveMenu('conversations')}
                >
                  <NavLink
                    to={`/projects/${fetchProjectSecretKey()}/conversations/${
                      cid ? cid : 'latest'
                    }`}
                  >
                    <i className="fal fa-inbox" /> Inbox
                    {openConversationCount ? (
                      <span>
                        {' '}
                        ({openConversationCount > 0 ? openConversationCount : 0}
                        ){' '}
                      </span>
                    ) : (
                      ''
                    )}
                  </NavLink>
                </Menu.Item>
                <Menu.Item
                  className="item"
                  key="outbound"
                  onClick={() => this.changeActiveMenu('outbound')}
                >
                  <NavLink
                    to={`/projects/${fetchProjectSecretKey()}/messages/email`}
                  >
                    <i className="fal fa-paper-plane" /> Outbound
                  </NavLink>
                </Menu.Item>
                {/*<SubMenu*/}
                {/*  className="item navbar-submenu"*/}
                {/*  key="marketing"*/}
                {/*  title={<span>Marketing</span>}*/}
                {/*>*/}
                {/*  <Menu.Item*/}
                {/*    key="message"*/}
                {/*    onClick={() => this.changeActiveMenu('message')}*/}
                {/*  >*/}
                {/*    <NavLink*/}
                {/*      to={`/projects/${fetchProjectSecretKey()}/messages/email`}*/}
                {/*    >*/}
                {/*      Messages*/}
                {/*    </NavLink>*/}
                {/*  </Menu.Item>*/}
                {/*  <Menu.Item*/}
                {/*    key="events"*/}
                {/*    onClick={() => this.changeActiveMenu('events')}*/}
                {/*  >*/}
                {/*    <NavLink to={`/projects/${fetchProjectSecretKey()}/events`}>*/}
                {/*      Event Tracking*/}
                {/*    </NavLink>*/}
                {/*  </Menu.Item>*/}
                {/*</SubMenu>*/}

                {isTemplateEnabled() && (
                  <SubMenu
                    className="item navbar-submenu"
                    key="templates"
                    title={<span>Templates</span>}
                    popupClassName="top-navbar-dropdown"
                  >
                    <Menu.Item
                      key={'templates'}
                      onClick={() => this.changeActiveMenu('templates')}
                    >
                      <NavLink
                        to={`/projects/${fetchProjectSecretKey()}/templates`}
                      >
                        Form Templates
                      </NavLink>
                    </Menu.Item>
                    <Menu.Item
                      key={'form-template'}
                      onClick={() => this.changeActiveMenu('form-template')}
                    >
                      <NavLink
                        to={`/projects/${fetchProjectSecretKey()}/form-template`}
                      >
                        Form Templates v3
                      </NavLink>
                    </Menu.Item>
                    <Menu.Item
                      key="workflow-templates"
                      onClick={() =>
                        this.changeActiveMenu('workflow-templates')
                      }
                    >
                      <NavLink
                        to={`/projects/${fetchProjectSecretKey()}/workflow-templates`}
                      >
                        Workflow Templates
                      </NavLink>
                    </Menu.Item>
                    <Menu.Item
                      key="chatbot-templates"
                      onClick={() => this.changeActiveMenu('chatbot-templates')}
                    >
                      <NavLink
                        to={`/projects/${fetchProjectSecretKey()}/chatbot-templates`}
                      >
                        Chatbot Templates
                      </NavLink>
                    </Menu.Item>
                    <Menu.Item
                      key="survey-templates"
                      onClick={() => this.changeActiveMenu('survey-templates')}
                    >
                      <NavLink
                        to={`/projects/${fetchProjectSecretKey()}/survey-templates`}
                      >
                        Survey Templates
                      </NavLink>
                    </Menu.Item>
                    <Menu.Item
                      key="chat-templates"
                      onClick={() => this.changeActiveMenu('chat-templates')}
                    >
                      <NavLink
                        to={`/projects/${fetchProjectSecretKey()}/chat-templates`}
                      >
                        Chat Templates
                      </NavLink>
                    </Menu.Item>
                    <Menu.Item
                      key="post-templates"
                      onClick={() => this.changeActiveMenu('post-templates')}
                    >
                      <NavLink
                        to={`/projects/${fetchProjectSecretKey()}/post-templates`}
                      >
                        Post Templates
                      </NavLink>
                    </Menu.Item>
                  </SubMenu>
                )}

                <SubMenu
                  className="item navbar-submenu"
                  key="sub2"
                  title={<span>Sales</span>}
                  popupClassName="top-navbar-dropdown"
                >
                  <Menu.Item
                    key="deals"
                    onClick={() => this.changeActiveMenu('deals')}
                  >
                    <NavLink to={`/projects/${fetchProjectSecretKey()}/deals`}>
                      <i className="fal fa-suitcase" /> Deals
                    </NavLink>
                  </Menu.Item>
                  <Menu.Item
                    key="meetings"
                    onClick={() => this.changeActiveMenu('meetings')}
                  >
                    <NavLink
                      to={`/projects/${fetchProjectSecretKey()}/meetings`}
                    >
                      <i className="fal fa-calendar-alt" /> Meetings
                    </NavLink>
                  </Menu.Item>
                  {!fetchHiddenFeatures().includes(menu.liveView) &&
                    (!isRestricted(restrictedPage.liveView) || !isLiveViewRestricted()) && !isPrimeCouponWorkspace() && (
                      <Menu.Item
                        key="liveView"
                        onClick={() => this.changeActiveMenu('liveView')}
                      >
                        <NavLink
                          to={`/projects/${fetchProjectSecretKey()}/live-view`}
                        >
                          <i className="fal fa-mask" /> Live View
                        </NavLink>
                      </Menu.Item>
                    )}
                </SubMenu>
                {!isRestricted(restrictedPage.knowledgeBase) && (
                  <SubMenu
                    className="item navbar-submenu"
                    key="sub3"
                    title={
                      <Fragment>
                        <span>Support</span>
                      </Fragment>
                    }
                    popupClassName="top-navbar-dropdown"
                  >
                    <Menu.Item
                      key="knowledgeBase"
                      onClick={() => this.changeActiveMenu('knowledgeBase')}
                    >
                      <NavLink
                        to={`/projects/${fetchProjectSecretKey()}/knowledge-base/articles`}
                      >
                        <i className="fal fa-book-open" /> Knowledge Base
                      </NavLink>
                    </Menu.Item>
                    {isSupportBotVisible && (
                      <Menu.Item
                        key="emma"
                        onClick={() => this.changeActiveMenu('emma')}
                      >
                        <NavLink
                          to={`/projects/${fetchProjectSecretKey()}/support/emma/setup`}
                        >
                          <i className="fal fa-robot" /> Emma
                        </NavLink>
                      </Menu.Item>
                    )}
                  </SubMenu>
                )}
                {!isRestricted(restrictedPage.conversationReport) && (
                  <Menu.Item
                    className="item"
                    key="reports"
                    onClick={() => this.changeActiveMenu('reports/support')}
                  >
                    <NavLink
                      to={`/projects/${fetchProjectSecretKey()}/reports/support/conversations`}
                    >
                      <i className="fal fa-chart-column" />
                      Reports
                    </NavLink>
                  </Menu.Item>
                )}
                <Menu.Item
                  className="item"
                  key="settings"
                  onClick={() => this.changeActiveMenu('settings')}
                >
                  <NavLink
                    to={`/projects/${fetchProjectSecretKey()}/settings/${
                      localStorage.getItem('currentSettingsUrl') &&
                      !(localStorage.getItem('currentSettingsUrl') === 'apps')
                        ? localStorage.getItem('currentSettingsUrl')
                        : 'workspace-defaults'
                    }`}
                  >
                    <i className="fal fa-cog" /> Settings
                  </NavLink>
                </Menu.Item>
              </Menu>
            </Col>
            <Col span={7} className="right">
              <i
                className="fas fa-keyboard action-icons"
                onClick={() => this.props.handleKeyboardShortcutsDisplay(true)}
              />
              {(isGistDomain() && hasNewsTab) && (
                <Badge dot={this.state.hasUnreadNews}>
                  <i className="fas fa-bell gist-updates-tigger action-icons" onClick={this.handleGistUpdates}/>
                </Badge>
              )}
              <i
                className="fal fa-circle-question action-icons"
                onClick={() => window.gist.chat('navigate', 'articles')}
              />
              <Dropdown
                overlay={subMenu}
                overlayClassName="user-profile-dropdown assign-dropdown"
                placement="bottomLeft"
              >
                <div className="right-content">
                  <span
                    className={`active-status-dot active-status ${profile.agent_status}`}
                  />
                  <img alt="user" src={extractUserProfileData().avatar_url} />
                  <span>{extractUserProfileData().full_name}</span>
                  <i className="fas fa-ellipsis-vertical" />
                  {/*TODO: Need to tooltip for name in future*/}
                </div>
              </Dropdown>
            </Col>
          </Row>
        </Header>
        {displayRestrictedFeatureNotification(currentContent) && (
          <RestrictedFeatureNotification currentContent={currentContent} />
        )}
        {displayRestrictedFeatureNotification(addonContent) && (
          <SubscriptionAddonNotification addonContent={addonContent} />
        )}
      </Fragment>
    );
  }
}

export default TopNavBar;
