const actions = {
  FETCH_WORKFLOW_LIST: 'FETCH_WORKFLOW_LIST',
  FETCH_WORKFLOW_LIST_SUCCESS: 'FETCH_WORKFLOW_LIST_SUCCESS',
  APPEND_WORKFLOW_LIST: 'APPEND_WORKFLOW_LIST',
  APPEND_WORKFLOW_LIST_SUCCESS: 'APPEND_WORKFLOW_LIST_SUCCESS',
  APPEND_AUTO_EMAIL: 'APPEND_AUTO_EMAIL',
  APPEND_AUTO_EMAIL_SUCCESS: 'APPEND_AUTO_EMAIL_SUCCESS',
  SET_WORKFLOW_STATUS: 'SET_WORKFLOW_STATUS',
  SET_WORKFLOW_LOADER: 'SET_WORKFLOW_LOADER',
  SET_WORKFLOW_SEARCH_VALUE: 'SET_WORKFLOW_SEARCH_VALUE',
  SET_WORKFLOW_ID: 'SET_WORKFLOW_ID',
  SET_WORKFLOW_DRAWER_STATUS: 'SET_WORKFLOW_DRAWER_STATUS',
  CREATE_WORKFLOW: 'CREATE_WORKFLOW',
  CREATE_WORKFLOW_SUCCESS: 'CREATE_WORKFLOW_SUCCESS',
  CREATE_WORKFLOW_FAILURE: 'CREATE_WORKFLOW_FAILURE',
  DELETE_WORKFLOW: 'DELETE_WORKFLOW',
  SET_WORKFLOW_SORT_KEY_AND_VALUE: 'SET_WORKFLOW_SORT_KEY_AND_VALUE',
  FETCH_WORKFLOW_TRIGGER_LIST: 'FETCH_WORKFLOW_TRIGGER_LIST',
  FETCH_WORKFLOW_TRIGGER_LIST_SUCCESS: 'FETCH_WORKFLOW_TRIGGER_LIST_SUCCESS',
  FETCH_WORKFLOW_ACTION_LIST: 'FETCH_WORKFLOW_ACTION_LIST',
  SET_WORKFLOW_SPLIT_DRAWER_STATUS: 'SET_WORKFLOW_SPLIT_DRAWER_STATUS',
  FETCH_WORKFLOW_ACTION_LIST_SUCCESS: 'FETCH_WORKFLOW_ACTION_LIST_SUCCESS',
  SET_WORKFLOW_TRIGGER_DRAWER_STATUS: 'SET_WORKFLOW_TRIGGER_DRAWER_STATUS',
  SET_WORKFLOW_COMPONENT_DRAWER_STATUS: 'SET_WORKFLOW_COMPONENT_DRAWER_STATUS',
  SET_WORKFLOW_TRIGGER_FORM_DRAWER_STATUS:
    'SET_WORKFLOW_TRIGGER_FORM_DRAWER_STATUS',
  SET_WORKFLOW_ACTION_CREATE_AND_EDIT_DRAWER_STATUS:
    'SET_WORKFLOW_ACTION_CREATE_AND_EDIT_DRAWER_STATUS',
  SET_WORKFLOW_TRIGGER_TYPE: 'SET_WORKFLOW_TRIGGER_TYPE',
  FETCH_WORKFLOW_CAMPAIGN_LIST: 'FETCH_WORKFLOW_CAMPAIGN_LIST',
  FETCH_WORKFLOW_CAMPAIGN_LIST_SUCCESS: 'FETCH_WORKFLOW_CAMPAIGN_LIST_SUCCESS',
  FETCH_WORKFLOW_CAMPAIGN_LIST_FAILURE: 'FETCH_WORKFLOW_CAMPAIGN_LIST_FAILURE',
  FETCH_WORKFLOW_FORM_LIST: 'FETCH_WORKFLOW_FORM_LIST',
  FETCH_WORKFLOW_FORM_LIST_SUCCESS: 'FETCH_WORKFLOW_FORM_LIST_SUCCESS',
  FETCH_WORKFLOW_FORM_LIST_FAILURE: 'FETCH_WORKFLOW_FORM_LIST_FAILURE',
  FETCH_WORKFLOW_EMAIL_LIST: 'FETCH_WORKFLOW_EMAIL_LIST',
  FETCH_WORKFLOW_EMAIL_LIST_SUCCESS: 'FETCH_WORKFLOW_EMAIL_LIST_SUCCESS',
  FETCH_WORKFLOW_TAG_LIST: 'FETCH_WORKFLOW_TAG_LIST',
  FETCH_WORKFLOW_TAG_LIST_SUCCESS: 'FETCH_WORKFLOW_TAG_LIST_SUCCESS',
  FETCH_WORKFLOW_TAG_LIST_FAILURE: 'FETCH_WORKFLOW_TAG_LIST_FAILURE',
  FETCH_ACTION_WORKFLOW_LIST: 'FETCH_ACTION_WORKFLOW_LIST',
  FETCH_ACTION_WORKFLOW_LIST_SUCCESS: 'FETCH_ACTION_WORKFLOW_LIST_SUCCESS',
  FETCH_ACTION_WORKFLOW_LIST_FAILURE: 'FETCH_ACTION_WORKFLOW_LIST_FAILURE',
  FETCH_WORKFLOW_EMAIL_LINK_LIST: 'FETCH_WORKFLOW_EMAIL_LINK_LIST',
  FETCH_WORKFLOW_EMAIL_LINK_LIST_SUCCESS:
    'FETCH_WORKFLOW_EMAIL_LINK_LIST_SUCCESS',
  CREATE_COMPONENT: 'CREATE_COMPONENT',
  CREATE_COMPONENT_SUCCESS: 'CREATE_COMPONENT_SUCCESS',
  DELAY_CREATED: 'DELAY_CREATED',
  SET_WORKFLOW_ACTION_TYPE: 'SET_WORKFLOW_ACTION_TYPE',
  FETCH_WORKFLOW_CAMPAIGN_EMAIL_LIST_SUCCESS:
    'FETCH_WORKFLOW_CAMPAIGN_EMAIL_LIST_SUCCESS',
  FETCH_WORKFLOW_PROPERTIES: 'FETCH_WORKFLOW_PROPERTIES',
  FETCH_WORKFLOW_PROPERTIES_SUCCESS: 'FETCH_WORKFLOW_PROPERTIES_SUCCESS',
  FETCH_WORKFLOW_PROPERTIES_FAILURE: 'FETCH_WORKFLOW_PROPERTIES_FAILURE',
  FETCH_WORKFLOW_PROPERTIES_META_DATA: 'FETCH_WORKFLOW_PROPERTIES_META_DATA',
  FETCH_WORKFLOW_PROPERTIES_META_DATA_SUCCESS:
    'FETCH_WORKFLOW_PROPERTIES_META_DATA_SUCCESS',
  FETCH_WORKFLOW_TEAMMATE_LIST: 'FETCH_WORKFLOW_TEAMMATE_LIST',
  FETCH_WORKFLOW_TEAMMATE_LIST_SUCCESS: 'FETCH_WORKFLOW_TEAMMATE_LIST_SUCCESS',
  FETCH_WORKFLOW_EDITOR_COMPONENTS: 'FETCH_WORKFLOW_EDITOR_COMPONENTS',
  DELETE_WORKFLOW_EDITOR_COMPONENT: 'DELETE_WORKFLOW_EDITOR_COMPONENT',
  DELETE_WORKFLOW_EDITOR_COMPONENT_SUCCESS:
    'DELETE_WORKFLOW_EDITOR_COMPONENT_SUCCESS',
  FETCH_WORKFLOW_CUSTOM_FIELD_LIST: 'FETCH_WORKFLOW_CUSTOM_FIELD_LIST',
  FETCH_WORKFLOW_CUSTOM_FIELD_LIST_SUCCESS:
    'FETCH_WORKFLOW_CUSTOM_FIELD_LIST_SUCCESS',
  SET_WORKFLOW_DELAY_DRAWER_STATUS: 'SET_WORKFLOW_DELAY_DRAWER_STATUS',
  SET_WORKFLOW_DECISION_DRAWER_STATUS: 'SET_WORKFLOW_DECISION_DRAWER_STATUS',
  UPDATE_COMPONENT: 'UPDATE_COMPONENT',
  UPDATE_COMPONENT_SUCCESS: 'UPDATE_COMPONENT_SUCCESS',
  SET_WORKFLOW_FORK_DRAWER_STATUS: 'SET_WORKFLOW_FORK_DRAWER_STATUS',
  SET_WORKFLOW_EXIT_DRAWER_STATUS: 'SET_WORKFLOW_EXIT_DRAWER_STATUS',
  SET_WORKFLOW_COMPONENT_DELETE_DRAWER_STATUS:
    'SET_WORKFLOW_COMPONENT_DELETE_DRAWER_STATUS',
  RESET_WORKFLOW_CREATE_LOADER_STATUS: 'RESET_WORKFLOW_CREATE_LOADER_STATUS',
  SET_WORKFLOW_TRIGGER_VIEW_DRAWER_STATUS:
    'SET_WORKFLOW_TRIGGER_VIEW_DRAWER_STATUS',
  UPDATE_WORKFLOW_TRIGGERS_DETAIL: 'UPDATE_WORKFLOW_TRIGGERS_DETAIL',
  FETCH_WORKFLOW_EDITOR_COMPONENTS_SUCCESS:
    'FETCH_WORKFLOW_EDITOR_COMPONENTS_SUCCESS',
  UPDATE_WORKFLOW: 'UPDATE_WORKFLOW',
  UPDATE_WORKFLOW_SUCCESS: 'UPDATE_WORKFLOW_SUCCESS',
  SET_WORKFLOW_DETAIL: 'SET_WORKFLOW_DETAIL',
  UPDATE_WORKFLOW_STATUS_CHANGE_LOADER: 'UPDATE_WORKFLOW_STATUS_CHANGE_LOADER',
  UPDATE_WORKFLOW_NAME_CHANGE_LOADER: 'UPDATE_WORKFLOW_NAME_CHANGE_LOADER',
  SET_WORKFLOW_GOTO_DRAWER_STATUS: 'SET_WORKFLOW_GOTO_DRAWER_STATUS',
  SET_DELAY_TYPE: 'SET_DELAY_TYPE',
  FETCH_WORKFLOW_USERS_LIST: 'FETCH_WORKFLOW_USERS_LIST',
  FETCH_WORKFLOW_USERS_LIST_SUCCESS: 'FETCH_WORKFLOW_USERS_LIST_SUCCESS',
  CLEAR_WORKFLOW_CUSTOM_FIELDS: 'CLEAR_WORKFLOW_CUSTOM_FIELDS',
  SET_WORKFLOW_TRIGGER_MATCHES_A_CUSTOM_AUDIENCE: 'SET_WORKFLOW_TRIGGER_MATCHES_A_CUSTOM_AUDIENCE',
  SHOW_EMPTY_PAGE_LOADER: 'SHOW_EMPTY_PAGE_LOADER',
  TRIGGER_OPTION: 'TRIGGER_OPTION',
  SET_WORKFLOW_GOAL_DRAWER: 'SET_WORKFLOW_GOAL_DRAWER',
  APPEND_WORKFLOW_TAG_LIST: 'APPEND_WORKFLOW_TAG_LIST',
  UPDATE_WORKFLOW_TEMPLATE: 'UPDATE_WORKFLOW_TEMPLATE',
  UPDATE_WORKFLOW_TEMPLATE_SUCCESS: 'UPDATE_WORKFLOW_TEMPLATE_SUCCESS',
  UPDATE_WORKFLOW_TEMPLATE_FAILURE: 'UPDATE_WORKFLOW_TEMPLATE_FAILURE',
  UPDATE_WORKFLOW_TEMPLATE_DRAWER_VISIBLE:
    'UPDATE_WORKFLOW_TEMPLATE_DRAWER_VISIBLE',
  DUPLICATE_WORKFLOW: 'DUPLICATE_WORKFLOW',
  DUPLICATE_WORKFLOW_SUCCESS: 'DUPLICATE_WORKFLOW_SUCCESS',
  DUPLICATE_WORKFLOW_FAILURE: 'DUPLICATE_WORKFLOW_FAILURE',
  SET_WORKFLOW_TEMPLATE_STATUS_LOADER: 'SET_WORKFLOW_TEMPLATE_STATUS_LOADER',
  SET_ALL_COMPONENTS_CONFIGURED: 'SET_ALL_COMPONENTS_CONFIGURED',
  EMAIL_ACTION_VISIBLE: 'EMAIL_ACTION_VISIBLE',
  WORKFLOW_CREATE_BROADCAST_DRAWER: 'WORKFLOW_CREATE_BROADCAST_DRAWER',
  WORKFLOW_FETCH_TEMPLATES: 'WORKFLOW_FETCH_TEMPLATES',
  WORKFLOW_FETCH_TEMPLATES_SUCCESS: 'WORKFLOW_FETCH_TEMPLATES_SUCCESS',
  SET_SELECTED_TEMPLATE_ID: 'SET_SELECTED_TEMPLATE_ID',
  SET_SELECTED_TEMPLATE_CONTENT: 'SET_SELECTED_TEMPLATE_CONTENT',
  SAVE_BROADCAST_DETAILS: 'SAVE_BROADCAST_DETAILS',
  RESET_BROADCAST_DETAILS: 'RESET_BROADCAST_DETAILS',
  EDIT_BROADCAST_DETAILS: 'EDIT_BROADCAST_DETAILS',
  EDIT_BROADCAST_DETAILS_SUCCESS: 'EDIT_BROADCAST_DETAILS_SUCCESS',
  VALIDATE_EMAIL_NAME: 'VALIDATE_EMAIL_NAME',
  VALIDATE_EMAIL_NAME_SUCCESS: 'VALIDATE_EMAIL_NAME_SUCCESS',
  VALIDATE_EMAIL_NAME_FAILURE: 'VALIDATE_EMAIL_NAME_FAILURE',
  GET_PRODUCTS_LIST: 'GET_PRODUCTS_LIST',
  GET_PRODUCTS_LIST_SUCCESS: 'GET_PRODUCTS_LIST_SUCCESS',
  GET_PRODUCTS_CATEGORIES_LIST: 'GET_PRODUCTS_CATEGORIES_LIST',
  GET_PRODUCTS_CATEGORIES_LIST_SUCCESS: 'GET_PRODUCTS_CATEGORIES_LIST_SUCCESS',
  UPDATE_PRODUCTS_LIST_SUCCESS: 'UPDATE_PRODUCTS_LIST_SUCCESS',
  UPDATE_PRODUCTS_CATEGORIES_LIST_SUCCESS:
    'UPDATE_PRODUCTS_CATEGORIES_LIST_SUCCESS',
  PRODUCTS_CATEGORIES_SEARCH: 'PRODUCTS_CATEGORIES_SEARCH',
  PRODUCTS_LIST_SEARCH: 'PRODUCTS_LIST_SEARCH',
  SET_WORKFLOW_CHAT_NAME_DRAWER_STATUS: 'SET_WORKFLOW_CHAT_NAME_DRAWER_STATUS',
  SET_WORKFLOW_CHAT_DRAWER_STATUS: 'SET_WORKFLOW_CHAT_DRAWER_STATUS',
  GET_WORKFLOW_CHAT_MESSAGE_DETAILS: 'GET_WORKFLOW_CHAT_MESSAGE_DETAILS',
  GET_WORKFLOW_CHAT_MESSAGE_DETAILS_SUCCESS:
    'GET_WORKFLOW_CHAT_MESSAGE_DETAILS_SUCCESS',
  GET_WORKFLOW_CHAT_MESSAGE_DETAILS_FAILURE:
    'GET_WORKFLOW_CHAT_MESSAGE_DETAILS_FAILURE',
  CREATE_WORKFLOW_CHAT_MESSAGE: 'CREATE_WORKFLOW_CHAT_MESSAGE',
  CREATE_WORKFLOW_CHAT_MESSAGE_SUCCESS: 'CREATE_WORKFLOW_CHAT_MESSAGE_SUCCESS',
  UPDATE_WORKFLOW_CHAT_MESSAGE_DETAILS: 'UPDATE_WORKFLOW_CHAT_MESSAGE_DETAILS',
  CREATE_WORKFLOW_CHAT_MESSAGE_FAILURE: 'CREATE_WORKFLOW_CHAT_MESSAGE_FAILURE',
  SET_WORKFLOW_POST_NAME_DRAWER_STATUS: 'SET_WORKFLOW_POST_NAME_DRAWER_STATUS',
  SET_WORKFLOW_POST_DRAWER_STATUS: 'SET_WORKFLOW_POST_DRAWER_STATUS',
  GET_WORKFLOW_POST_MESSAGE_DETAILS: 'GET_WORKFLOW_POST_MESSAGE_DETAILS',
  GET_WORKFLOW_POST_MESSAGE_DETAILS_SUCCESS:
    'GET_WORKFLOW_POST_MESSAGE_DETAILS_SUCCESS',
  GET_WORKFLOW_POST_MESSAGE_DETAILS_FAILURE:
    'GET_WORKFLOW_POST_MESSAGE_DETAILS_FAILURE',
  UPDATE_WORKFLOW_POST_MESSAGE_DETAILS: 'UPDATE_WORKFLOW_POST_MESSAGE_DETAILS',
  CREATE_WORKFLOW_POST_MESSAGE: 'CREATE_WORKFLOW_POST_MESSAGE',
  CREATE_WORKFLOW_POST_MESSAGE_SUCCESS: 'CREATE_WORKFLOW_POST_MESSAGE_SUCCESS',
  CREATE_WORKFLOW_POST_MESSAGE_FAILURE: 'CREATE_WORKFLOW_POST_MESSAGE_FAILURE',
  GET_WORKFLOW_ACTIONS_HUBSPOT_DETAILS: 'GET_WORKFLOW_ACTIONS_HUBSPOT_DETAILS',
  GET_WORKFLOW_ACTIONS_HUBSPOT_DETAILS_SUCCESS:
    'GET_WORKFLOW_ACTIONS_HUBSPOT_DETAILS_SUCCESS',
  GET_WORKFLOW_ACTIONS_HUBSPOT_DETAILS_FAILURE:
    'GET_WORKFLOW_ACTIONS_HUBSPOT_DETAILS_FAILURE',
  GET_MESSAGES: 'GET_MESSAGES',
  GET_MESSAGES_SUCCESS: 'GET_MESSAGES_SUCCESS',
  GET_MESSAGES_FAILURE: 'GET_MESSAGES_FAILURE',
  GET_SURVEY: 'GET_SURVEY',
  GET_SURVEY_SUCCESS: 'GET_SURVEY_SUCCESS',
  GET_SURVEY_FAILURE: 'GET_SURVEY_FAILURE',
  GET_TOUR: 'GET_TOUR',
  GET_TOUR_SUCCESS: 'GET_TOUR_SUCCESS',
  GET_TOUR_FAILURE: 'GET_TOUR_FAILURE',
  GET_FORM: 'GET_FORM',
  GET_FORM_SUCCESS: 'GET_FORM_SUCCESS',
  GET_FORM_FAILURE: 'GET_FORM_FAILURE',
  GET_WORKFLOW: 'GET_WORKFLOW',
  GET_WORKFLOW_SUCCESS: 'GET_WORKFLOW_SUCCESS',
  GET_WORKFLOW_FAILURE: 'GET_WORKFLOW_FAILURE',
  GET_BOTS: 'GET_BOTS',
  GET_BOTS_SUCCESS: 'GET_BOTS_SUCCESS',
  GET_BOTS_FAILURE: 'GET_BOTS_FAILURE',
  GET_DEAL_STAGES: 'GET_DEAL_STAGES',
  GET_DEAL_STAGES_SUCCESS: 'GET_DEAL_STAGES_SUCCESS',
  UPDATE_TRIGGER_SELECTED_WEBHOOK_DATA: 'UPDATE_TRIGGER_SELECTED_WEBHOOK_DATA',
  UPDATE_ACTION_SELECTED_TRACK_CUSTOM_EVENT_DATA:
    'UPDATE_ACTION_SELECTED_TRACK_CUSTOM_EVENT_DATA',
  UPDATE_WORKFLOW_ZOOM: 'UPDATE_WORKFLOW_ZOOM',
  START_TROUBLESHOOT: 'START_TROUBLESHOOT',
  START_TROUBLESHOOT_SUCCESS: 'START_TROUBLESHOOT_SUCCESS',
  FETCH_PERSON_FLOWS: 'FETCH_PERSON_FLOWS',
  FETCH_PERSON_FLOWS_SUCCESS: 'FETCH_PERSON_FLOWS_SUCCESS',
  FETCH_WORKFLOW_PERSON_ACTIVITIES: 'FETCH_WORKFLOW_PERSON_ACTIVITIES',
  FETCH_WORKFLOW_PERSON_ACTIVITIES_SUCCESS:
    'FETCH_WORKFLOW_PERSON_ACTIVITIES_SUCCESS',
  SKIP_COMPONENT: 'SKIP_COMPONENT',
  SKIP_COMPONENT_SUCCESS: 'SKIP_COMPONENT_SUCCESS',
  STOP_TROUBLESHOOT: 'STOP_TROUBLESHOOT',
  SET_CURRENT_TROUBLESHOOT_ID: 'SET_CURRENT_TROUBLESHOOT_ID',
  UPDATE_PERSON_WORKFLOW_ACTIVITIES: 'UPDATE_PERSON_WORKFLOW_ACTIVITIES',
  RESET_WORKFLOW_EDIT_MODE: 'RESET_WORKFLOW_EDIT_MODE',
  GET_UPDATED_NODES: 'GET_UPDATED_NODES',
  FETCH_WORKFLOW_CONTACT_REPORT: 'FETCH_WORKFLOW_CONTACT_REPORT',
  FETCH_WORKFLOW_CONTACT_REPORT_SUCCESS:
    'FETCH_WORKFLOW_CONTACT_REPORT_SUCCESS',
  FETCH_WORKFLOW_CONTACT_REPORT_FAILURE:
    'FETCH_WORKFLOW_CONTACT_REPORT_FAILURE',
  APPEND_WORKFLOW_CONTACT_REPORT_SUCCESS:
    'APPEND_WORKFLOW_CONTACT_REPORT_SUCCESS',
  FETCH_COMPONENT_CONTACT_REPORT: 'FETCH_COMPONENT_CONTACT_REPORT',
  FETCH_COMPONENT_CONTACT_REPORT_SUCCESS:
    'FETCH_COMPONENT_CONTACT_REPORT_SUCCESS',
  FETCH_COMPONENT_CONTACT_REPORT_FAILURE:
    'FETCH_COMPONENT_CONTACT_REPORT_FAILURE',
  APPEND_COMPONENT_CONTACT_REPORT_SUCCESS:
    'APPEND_COMPONENT_CONTACT_REPORT_SUCCESS',
  SHOW_CONTACT_REPORT_DRAWER: 'SHOW_CONTACT_REPORT_DRAWER',
  SET_TROUBLESHOOT_MODE: 'SET_TROUBLESHOOT_MODE',
  SET_SPLIT_DATA: 'SET_SPLIT_DATA',
};

export default actions;
