import React, {forwardRef} from 'react';
import FroalaEditor from 'react-froala-wysiwyg';

import './froala.scss';
import 'froala-editor/js/froala_editor.pkgd.min.js';
import 'froala-editor/js/plugins.pkgd.min.js';

const TextEditor = forwardRef(({ config, model, onModelChange, id }, ref) => {
  const handleModelChange = (content) => {
    if (content !== model && onModelChange) {
      onModelChange(content);
    }
  };

  return (
    <div id={id || 'froalaEditor'} className="froalaEditor">
      <FroalaEditor
        ref={ref}
        config={config}
        model={model}
        onModelChange={handleModelChange}
      />
    </div>
  );
});

export default TextEditor;
