import { all, takeEvery, put, call } from 'redux-saga/effects';
import actions from './actions';
import messagesActions from '../../messages/actions';
import settings from '../../../settings';
import {
  deletePromise,
  fetchProjectSecretKey,
  getPromise,
  patchPromise,
  postPromise,
  unAuthorisedRedirection,
  updateBrowserHistory,
  trackEvent,
  unProcessableEntity,
} from '../../../helpers/utility';
import { message, notification } from 'antd';
import { store } from '../../../redux/store';
import { BROADCAST_MAIL_TYPE, filterVariables } from '../../constants';
import filterActions from '../../filters/actions';
import reportActions from '../report/actions';

export function* fetchBroadcast(params) {
  try {
    let searchValue = '',
      sortKey = '',
      sortValue = '',
      tagId = '',
      status = '';
    if (params.searchValue) {
      searchValue = params.searchValue;
    } else {
      searchValue = store.getState().BroadcastEmail.searchValue;
    }
    if (params.sortKey && params.sortOrder) {
      sortKey = params.sortKey;
      sortValue = params.sortOrder;
    }
    if (params.tagId) {
      tagId = params.tagId;
    } else {
      tagId = store.getState().BroadcastEmail.broadcastTagId;
    }
    if (params.status) {
      status = params.status;
    } else {
      status = store.getState().BroadcastEmail.broadcastStatus;
    }
    const url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/mail_filters?mail_type=${
      params.mailType
    }&status=${status}&sort_key=${sortKey}&sort_type=${sortValue}&search_text=${encodeURIComponent(
      searchValue,
    )}&tag_ids=${tagId}`;
    const data = yield call(() => getPromise(url));
    yield put({
      type: actions.FETCH_BROADCAST_SUCCESS,
      payload: data,
    });
  } catch (error) {
    unAuthorisedRedirection(error);
    message.error('Fetch emails failed');
  }
}

export function* appendBroadcast(params) {
  try {
    let searchValue = '';
    let sortKey = '';
    let sortValue = '';
    let tagId = '';
    const { payload } = params;
    if (params.searchValue) {
      searchValue = params.searchValue;
    } else {
      searchValue = store.getState().BroadcastEmail.searchValue;
    }
    if (
      store.getState().BroadcastEmail.sortKey &&
      store.getState().BroadcastEmail.sortValue
    ) {
      sortKey = store.getState().BroadcastEmail.sortKey;
      sortValue = store.getState().BroadcastEmail.sortValue;
    }
    let lastBroadcastId = '';
    if (payload && payload.data.lastBroadcastId) {
      lastBroadcastId = payload.data.lastBroadcastId;
    }
    if (params.tagId) {
      tagId = params.tagId;
    } else {
      tagId = store.getState().BroadcastEmail.broadcastTagId;
    }
    const url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/mail_filters?mail_type=${BROADCAST_MAIL_TYPE}&status=${
      store.getState().BroadcastEmail.broadcastStatus
    }&sort_key=${sortKey}&sort_type=${sortValue}&search_text=${encodeURIComponent(
      searchValue,
    )}&tag_ids=${tagId}&last_mail_filter_id=${lastBroadcastId}`;
    const data = yield call(() => getPromise(url));
    yield put({ type: actions.APPEND_BROADCAST_SUCCESS, payload: data });
  } catch (error) {
    unAuthorisedRedirection(error);
    message.error('Fetch emails failed');
  }
}

export function* fetchTemplates(params) {
  try {
    const url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/mail_filters/templates_list?template_type=${
      params.templateType
    }`;
    const data = yield call(() => getPromise(url));
    yield put({
      type: actions.FETCH_TEMPLATES_SUCCESS,
      payload: data.data,
    });
  } catch (error) {
    unAuthorisedRedirection(error);
    message.error('Fetch template failed');
  }
}

export function* fetchVisualBuilderJson(params) {
  try {
    let json = yield call(() =>
      getPromise(
        `${settings.BEE_EMAIL_TEMPLATE}${params.templateId}.json`,
        false,
      ),
    );
    yield put({
      type: actions.FETCH_VISUAL_BUILDER_JSON_SUCCESS,
      payload: json,
    });
    yield put({
      type: actions.UPDATE_VISUAL_BUILDER_JSON,
      payload: json,
    });
  } catch {
    message.error('JSON fetch failed');
  }
}
export function* createBroadCast(params) {
  try {
    const url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/mail_filters/mail_create`;
    const data = yield call(() => postPromise(url, params.object));
    trackEvent('Created oneoff email');
    yield put({
      type: actions.CREATE_BROADCAST_SUCCESS,
      payload: data,
    });
    yield put({
      type: actions.FETCH_BROADCAST_DATA,
      id: data.id,
    });
    yield put({
      type: actions.OPEN_BROADCAST_TEMPLATE_DRAWER,
      payload: false,
    });
    updateBrowserHistory(`email/broadcasts/${data.id}`);
    yield put({
      type: messagesActions.SET_SELECT_CHANNEL_MODAL_STATUS,
      payload: false,
    });
  } catch (error) {
    unAuthorisedRedirection(error);
    yield put({
      type: actions.CREATE_BROADCAST_FAILURE,
    });
    if (error.response && error.response.status === 422) {
      message.error(error.response.data.error.message.join());
    } else {
      message.error('Create one-off email failed.');
    }
  }
}

export function* fetchComposeEmail(params) {
  try {
    const url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/mail_filters/edit/${params.id}`;
    const data = yield call(() => getPromise(url));
    if (params.duplicateRequest) {
      yield put({
        type: actions.SET_DUPLICATE_BROADCAST_DATA,
        payload: data,
      });
    } else {
      yield put({
        type: actions.FETCH_BROADCAST_DATA_SUCCESS,
        payload: data,
      });
    }
    yield put({
      type: filterActions.SET_FILTERS,
      payload: data.data.new_filters,
      filterName: filterVariables.broadcastKey,
    });
    if (data.data.message_goal) {
      yield put({
        type: filterActions.SET_FILTERS,
        payload: data.data.message_goal.goal_filter,
        filterName: filterVariables.broadcastKeyGoal,
      });
    }
  } catch (error) {
    unAuthorisedRedirection(error);
    message.error('Fetch broadcast failed');
  }
}

export function* sendTestEmail(params) {
  try {
    const url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/mail_filters/send_test_mail`;
    const data = yield call(() => postPromise(url, params.object));
    message.success(data.message);
    yield put({ type: actions.SEND_BROADCAST_TEST_EMAIL_SUCCESS });
  } catch (error) {
    unAuthorisedRedirection(error);
    if (error.response && error.response.status === 422) {
      message.error(error.response.data.error.message);
    } else {
      message.error('Something went wrong! Please contact support');
    }
    yield put({ type: actions.SEND_BROADCAST_TEST_EMAIL_FAILURE });
  }
}

export function* createEmailComposer(params) {
  try {
    const url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/mail_filters/mail_composer/${
      params.broadcastId
    }`;
    const data = yield call(() => patchPromise(url, params.object));
    yield put({
      type: actions.SET_BROADCAST_LAST_SAVE_TIME,
      payload: data.data,
    });
    yield put({
      type: actions.SET_BROADCAST_EMAIL_LOADING_INDICATOR,
      payload: false,
    });
    yield put({
      type: actions.CREATE_BROADCAST_COMPOSER_SUCCESS,
      payload: false,
    });
    if (params.setComposer) {
      yield put({
        type: actions.SET_COMPOSER_VALUE_CHANGE,
        payload: false,
      });
    }
  } catch (error) {
    yield put({
      type: actions.CREATE_BROADCAST_COMPOSER_FAILURE,
      payload: false,
    });
    unAuthorisedRedirection(error);
    if (error.response && error.response.status === 422) {
      message.error(error.response.data.error.message.join());
    } else {
      message.error('Save email failed');
    }
    yield put({
      type: actions.SET_BROADCAST_EMAIL_LOADING_INDICATOR,
      payload: false,
    });
  }
}

export function* scheduleBroadcast(params) {
  try {
    const url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/mail_filters/send_mail/${
      params.broadcastId
    }`;
    const data = yield call(() => patchPromise(url, params.object));
    message.success(data.data.message);
    params.history.push(`/projects/${fetchProjectSecretKey()}/messages/email`);
  } catch (error) {
    unAuthorisedRedirection(error);
    if (error.response && error.response.status === 422) {
      notification['error']({
        message: 'Required',
        description: error.response.data.error.message.join(),
      });
    } else {
      message.error('Scheduled email failed');
    }
  }
}

export function* deleteBroadcast(params) {
  try {
    const url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/mail_filters/${params.id}`;
    yield call(() => deletePromise(url));
    if (params.fromPage) {
      store.dispatch({
        type: actions.SET_BROADCAST_TAB_KEY,
        payload: '1',
      });
      params.history.push(
        `/projects/${fetchProjectSecretKey()}/messages/email`,
      );
    } else {
      yield put({
        type: actions.FETCH_BROADCAST,
        mailType: BROADCAST_MAIL_TYPE,
        status: store.getState().BroadcastEmail.broadcastStatus,
      });
      store.dispatch({
        type: actions.DELETE_BROADCAST_SUCCESS,
      });
    }
    message.success('Email deleted successfully');
  } catch (error) {
    unAuthorisedRedirection(error);
    if (error.response && error.response.status === 422) {
      message.error(error.response.data.error.message.join());
    } else {
      message.error('Delete broadcast failed');
    }
  }
}

export function* createSettings(params) {
  try {
    const url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/mail_filters/settings/${
      params.payload.broadcastID
    }`;
    const data = yield call(() => patchPromise(url, params.payload.data));
    yield put({
      type: actions.SET_BROADCAST_LAST_SAVE_TIME,
      payload: data.data,
    });
    yield put({
      type: actions.SET_BROADCAST_EMAIL_LOADING_INDICATOR,
      payload: false,
    });
    yield put({
      type: actions.CREATE_BROADCAST_COMPOSER_SUCCESS,
      payload: false,
    });
  } catch (error) {
    unAuthorisedRedirection(error);
    yield put({
      type: actions.SET_BROADCAST_EMAIL_LOADING_INDICATOR,
      payload: false,
    });
    yield put({
      type: actions.CREATE_BROADCAST_COMPOSER_FAILURE,
      payload: false,
    });
    if (error.response && error.response.status === 422) {
      message.error(error.response.data.error.message.join());
    } else {
      message.error('Settings update failed');
    }
  }
}

export function* createDuplicateBroadcast(params) {
  try {
    const url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/mail_filters/duplicate`;
    const data = yield call(() => postPromise(url, params.payload.object));
    yield put({
      type: actions.OPEN_BROADCAST_TEMPLATE_DRAWER,
      payload: false,
    });
    yield put({
      type: actions.SET_BROADCAST_ID,
      id: data.data.id,
    });
    yield put({
      type: actions.FETCH_BROADCAST_DATA,
      id: data.data.id,
    });
    store.dispatch({
      type: actions.SET_BROADCAST_TAB_KEY,
      payload: '1',
    });
    params.payload.history.push(
      `/projects/${fetchProjectSecretKey()}/email/broadcasts/${data.data.id}`,
    );
  } catch (error) {
    unAuthorisedRedirection(error);
    if (error.response && error.response.status === 422) {
      message.error(error.response.data.error.message.join());
    } else {
      message.error('Create email broadcast failed');
    }
  }
}

export function* createRecipients(params) {
  let recipientsData = {
    mail_filter: store.getState().Filter.broadcastAudienceFilters,
  };
  if (params.sunsetPolicyEnabled) {
    recipientsData.mail_filter = {
      sunset_policy_enabled: store.getState().BroadcastEmail.sunsetPolicyStatus,
    };
  }
  try {
    const url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/mail_filters/recipient_create/${
      params.broadcastID
    }`;
    const data = yield call(() => patchPromise(url, recipientsData));
    yield put({
      type: actions.SET_BROADCAST_LAST_SAVE_TIME,
      payload: data.data,
    });
    yield put({
      type: actions.BROADCAST_RECIPIENTS_CREATE_SUCCESS,
      payload: data.data,
    });
    yield put({
      type: actions.SET_BROADCAST_EMAIL_LOADING_INDICATOR,
      payload: false,
    });
  } catch (error) {
    yield put({
      type: actions.BROADCAST_RECIPIENTS_CREATE_FAILURE,
      payload: true,
    });
    yield put({
      type: actions.SET_BROADCAST_EMAIL_LOADING_INDICATOR,
      payload: false,
    });
    unAuthorisedRedirection(error);
    message.error('Create recipient failed');
  }
}

export function* getAudienceCount(params) {
  yield put({ type: actions.BROADCAST_SET_RECIPIENTS_LOADER, payload: true });
  try {
    const url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/get_filtered_people_count?mail_filter_id=${
      params.broadcastId
    }&validate_email_sending=true&include_email_filters=true`;
    let data = yield call(() => getPromise(url));
    yield put({
      type: actions.BROADCAST_FETCH_RECIPIENTS_SUCCESS,
      payload: data,
    });
    yield put({
      type: actions.BROADCAST_SET_RECIPIENTS_LOADER,
      payload: false,
    });
  } catch (error) {
    unAuthorisedRedirection(error);
    unProcessableEntity(error, 'Fetch recipients count failed');
    yield put({
      type: actions.BROADCAST_FETCH_RECIPIENTS_FAILURE,
      payload:
        error.response.data &&
        error.response.data.data &&
        Object.keys(error.response.data.data).length > 0,
      count: error.response.data,
    });
    yield put({
      type: actions.BROADCAST_SET_RECIPIENTS_LOADER,
      payload: false,
    });
  }
}
export function* fetchBeeToken(params) {
  try {
    const url = `${
      settings.ROOT_URL
    }bee_free/token?project_secret_key=${fetchProjectSecretKey()}`;
    let data = yield call(() => getPromise(url));
    yield put({
      type: actions.FETCH_BEE_TOKEN_SUCCESS,
      payload: data,
    });
  } catch (error) {
    message.error('Fetch bee token failed');
  }
}

export function* stopBroadcast(params) {
  try {
    const url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/mail_filters/send_mail/${params.id}`;
    const data = yield call(() => patchPromise(url, params.payload));
    message.success(data.data.message);
    yield put({
      type: actions.STOP_BROADCAST_SUCCESS,
      payload: { id: params.id, status: params.payload.status },
    });
    if (params.isFromReportPage) {
      yield put({
        type: reportActions.UPDATE_EMAIL_STATUS,
        payload: params.payload.status,
      });
    }
  } catch (error) {
    unAuthorisedRedirection(error);
    if (error.response && error.response.status === 422) {
      message.error(error.response.data.error.message.join());
    } else {
      message.error('Stop broadcast failed');
    }
    yield put({
      type: actions.STOP_BROADCAST_FAILURE,
    });
  }
}
export function* fetchBatchDetails(params) {
  try {
    const { broadcastID } = store.getState().BroadcastEmail;
    const url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/mail_filters/${broadcastID}/optimal_batch_configuration${
      params.recipientsCount >= 0
        ? `?recipient_count=${params.recipientsCount}`
        : ''
    }`;
    const data = yield call(() => getPromise(url));
    yield put({
      type: actions.CHANGE_BATCH_SENDING_STATUS,
      batchSendingData: data?.data?.batch_config ?? null,
    });
  } catch (error) {
    unAuthorisedRedirection(error);
    if (error.response && error.response.status === 422) {
      message.error(error.response.data.error.message.join());
    } else {
      message.error('Enable batch sending failed');
    }
    yield put({
      type: actions.CHANGE_BATCH_SENDING_STATUS,
      batchSendingData: null,
    });
  }
}

export function* createBroadCastSchedule(params) {
  try {
    const url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/mail_filters/update_schedule_details/${
      params.broadcastID
    }`;
    const data = yield call(() => patchPromise(url, params.payload));
    yield put({
      type: actions.SET_BROADCAST_LAST_SAVE_TIME,
      payload: data.data,
    });
  } catch (error) {
    unAuthorisedRedirection(error);
    message.error('Create Schedule failed');
  }
}

export default function* rootSaga() {
  yield all([takeEvery(actions.FETCH_BROADCAST, fetchBroadcast)]);
  yield all([takeEvery(actions.FETCH_TEMPLATES, fetchTemplates)]);
  yield all([takeEvery(actions.APPEND_BROADCAST, appendBroadcast)]);
  yield all([takeEvery(actions.CREATE_BROADCAST, createBroadCast)]);
  yield all([takeEvery(actions.FETCH_BROADCAST_DATA, fetchComposeEmail)]);
  yield all([takeEvery(actions.SEND_BROADCAST_TEST_EMAIL, sendTestEmail)]);
  yield all([
    takeEvery(actions.CREATE_BROADCAST_COMPOSER, createEmailComposer),
  ]);
  yield all([takeEvery(actions.SCHEDULE_BROADCAST, scheduleBroadcast)]);
  yield all([takeEvery(actions.DELETE_BROADCAST, deleteBroadcast)]);
  yield all([takeEvery(actions.CREATE_BROADCAST_SETTINGS, createSettings)]);
  yield all([
    takeEvery(actions.CREATE_DUPLICATE_BROADCAST, createDuplicateBroadcast),
  ]);
  yield all([takeEvery(actions.CREATE_RECIPIENTS, createRecipients)]);
  yield all([
    takeEvery(actions.BROADCAST_GET_AUDIENCE_COUNT, getAudienceCount),
  ]);
  yield all([
    takeEvery(actions.FETCH_VISUAL_BUILDER_JSON, fetchVisualBuilderJson),
  ]);
  yield all([takeEvery(actions.FETCH_BEE_TOKEN, fetchBeeToken)]);
  yield all([takeEvery(actions.STOP_BROADCAST, stopBroadcast)]);
  yield all([
    takeEvery(actions.CREATE_BROADCAST_SCHEDULE, createBroadCastSchedule),
  ]);
  yield all([takeEvery(actions.FETCH_BATCH_DETAILS, fetchBatchDetails)]);
}
