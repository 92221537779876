import React, { useEffect, useRef, useState } from 'react';
import { Button, Icon, message, Popover, Tabs, Tooltip } from 'antd';
import {
  agentTypingStatusObject,
  convertHtmlToText,
  extractCurrentPersonId,
  extractCurrentUserId,
  extractUserProfileData,
  fetchProjectSecretKey,
  generateRandomId,
  getlastMessage,
  scrollToBottom,
  setImageLink,
  fetchRestrictedFeatures,
  getPromise,
  handleImageAttachment,
  handleUploadAttachment,
  isPrimeCouponWorkspace,
  extractConversationIdentifier, fetchHiddenFeatures,
} from '../../../helpers/utility';
import {
  pluginsEnabled,
  imageEditButtons,
  toolbarButtons,
  shortcutsEnabled,
} from './constants';
import actions from '../../../redux/chat/actions';
import { debounce, trim } from 'lodash';
import socket from '../../../helpers/socket';
import { typingSocketEmitInterval } from '../Conversations/constants';
import {
  shortcutKeyCodes,
  socketChannels,
} from '../../../containers/App/constants';
import { useParams } from 'react-router-dom';
import TextEditor from '../../Shared/TextEditor';
import settings from '../../../settings';
import FileUpload from './FileUpload';
import GifUpload from './GifUpload';
import EmojisHolder from './EmojisHolder';
import { keyCode } from '../../constants';
import dompurify from 'dompurify';
import Tribute from 'tributejs';
import Froalaeditor from 'froala-editor';
import Snooze from '../../Shared/Snoooze';
import { generalTimeInterval } from '../../Email/constants';
import CallHolder from './CallHolder';
import { menu } from '../../TopNavBar/constants';
import sharedActions from '../../../redux/sharedReducers/actions';
import TypingBubble from '../Conversations/TypingBubble';
import { useDispatch, useSelector } from 'react-redux';
import { usePrevious } from '../../../helpers/hooks';
import { aiAssistToolbarButtons } from '../../Settings/constant';

const TabPane = Tabs.TabPane;

export function socialMediaRestriction(
  currentConversationMessages,
  tabActiveKey = 'reply',
) {
  if (currentConversationMessages)
    return (
      tabActiveKey === 'reply' &&
      !['facebook', 'twitter'].includes(
        String(
          currentConversationMessages.message_initiated_from,
        ).toLowerCase(),
      )
    );
}

function isEmailRoom(currentConversationMessages) {
  return !['email', 'emailreply'].includes(
    String(currentConversationMessages.message_initiated_from).toLowerCase(),
  );
}
function convertListItemsToParagraph(clipboard_html) {
  let parser = new DOMParser();
  let parsedDocument = parser.parseFromString(clipboard_html, 'text/html');
  const listTag = parsedDocument.getElementsByTagName('li');
  [...listTag].forEach((item) => {
    let d = document.createElement('p');
    d.innerHTML = item.innerHTML;
    item.parentNode.replaceChild(d, item);
  });
  return parsedDocument.body.innerHTML;
}

let snippetTribute = null,
  mentionTribute = null;

const MessageComposer = (props) => {
  const dispatch = useDispatch();
  const {
    currentConversation,
    currentConversationMessages,
    enqueueAttachment,
    activateComposerTab,
  } = props;

  const isPreviousConversation = useSelector(
    ({ Chat }) => Chat.isFromPreviousConversation,
  );
  const selectedPreviousConversation = useSelector(
    ({ Chat }) => Chat.selectedPreviousConversation,
  );
  const agentTypedReplyMessages = useSelector(
      ({ Chat }) => Chat.agentTypedReplyMessages,
    ),
    agentTypedNoteMessages = useSelector(
      ({ Chat }) => Chat.agentTypedNoteMessages,
    ),
    gifResource = useSelector(({ Chat }) => Chat.gifResource),
    activeComposerTab = useSelector(({ Chat }) => Chat.activeComposerTab),
    tabActiveKey = useSelector(
      ({ Chat }) =>
        (currentConversation &&
          Chat.activeComposerTabList[
            currentConversation.conversation_identifier
          ]) ||
        (!isPreviousConversation && activeComposerTab) ||
        'reply',
    ),
    teamMates = useSelector(({ Chat }) => Chat.teamMates),
    isOffline = useSelector(({ Chat }) => Chat.isOffline),
    isEmptyGif = useSelector(({ Chat }) => Chat.isEmptyGif),
    gifOffset = useSelector(({ Chat }) => Chat.gifOffset),
    replyAttachmentQueues = useSelector(
      ({ Chat }) => Chat.replyAttachmentQueues,
    ),
    noteAttachmentQueues = useSelector(({ Chat }) => Chat.noteAttachmentQueues),
    replySending = useSelector(({ Chat }) => Chat.replySending),
    noteSending = useSelector(({ Chat }) => Chat.noteSending),
    isReplyEditorFocus = useSelector(({ Chat }) => Chat.isReplyEditorFocus),
    isNoteEditorFocus = useSelector(({ Chat }) => Chat.isNoteEditorFocus),
    editMessages = useSelector(({ Chat }) => Chat.editMessages),
    deleteMessage = useSelector(({ Chat }) => Chat.deleteMessage),
    isAiBotAssist = useSelector(({ Chat }) => Chat.isAiBotAssist),
    aiAssistReplyLoader = useSelector(({ Chat }) => Chat.aiAssistReplyLoader),
    aiAssistNoteLoader = useSelector(({ Chat }) => Chat.aiAssistNoteLoader),
    aiAssistReplyResponse = useSelector(
      ({ Chat }) => Chat.aiAssistReplyResponse,
    ),
    aiAssistNoteResponse = useSelector(({ Chat }) => Chat.aiAssistNoteResponse),
    allowOutgoingAttachments = useSelector(
      ({ Auth }) => Auth.allowOutgoingAttachments,
    ),
    currentReplyEditor = useSelector(
      ({ SharedReducers }) => SharedReducers.replyEditor,
    ),
    currentNoteEditor = useSelector(
      ({ SharedReducers }) => SharedReducers.noteEditor,
    );
  const tabActiveKeyRef = useRef(tabActiveKey);
  const route = useParams();
  const conversationId = useSelector(({Chat}) => Chat.currentConversationId) || route?.conversation_identifier?.split('?')?.[0];
  const currentConversationId = isPreviousConversation ? selectedPreviousConversation?.conversation_identifier : conversationId;
  const currentConversationIdRef = useRef();

  const previousReplyAttachmentQueues = usePrevious(replyAttachmentQueues);
  const previousNoteAttachmentQueues = usePrevious(noteAttachmentQueues);

  const [messageState, setMessageState] = useState('reply');
  const [showPicker, setShowPicker] = useState(false);
  const [replyMsg, setReplyMsg] = useState(
    agentTypedReplyMessages[currentConversationId],
  );
  const [noteMsg, setNoteMsg] = useState(
    agentTypedNoteMessages[currentConversationId],
  );
  const [showGif, setShowGif] = useState(false);
  const [replyLoader, setReplyLoader] = useState(false);
  const [noteLoader, setNoteLoader] = useState(false);
  const [messageStatus, setMessageStatus] = useState('');
  const [displaySnoozeMenu, setDisplaySnoozeMenu] = useState(false);
  const [isEditorFocused, setIsEditorFocused] = useState(false);

  const isEditorEditModeOn = useRef(false);
  const editableMessageRef = useRef();
  const snoozeRefTwo = useRef();

  let agentTyping = false,
    replyEditorUndo = false,
    noteEditorUndo = false,
    timeOut = null;
  const replyEditor = useRef(null);
  const noteEditor = useRef(null);
  const postReply = (
    replyMessageData,
    attachmentQueue = [],
    messageStatus = '',
  ) => {
    dispatch({
      type: actions.POST_REPLY,
      cid: currentConversationId,
      replyMessageData,
      attachmentQueue,
      messageStatus,
    });
  };

  useEffect(() => {
    currentConversationIdRef.current = currentConversationId;
  }, [currentConversationId]);

  const postNote = (noteData, attachmentQueue = []) => {
    dispatch({
      type: actions.POST_NOTE,
      cid: currentConversationId,
      noteData,
      attachmentQueue,
    });
  };

  const sendEditMessage = (messageData) => {
    dispatch({
      type: actions.EDIT_MESSAGE,
      messageData,
    });
  };

  const parseSnippetContent = (id, parseSnippetLiquidCB) => {
    const cid = currentConversationId;
    let URL = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/snippets/${id}/parse_message_content?person_id=${extractCurrentPersonId()}&conversation_identifier=${cid}`;
    dispatch({
      type: actions.UPDATE_SNIPPET_DETAILS,
      payload: id,
    });
    getPromise(URL)
      .then(({ data }) => {
        parseSnippetLiquidCB(
          data.parsed_message_content.replace(/^(<br>|<br>+)*/, ''),
        );
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const convertSnippetsInTributeFormat = (snippets) => {
    const regex = /(<([^>]+)>)/gi;
    return snippets.map((prop) => ({
      key: prop.title,
      value: prop.message,
      // remove all html tags and replace Img tag with (Image) text
      preview: prop.message
        .replace(/<img .*?>/g, ' (Image) ')
        .replace(regex, ''),
      id: prop.id,
    }));
  };

  const snippetRemoteSearch = async (searchText, snippetRemoteSearchCB) => {
    const URL = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/snippets?search_in_title=true&search_text=${searchText.trim()}`;
    try {
      let data = await getPromise(URL);

      let snippetsInTributeFormat = convertSnippetsInTributeFormat(
        data.data.saved_replies,
      );
      snippetRemoteSearchCB(snippetsInTributeFormat);
    } catch (err) {
      console.error(err);
      snippetRemoteSearchCB([]);
    }
  };

  const onSetSnippetTribute = () => {
    snippetTribute = new Tribute({
      trigger: '/',
      allowSpaces: true,
      values: debounce(function (text, snippetRemoteSearchCB) {
        snippetRemoteSearch(text, (snippets) =>
          snippetRemoteSearchCB(snippets),
        );
      }, 500),
      selectTemplate: function (item) {
        parseSnippetContent(item?.original?.id, function (data) {
          pushContentIntoComposer(`${data}`);
        });
        return '';
      },
      menuItemTemplate: (item) =>
        `<strong>${item.original.key}</strong> ${item.original.preview}`,
      replaceTextSuffix: '',
    });
  };

  const handleAiAssist = (e, actionType) => {
    const selectedContent = e.selection.text();
    if (selectedContent.replace(/&nbsp;/g, ' ').trim() === '') {
      message.error(`Please select text to make ${actionType}`);
    } else {
      const element = document.getElementsByClassName(actionType);
      let activeElement;
      if (element) {
        activeElement =
          tabActiveKeyRef.current === 'reply' ? element[0] : element[1];
        activeElement.classList.add('ai-assist-loader');
      }
      dispatch({
        type:
          tabActiveKeyRef.current === 'reply'
            ? actions.AI_ASSIST_REPLY_LOADER
            : actions.AI_ASSIST_NOTE_LOADER,
        payload: true,
        conversationId: currentConversationIdRef.current,
      });
      e.selection.save();
      dispatch({
        type: actions.FETCH_AI_ASSIST,
        actionType,
        message: selectedContent,
        activeElement,
        tabActiveKey: tabActiveKeyRef.current,
        conversationId: currentConversationIdRef.current,
      });
    }
  };

  const clickOutSideComposer = () => {
    const composerTab = document.getElementsByClassName('ant-tabs-bar')[0];
    if (composerTab) {
      composerTab.focus();
    }
  };

  const setEditorEditable = () => {
    let currentEditableMessage = editMessages[currentConversationId];

    if (
      currentEditableMessage &&
      currentEditableMessage.message_type === 'note'
    ) {
      if (
        !isEditorEditModeOn.current ||
        editableMessageRef.current.id !== currentEditableMessage.id
      ) {
        setNoteMsg(currentEditableMessage.message_content);
        editableMessageRef.current = currentEditableMessage;
        isEditorEditModeOn.current = true;
        dispatch({
          type: actions.SET_NOTE_COMPOSERS_VALUE,
          payload: currentEditableMessage.message_content,
          conversationId: currentConversationId,
        });
        noteEditor.current &&
          noteEditor.current.events &&
          noteEditor.current.events.focus(true);
      }
    } else if (currentEditableMessage) {
      if (
        !isEditorEditModeOn.current ||
        editableMessageRef.current.id !== currentEditableMessage.id
      ) {
        setReplyMsg(currentEditableMessage.message_content);
        editableMessageRef.current = currentEditableMessage;
        isEditorEditModeOn.current = true;
        dispatch({
          type: actions.SET_REPLY_COMPOSERS_VALUE,
          payload: currentEditableMessage.message_content,
          conversationId: currentConversationId,
        });
        replyEditor.current &&
          replyEditor.current.events &&
          replyEditor.current.events.focus(true);
      }
    } else {
      isEditorEditModeOn.current = false;
    }
  };

  const timeoutFunction = () => {
    agentTyping = false;
    socket.emit(socketChannels.agentTypingStopped, agentTypingStatusObject());
  };

  const messageStatusChange = () => {
    let lastMessage = getlastMessage(currentConversationMessages.messages);
    if (
      lastMessage.index >= 0 &&
      lastMessage.message.status !== 'responded' &&
      lastMessage.message.status === 'seen'
    ) {
      lastMessage.statusData.status = 'responded';
      dispatch({
        type: actions.UPDATE_MESSAGE_STATUS,
        payload: lastMessage.statusData,
      });
    }
  };

  const sendReply = (messageStatus) => {
    setMessageStatus(messageStatus);
    setDisplaySnoozeMenu(false);
    let replyMsg = replyEditor.current.html.get(true);
    replyMsg = replyMsg.replace(/<\/?span class="fr-marker"[^>]*>/g, '');
    replyMsg = replyMsg.replace(/\u200B/g, ''); // Fixed the \u200b - a zero width spaces
    replyMsg = setImageLink(replyMsg);
    const cid = currentConversationId,
      currentReplyAttachments = replyAttachmentQueues[cid];

    if (replySending[cid] || isOffline) return;

    if (trim(convertHtmlToText(replyMsg)) !== '') {
      // Sending reply message
      let message = {};
      (replyMsg.match(/<br>/g) || []).forEach(() => {
        replyMsg = replyMsg.replace(/^<br>+|<br>+$/gm, '');
      });
      if (!socialMediaRestriction(currentConversationMessages, 'reply')) {
        replyMsg = convertHtmlToText(replyMsg.replace(/&nbsp;/g, ' '));
      }
      if (isEditorEditModeOn.current === true) {
        message = editableMessageRef.current;
        message.message_content = replyMsg;
        message.message_display_content = replyMsg;
        message.projectSecretKey = fetchProjectSecretKey();
        sendEditMessage(message);
        dispatch({
          type: actions.SET_EMPTY_EDIT_MESSAGE,
        });
      } else {
        message = {
          message_content: replyMsg,
          message_temp_id: generateRandomId(cid),
        };
        const messageUpdatePromise = new Promise(function (resolve, reject) {
            resolve(
              postReply({ ...message }, currentReplyAttachments, messageStatus),
            );
          }),
          chatBody = currentChatBody();
        messageUpdatePromise.then(function () {
          scrollToBottom(document.getElementById(chatBody));
        });
      }
      // Empty the current composer value
      dispatch({
        type: actions.SET_CURRENT_COMPOSERS_VALUE,
        payload: { reply: '', note: '' },
      });

      // Update the message
      message.message_type = null;
      message.message_display_content = replyMsg;
      updateMessage(message);
    } else if (!!currentReplyAttachments && !!currentReplyAttachments.length) {
      dispatch({
        type: actions.REPLY_LOADER,
        payload: { status: true, cid },
      });
      // Sending reply attachments or images
      dispatch({
        type: actions.DEQUEUE_REPLY_ATTACHMENT,
        payload: { cid },
      });
      dispatch({
        type: actions.POST_ATTACHMENTS,
        payload: { attachmentData: currentReplyAttachments[0], cid },
        messageStatus,
      });
    }
    onReplyValueChange('');
    replyEditor.current.selection.clear();
    replyEditor.current.events.focus(true);
  };

  const updateMessage = (msg) => {
    msg.conversation_identifier = currentConversationId;
    msg.user = extractUserProfileData();
    msg.user_id = extractCurrentUserId();
    const payload = { data: msg };
    // updateConversationBehaviour();
    dispatch({
      type: actions.UPDATE_MESSAGE,
      payload,
      currentUserId: extractCurrentUserId(),
    });
    scrollToBottom(document.getElementById(currentChatBody()));

    dispatch({
      type: actions.UPDATE_ALL_CONVERSATION_DATA,
      payload,
    });
  };

  const cancelNoteEdit = () => {
    dispatch({
      type: actions.SET_EMPTY_EDIT_MESSAGE,
    });
    onNoteValueChange('');
    noteEditor.current.selection.clear();
    noteEditor.current.events.focus(true);
  };

  const cancelReplyEdit = () => {
    dispatch({
      type: actions.SET_EMPTY_EDIT_MESSAGE,
    });
    onReplyValueChange('');
    replyEditor.current.selection.clear();
    replyEditor.current.events.focus(true);
  };

  const sendNotes = () => {
    const cid = currentConversationId,
      currentNoteAttachments = noteAttachmentQueues[cid];
    let userIds = [],
      noteMsg = noteEditor.current.html.get(true);
    noteMsg = noteMsg.replace(/<\/?span class="fr-marker"[^>]*>/g, '');
    noteMsg = noteMsg.replace(/\u200B/g, ''); // Fixed the \u200b - a zero width spaces
    noteMsg = setImageLink(noteMsg);
    if (noteSending[cid] || isOffline) return;

    if (trim(convertHtmlToText(noteMsg)) !== '') {
      // Sending note message
      (noteMsg.match(/<br>/g) || []).forEach(() => {
        noteMsg = noteMsg.replace(/^<br>+|<br>+$/gm, '');
      });

      for (let teamMate of teamMates) {
        if (noteMsg.match(teamMate.name)) {
          if (!userIds.some((id) => id === teamMate.id)) {
            userIds.push(teamMate.id);
          }
        }
      }

      let message = {};

      if (isEditorEditModeOn.current === true) {
        // edit the note message
        message = editableMessageRef.current;
        message.message_content = noteMsg;
        message.projectSecretKey = fetchProjectSecretKey();
        message.user_ids = userIds;
        sendEditMessage(message);
        dispatch({
          type: actions.SET_EMPTY_EDIT_MESSAGE,
        });
      } else {
        // create new message
        message = {
          message_content: noteMsg,
          message_temp_id: generateRandomId(cid),
          user_ids: userIds,
        };
        postNote({ ...message }, currentNoteAttachments);
        scrollToBottom(document.getElementById(currentChatBody()));
      }

      // Update the message
      message.message_type = 'note';
      message.message_display_content = noteMsg;
      updateMessage(message);

      // Empty the current composer value
      dispatch({
        type: actions.SET_CURRENT_COMPOSERS_VALUE,
        payload: { reply: '', note: '' },
      });
    } else if (!!currentNoteAttachments && !!currentNoteAttachments.length) {
      dispatch({
        type: actions.NOTE_LOADER,
        payload: { status: true, cid },
      });
      dispatch({
        type: actions.DEQUEUE_NOTE_ATTACHMENT,
        payload: { cid },
      });
      // Sending note attachments or images
      dispatch({
        type: actions.POST_ATTACHMENTS,
        payload: { attachmentData: currentNoteAttachments[0], cid },
      });
    }
    onNoteValueChange('');
    noteEditor.current.selection.clear();
    noteEditor.current.events.focus(true);
  };

  const onSelectEmojis = (emoji) => {
    if (messageState === 'reply') {
      replyEditor.current.selection.restore();
      replyEditor.current.html.insert(emoji.native);
    } else if (messageState === 'note') {
      noteEditor.current.selection.restore();
      noteEditor.current.html.insert(emoji.native);
    }
  };

  const handleEmojiPickerVisibleChange = (showPicker) => {
    setShowPicker(showPicker);
  };

  const handleGifVisibleChange = (showGif) => {
    setShowGif(showGif);
  };

  const hideGif = () => {
    setShowGif(false);
  };

  const handleOmnisearchVisibleChange = (command) => {
    dispatch({
      type: sharedActions.UPDATE_OMNISEARCH_STATE,
      payload: command,
    });

    dispatch({
      type: sharedActions.SHOW_HIDE_OMNISEARCH,
      payload: true,
    });
  };

  const onComposerTabChange = (activeKeys) => {
    let type = 'reply';
    activateComposerTab(activeKeys, currentConversationId);
    if (activeKeys === 'reply') {
      dispatch({
        type: sharedActions.SET_REPLY_EDITOR,
        payload: replyEditor.current,
        cid: currentConversationId,
      });
    } else if (activeKeys === 'note') {
      dispatch({
        type: sharedActions.SET_NOTE_EDITOR,
        payload: noteEditor.current,
        cid: currentConversationId,
      });
    }
    setTimeout(() => {
      if (messageState === 'reply') {
        type = 'note';
      }
      setMessageState(type);
      focusEditor(activeKeys);
    }, 300);
  };

  const focusEditor = (updatedState) => {
    const messageStates = updatedState || messageState;
    if (messageStates === 'note') {
      if (noteEditor.current) {
        noteEditor.current.events.focus(true);
      }
    } else {
      if (replyEditor.current) {
        replyEditor.current.events.focus(true);
      }
    }
  };

  const pushContentIntoComposer = (value, articleLink = false) => {
    const tabKeyBasedEditor =
      tabActiveKeyRef.current === 'reply'
        ? replyEditor.current
        : noteEditor.current;
    tabKeyBasedEditor.edit.on();
    tabKeyBasedEditor.selection.restore();
    if (
      (tabActiveKeyRef.current === 'reply' && replyEditorUndo) ||
      (tabActiveKeyRef.current === 'note' && noteEditorUndo)
    ) {
      tabKeyBasedEditor.commands.undo();
    }
    // if (articleLink) {
    //   value = `<a href="${value}" target="_blank" rel="noreferrer noopener nofollow">${value}<a/>`;
    // }
    value = value.replace(/\r?\n/g, '<br>');
    tabKeyBasedEditor.events.focus();
    tabKeyBasedEditor.html.insert(`${value} `);
    tabKeyBasedEditor.undo.saveStep();
    tabKeyBasedEditor.selection.save();
    tabKeyBasedEditor.selection.restore();
    if (tabActiveKeyRef.current === 'reply') {
      onReplyValueChange(tabKeyBasedEditor.html.get(true));
    } else {
      onNoteValueChange(tabKeyBasedEditor.html.get(true));
    }
    setTimeout(() => focusEditor(tabActiveKeyRef.current), 50);
  };

  const onReplyValueChange = (value) => {
    setReplyMsg(value);
    dispatch({
      type: actions.SET_REPLY_COMPOSERS_VALUE,
      payload: value,
      conversationId: currentConversationIdRef.current,
    });
    messageStatusChange();
  };

  const typingStatusHandler = () => {
    // handle typing status
    if (!agentTyping) {
      agentTyping = true;
      socket.emit(socketChannels.agentTypingStarted, agentTypingStatusObject());
    }
    clearTimeout(timeOut);
    timeOut = setTimeout(timeoutFunction.bind(this), typingSocketEmitInterval);
  };

  const onNoteValueChange = (value) => {
    setNoteMsg(value);
    dispatch({
      type: actions.SET_NOTE_COMPOSERS_VALUE,
      payload: value,
      conversationId: currentConversationIdRef.current,
    });
    messageStatusChange();
  };

  const removeReplyAttachment = (cid, replyIndex) => {
    dispatch({
      type: actions.REMOVE_REPLY_ATTACHMENT,
      payload: { cid, replyIndex },
    });
  };

  const removeNoteAttachment = (cid, noteIndex) => {
    dispatch({
      type: actions.REMOVE_NOTE_ATTACHMENT,
      payload: { cid, noteIndex },
    });
  };

  const postGifReply = (value) => {
    postReply(value);
    setTimeout(focusEditor, 50);
  };

  const postGifNote = (value) => {
    postNote(value);
    setTimeout(focusEditor, 50);
  };

  const handleSnoozeMenu = (status) => {
    setDisplaySnoozeMenu(status);
  };

  const currentChatBody = () => {
    if (isPreviousConversation) {
      return 'previous-conversation-body';
    } else {
      return 'messageBody';
    }
  };

  const showOmniSearch = () => {
    dispatch({
      type: sharedActions.SHOW_HIDE_OMNISEARCH,
      payload: true,
    });
  };

  const handleOmniSearch = (command) => {
    dispatch({
      type: sharedActions.UPDATE_OMNISEARCH_STATE,
      payload: command,
    });

    dispatch({
      type: sharedActions.SHOW_HIDE_OMNISEARCH,
      payload: true,
    });
  };

  useEffect(() => {
    const composerTab = document.getElementsByClassName('ant-tabs-bar')[0];
    if (composerTab) {
      document.addEventListener('click', () => composerTab.blur());
    }

    setEditorEditable();

    mentionTribute = new Tribute({
      values: teamMates,
      selectTemplate: (item) => `@${dompurify.sanitize(item.original.name)}`,
      menuItemTemplate: (item) =>
        `<img src=${item.original.avatar_url} alt="user">` +
        `<span>${dompurify.sanitize(item.original.name)}</span>`,
      lookup: 'name',
      replaceTextSuffix: '',
    });
    onSetSnippetTribute();
    if (!fetchHiddenFeatures().includes(menu.aiAssist) && !fetchRestrictedFeatures().includes(menu.aiAssist)) {
      Froalaeditor.DefineIconTemplate(
        'painBrush',
        '<i class="far fa-paint-brush expand" style="margin: 0"></i>',
      );
      Froalaeditor.DefineIcon('expand', {
        NAME: 'paintBrush',
        template: 'painBrush',
      });
      Froalaeditor.RegisterCommand('expand', {
        title: 'Expand',
        focus: true,
        undo: true,
        refreshAfterCallback: true,
        callback: function () {
          handleAiAssist(this, 'expand');
        },
      });
      Froalaeditor.DefineIconTemplate(
        'pencil',
        '<i class="far fa-pencil rephrase" style="margin: 0"></i>',
      );
      Froalaeditor.DefineIcon('rephrase', {
        NAME: 'pencil',
        template: 'pencil',
      });
      Froalaeditor.RegisterCommand('rephrase', {
        title: 'Rephrase',
        focus: true,
        undo: true,
        refreshAfterCallback: true,
        callback: function () {
          handleAiAssist(this, 'rephrase');
        },
      });
      Froalaeditor.DefineIconTemplate(
        'smile',
        '<i class="far fa-smile-wink friendly"></i>',
      );
      Froalaeditor.DefineIcon('friendly', {
        NAME: 'smile',
        template: 'smile',
      });
      Froalaeditor.RegisterCommand('friendly', {
        title: 'Friendly',
        focus: true,
        undo: true,
        refreshAfterCallback: true,
        callback: function () {
          handleAiAssist(this, 'friendly');
        },
      });
      Froalaeditor.DefineIconTemplate(
        'speechless',
        '<i class="far fa-meh professional"></i>',
      );
      Froalaeditor.DefineIcon('professional', {
        NAME: 'speechless',
        template: 'speechless',
      });
      Froalaeditor.RegisterCommand('professional', {
        title: 'Professional',
        focus: true,
        undo: true,
        refreshAfterCallback: true,
        callback: function () {
          handleAiAssist(this, 'professional');
        },
      });
    }
  }, [isPreviousConversation]);

  useEffect(() => {
    let cid = currentConversationId;
    setEditorEditable();

    // TODO Jitta - Need to review this code
    if (
      (previousReplyAttachmentQueues &&
        previousReplyAttachmentQueues[cid] !== replyAttachmentQueues[cid]) ||
      (previousNoteAttachmentQueues &&
        previousNoteAttachmentQueues[cid] !== noteAttachmentQueues[cid])
    ) {
      focusEditor();
    }
  }, [replyAttachmentQueues, noteAttachmentQueues, editMessages]);

  useEffect(() => {
    if (tabActiveKey === 'reply') {
      if (currentReplyEditor && currentReplyEditor[currentConversationId]) {
        currentReplyEditor[currentConversationId].html.set(
          agentTypedReplyMessages &&
            agentTypedReplyMessages[currentConversationId]
            ? agentTypedReplyMessages[currentConversationId]
            : '',
        );
        setTimeout(() => {
          currentReplyEditor[currentConversationId].undo.saveStep();
        }, 0);
      }
    } else if (tabActiveKey === 'note') {
      if (currentNoteEditor && currentNoteEditor[currentConversationId]) {
        currentNoteEditor[currentConversationId].html.set(
          agentTypedNoteMessages &&
            agentTypedNoteMessages[currentConversationId]
            ? agentTypedNoteMessages[currentConversationId]
            : '',
        );
        setTimeout(() => {
          currentNoteEditor[currentConversationId].undo.saveStep();
        }, 0);
      }
    }
    if(currentReplyEditor?.[currentConversationId] || currentNoteEditor?.[currentConversationId]){
      focusEditor();
    }
  }, [
    tabActiveKey,
    isAiBotAssist,
    currentReplyEditor,
    currentNoteEditor,
    currentConversationId,
  ]);

  useEffect(() => {
    onSetSnippetTribute();
    if (tabActiveKey !== tabActiveKeyRef.current) {
      tabActiveKeyRef.current = tabActiveKey;
    }
  }, [tabActiveKey]);

  useEffect(() => {
    // Update the composer tab
    if (tabActiveKey !== messageState) {
      setMessageState(tabActiveKey);
    }
  }, [tabActiveKey, messageState, editMessages]);

  useEffect(() => {
    if (isEditorEditModeOn.current && deleteMessage) {
      if (deleteMessage && deleteMessage.id === editableMessageRef.current.id) {
        if (editableMessageRef.current.message_type === 'note') {
          cancelNoteEdit();
        } else {
          cancelReplyEdit();
        }
      }
    }
  }, [isEditorEditModeOn.current, deleteMessage, editMessages]);

  useEffect(() => {
    if (isReplyEditorFocus) {
      onComposerTabChange('reply');
    }
  }, [isReplyEditorFocus]);

  useEffect(() => {
    if (isNoteEditorFocus) {
      onComposerTabChange('note');
    }
  }, [isNoteEditorFocus]);

  // TODO - Jitta - Review this
  useEffect(() => {
    if (
      currentReplyEditor &&
      currentReplyEditor[currentConversationId] &&
      aiAssistReplyResponse[currentConversationId] !== null &&
      Object.keys(aiAssistReplyResponse).length !== 0
    ) {
      currentReplyEditor[currentConversationId].selection.restore();
      if (currentReplyEditor[currentConversationId].selection.text() !== '') {
        currentReplyEditor[currentConversationId].html.insert(
          aiAssistReplyResponse[currentConversationId],
        );
        setTimeout(() => {
          currentReplyEditor[currentConversationId].undo.saveStep();
        }, 0);
        dispatch({
          type: actions.FETCH_AI_ASSIST_SUCCESS,
          replyResponse: null,
          conversationId: currentConversationId,
        });
      }
    }
  }, [aiAssistReplyResponse, currentConversationId]);

  useEffect(() => {
    if (
      currentNoteEditor &&
      currentNoteEditor[currentConversationId] &&
      aiAssistNoteResponse[currentConversationId] !== null &&
      Object.keys(aiAssistNoteResponse).length !== 0
    ) {
      currentNoteEditor[currentConversationId].selection.restore();
      if (currentNoteEditor[currentConversationId].selection.text() !== '') {
        currentNoteEditor[currentConversationId].html.insert(
          aiAssistNoteResponse[currentConversationId],
        );
        setTimeout(() => {
          currentNoteEditor[currentConversationId].undo.saveStep();
        }, 0);
        dispatch({
          type: actions.FETCH_AI_ASSIST_SUCCESS,
          noteResponse: null,
          conversationId: currentConversationId,
        });
      }
    }
  }, [aiAssistNoteResponse, currentConversationId]);

  const froalaConfig = {
    key: settings.FROALA_EDITOR_KEY,
    attribution: false,
    toolbarInline: true,
    toolbarSticky: false,
    charCounterCount: false,
    linkAlwaysBlank: true,
    linkAlwaysNoFollow: true,
    linkNoOpener: true,
    linkNoReferrer: true,
    htmlAllowedTags: [
      'a',
      'strong',
      'em',
      'u',
      'br',
      'img',
      'p',
      'ol',
      'li',
      'ul',
    ],
    heightMin: 50,
    heightMax: '45vh',
    iconsTemplate: 'font_awesome_5r',
    imageDefaultWidth: 0,
    imageDefaultAlign: 'left',
    imageDefaultMargin: 0,
    imageDefaultDisplay: 'block',
    imageResize: false,
    pluginsEnabled,
    imageEditButtons,
    imageUploadURL: `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/upload_image`,
    imageUploadParam: 'attachment_image',
    enter: Froalaeditor.ENTER_BR,
    linkInsertButtons: [],
    linkList: [],
    pasteAllowedStyleProps: [''],
    shortcutsEnabled,
  };

  const replyEditorConfig = {
    ...froalaConfig,
    toolbarButtons: socialMediaRestriction(
      props.currentConversationMessages,
      'reply',
    )
      ? [...toolbarButtons, ...aiAssistToolbarButtons]
      : [],
    placeholderText: 'Write a message or type / to add a snippet',
    events: {
      initialized() {
        replyEditor.current = this;
        activateComposerTab('reply', currentConversationId);
        dispatch({
          type: sharedActions.SET_REPLY_EDITOR,
          payload: this,
          cid: currentConversationId,
        });
        if (snippetTribute) {
          snippetTribute.attach(this.el);
        }
        let replyEditorCount = 0;
        const replyEditorSetInterval = setInterval(() => {
          if (replyEditor.current.events) {
            clearInterval(replyEditorSetInterval);
            replyEditor.current.events.on(
              'keydown',
              (keyDownEvent) => {
                if (keyDownEvent.which === keyCode.escape) {
                  clickOutSideComposer();
                  dispatch({
                    type: actions.CHANGE_REPLY_EDITOR_FOCUS,
                    payload: false,
                  });
                } else if (
                  (keyDownEvent.ctrlKey || keyDownEvent.metaKey) &&
                  keyDownEvent.shiftKey &&
                  keyDownEvent.keyCode === keyCode.enter &&
                  currentConversation.status === 'open'
                ) {
                  sendReply('closed');
                  setTimeout(() => {
                    replyEditor.current.events.trigger(
                      'image.hideResizer',
                      [],
                      true,
                    );
                  }, 50);
                  replyEditor.current.html.set('');
                  return false;
                } else if (
                  (keyDownEvent.ctrlKey || keyDownEvent.metaKey) &&
                  keyDownEvent.altKey &&
                  keyDownEvent.keyCode === keyCode.enter &&
                  currentConversation.status === 'open'
                ) {
                  setDisplaySnoozeMenu(true);
                  return false;
                } else if (
                  (keyDownEvent.ctrlKey || keyDownEvent.metaKey) &&
                  keyDownEvent.which === keyCode.enter
                ) {
                  sendReply(false);
                  setTimeout(() => {
                    replyEditor.current.events.trigger(
                      'image.hideResizer',
                      [],
                      true,
                    );
                  }, 50);
                  replyEditor.current.html.set('');
                  return false;
                } else if (
                  keyDownEvent.which === keyCode.enter &&
                  snippetTribute.isActive
                ) {
                  return false;
                } else if (
                  (keyDownEvent.ctrlKey || keyDownEvent.metaKey) &&
                  keyDownEvent.which === shortcutKeyCodes.J &&
                  (!fetchHiddenFeatures().includes(menu.aiAssist) &&
                    !fetchRestrictedFeatures().includes(menu.aiAssist))
                ) {
                  if (replyEditor.current.selection.text()) {
                    replyEditor.current.selection.save();
                  }
                  keyDownEvent.preventDefault();
                  dispatch({
                    type: actions.UPDATE_AI_ASSIST_MODE,
                    payload: 'default',
                  });
                  dispatch({
                    type: sharedActions.UPDATE_OMNISEARCH_STATE,
                    payload: 'ai_assist',
                  });
                  dispatch({
                    type: sharedActions.SHOW_HIDE_OMNISEARCH,
                    payload: true,
                  });
                  return false;
                } else if (
                  (keyDownEvent.ctrlKey || keyDownEvent.metaKey) &&
                  keyDownEvent.which === shortcutKeyCodes.K
                ) {
                  if (!replyEditor.current.selection.text()) {
                    replyEditor.current.selection.save();
                    keyDownEvent.preventDefault();
                    dispatch({
                      type: sharedActions.SHOW_HIDE_OMNISEARCH,
                      payload: true,
                    });
                    return false;
                  }
                  return true;
                }
              },
              true,
            );
          }
          replyEditorCount++;
          if (replyEditorCount > 50) {
            clearInterval(replyEditorSetInterval);
          }
        }, 200);
      },
      keydown: (e) => {
        typingStatusHandler();
        // if (
        //   (e.ctrlKey || e.metaKey) &&
        //   e.shiftKey &&
        //   e.keyCode === keyCode.enter &&
        //   props.currentConversation.status === 'open'
        // ) {
        //   sendReply('closed');
        //   setTimeout(() => {
        //     replyEditor.events.trigger('image.hideResizer', [], true);
        //   }, 50);
        //   replyEditor.html.set('');
        //   return false;
        // } else if (
        //   (e.ctrlKey || e.metaKey) &&
        //   e.altKey &&
        //   e.keyCode === keyCode.enter &&
        //   props.currentConversation.status === 'open'
        // ) {
        //   gistThis.setState({ showSnoozeMenu: true });
        //   return false;
        // }
        // else if ((e.ctrlKey || e.metaKey) && e.keyCode === keyCode.enter) {
        //   sendReply(false);
        //   setTimeout(() => {
        //     replyEditor.events.trigger('image.hideResizer', [], true);
        //   }, 50);
        //   replyEditor.html.set('');
        //   return false;
        // }
        if (e.key === ' ' || e.key === 'Spacebar') {
          replyEditor.current.undo.saveStep();
        }
        if (e.key === '\\') {
          e.preventDefault();
          handleOmnisearchVisibleChange('snippet');
        }
        if (
          (e.ctrlKey || e.metaKey) &&
          e.shiftKey &&
          e.keyCode === shortcutKeyCodes.M
        ) {
          e.preventDefault();
          handleOmnisearchVisibleChange('meeting');
        }
        if (
          (e.ctrlKey || e.metaKey) &&
          e.shiftKey &&
          e.keyCode === shortcutKeyCodes.H
        ) {
          e.preventDefault();
          handleOmnisearchVisibleChange('article');
        }
        if (
          (e.ctrlKey || e.metaKey) &&
          e.shiftKey &&
          e.keyCode === shortcutKeyCodes.A
        ) {
          e.preventDefault();
          handleUploadAttachment();
        }
        if (
          (e.ctrlKey || e.metaKey) &&
          e.shiftKey &&
          e.keyCode === shortcutKeyCodes.I
        ) {
          e.preventDefault();
          handleImageAttachment();
        }
      },
      blur: () => {
        replyEditor.current.selection.save();
        dispatch({
          type: actions.CHANGE_REPLY_EDITOR_FOCUS,
          payload: false,
        });
        setIsEditorFocused(false);
      },
      focus: () => {
        if (replyEditor.current && replyEditor.current.selection) {
          replyEditor.current.selection.restore();
        }
        setIsEditorFocused(true);
      },
      'commands.undo': () => {
        replyEditor.current.undo.saveStep();
      },
      'image.beforeUpload': () => {
        setReplyLoader(true);
      },
      'image.loaded': () => {
        setReplyLoader(false);
        setTimeout(() => {
          replyEditor.current.events.trigger('image.hideResizer', [], true);
          replyEditor.current.html.insert('<br>');
        }, 50);
      },
      'image.uploaded': function (response) {
        // Parse response to get image url.
        const imgLink = JSON.parse(response).data.link;
        // Inserts image manually
        this.image.insert(
          imgLink,
          false,
          { href: imgLink, changeurl: true },
          this.image.get(),
          response,
        );
        return false;
      },
      'image.error': () => {
        // TODO: Need to handle properly along with sentry
        message.error('Image not uploaded');
        setReplyLoader(false);
      },
      // 'paste.beforeCleanup': convertListItemsToParagraph,
    },
  };

  const noteEditorConfig = {
    ...froalaConfig,
    toolbarButtons: [...toolbarButtons, ...aiAssistToolbarButtons],
    placeholderText:
      'Add a private note and mention your teammates here (type @ and their name) or type / to add a snippet',
    events: {
      initialized() {
        noteEditor.current = this;
        dispatch({
          type: sharedActions.SET_NOTE_EDITOR,
          payload: this,
          cid: currentConversationId,
        });
        if (mentionTribute) {
          mentionTribute.attach(this.el);
        }
        if (snippetTribute) {
          snippetTribute.attach(this.el);
        }
        let noteEditorCount = 0;
        const noteEditorSetInterval = setInterval(() => {
          if (noteEditor.current.events) {
            clearInterval(noteEditorSetInterval);
            noteEditor.current.events.on(
              'keydown',
              (keyDownEvent) => {
                if (
                  (keyDownEvent.ctrlKey || keyDownEvent.metaKey) &&
                  keyDownEvent.keyCode === keyCode.enter
                ) {
                  sendNotes();
                  setTimeout(() => {
                    noteEditor.current.events.trigger(
                      'image.hideResizer',
                      [],
                      true,
                    );
                  }, 50);
                  noteEditor.current.html.set('');
                  return false;
                } else if (
                  keyDownEvent.which === keyCode.enter &&
                  mentionTribute.isActive
                ) {
                  return false;
                } else if (
                  (keyDownEvent.ctrlKey || keyDownEvent.metaKey) &&
                  keyDownEvent.which === shortcutKeyCodes.J &&
                  !fetchHiddenFeatures().includes(menu.aiAssist) &&
                  !fetchRestrictedFeatures().includes(menu.aiAssist)
                ) {
                  if (noteEditor.current.selection.text()) {
                    noteEditor.current.selection.save();
                  }
                  keyDownEvent.preventDefault();
                  dispatch({
                    type: actions.UPDATE_AI_ASSIST_MODE,
                    payload: 'default',
                  });
                  dispatch({
                    type: sharedActions.UPDATE_OMNISEARCH_STATE,
                    payload: 'ai_assist',
                  });
                  dispatch({
                    type: sharedActions.SHOW_HIDE_OMNISEARCH,
                    payload: true,
                  });
                  return false;
                } else if (
                  (keyDownEvent.ctrlKey || keyDownEvent.metaKey) &&
                  keyDownEvent.which === shortcutKeyCodes.K
                ) {
                  if (!noteEditor.current.selection.text()) {
                    noteEditor.current.selection.save();
                    keyDownEvent.preventDefault();
                    dispatch({
                      type: sharedActions.SHOW_HIDE_OMNISEARCH,
                      payload: true,
                    });
                    return false;
                  }
                  return true;
                }
              },
              true,
            );
          }
          noteEditorCount++;
          if (noteEditorCount > 50) {
            clearInterval(noteEditorSetInterval);
          }
        }, 200);
      },
      keydown: (e) => {
        // if ((e.ctrlKey || e.metaKey) && e.keyCode === keyCode.enter) {
        //   sendNotes();
        //   setTimeout(() => {
        //     noteEditor.current.events.trigger('image.hideResizer', [], true);
        //   }, 50);
        //   noteEditor.current.html.set('');
        //   return false;
        // } else
        if (e.which === keyCode.escape) {
          clickOutSideComposer();
          dispatch({
            type: actions.CHANGE_NOTE_EDITOR_FOCUS,
            payload: false,
          });
        }
        if (e.key === ' ') {
          noteEditor.current.undo.saveStep();
        }
        if (e.key === '\\') {
          e.preventDefault();
          handleOmnisearchVisibleChange('snippet');
        }
        if (
          (e.ctrlKey || e.metaKey) &&
          e.shiftKey &&
          e.keyCode === shortcutKeyCodes.M
        ) {
          e.preventDefault();
          handleOmnisearchVisibleChange('meeting');
        }
        if (
          (e.ctrlKey || e.metaKey) &&
          e.shiftKey &&
          e.keyCode === shortcutKeyCodes.H
        ) {
          e.preventDefault();
          handleOmnisearchVisibleChange('article');
        }
        if (
          (e.ctrlKey || e.metaKey) &&
          e.shiftKey &&
          e.keyCode === shortcutKeyCodes.A
        ) {
          e.preventDefault();
          handleUploadAttachment();
        }
        if (
          (e.ctrlKey || e.metaKey) &&
          e.shiftKey &&
          e.keyCode === shortcutKeyCodes.I
        ) {
          e.preventDefault();
          handleImageAttachment();
        }
      },
      blur: () => {
        noteEditor.current.selection.save();
        dispatch({
          type: actions.CHANGE_NOTE_EDITOR_FOCUS,
          payload: false,
        });
        setIsEditorFocused(false);
      },
      focus: () => {
        if (noteEditor.current && noteEditor.current.selection) {
          noteEditor.current.selection.restore();
        }
        setIsEditorFocused(true);
      },
      'commands.undo': () => {
        noteEditor.current.undo.saveStep();
      },
      'image.beforeUpload': () => {
        setNoteLoader(true);
      },
      'image.loaded': () => {
        setNoteLoader(false);
        setTimeout(() => {
          noteEditor.current.events.trigger('image.hideResizer', [], true);
          noteEditor.current.html.insert('<br>');
        }, 50);
      },
      'image.uploaded': function (response) {
        // Parse response to get image url.
        const imgLink = JSON.parse(response).data.link;
        // Inserts image manually
        this.image.insert(
          imgLink,
          false,
          { href: imgLink, changeurl: true },
          this.image.get(),
          response,
        );
        return false;
      },
      'image.error': () => {
        // TODO: Need to handle properly along with sentry
        message.error('Image not uploaded');
      },
      // 'paste.beforeCleanup': convertListItemsToParagraph,
    },
  };

  const isAIAssistEnabled = !fetchHiddenFeatures().includes(menu.aiAssist) &&
      !fetchRestrictedFeatures().includes(menu.aiAssist),
    isCallIconVisible =
      !isEditorEditModeOn.current &&
      tabActiveKey === 'reply' &&
      !fetchHiddenFeatures().includes(menu.callIntegration) &&
      !isPrimeCouponWorkspace() &&
      !fetchRestrictedFeatures().includes(menu.callIntegration) &&
      socialMediaRestriction(currentConversationMessages, tabActiveKey) &&
      isEmailRoom(currentConversationMessages),
    operations = (
      <div>
        {isAIAssistEnabled && (
          <Tooltip title="AI Compose" placement={'top'}>
            <span onClick={() => handleOmniSearch('ai_assist')}>
              <span className="ai-icon">AI</span>
            </span>
          </Tooltip>
        )}

        {isCallIconVisible && (
          <CallHolder isFromPreviousConversation={isPreviousConversation} />
        )}

        {!isEditorEditModeOn.current && allowOutgoingAttachments && (
          <FileUpload customRequest={enqueueAttachment} />
        )}

        {!isEditorEditModeOn.current && (
          <GifUpload
            gifResource={gifResource}
            postReply={postGifReply}
            postNote={postGifNote}
            composerState={messageState}
            hideGif={hideGif}
            emptyGif={isEmptyGif}
            gifOffset={gifOffset}
            visible={showGif}
            onVisibleChange={handleGifVisibleChange}
          />
        )}

        <EmojisHolder
          onSelectEmojis={onSelectEmojis}
          visible={showPicker}
          onVisibleChange={handleEmojiPickerVisibleChange}
        />

        <span onClick={() => handleOmniSearch('snippet')}>
          <Popover
            content={
              <>
                <span>Snippet</span>
                <span className="shortcut-indicator">
                  <span className="shortcut-key">\</span>
                </span>
              </>
            }
            overlayClassName="minimal-popover"
            trigger="hover"
          >
            <i className="fal fa-bookmark" />
          </Popover>
        </span>
        <span onClick={() => handleOmniSearch('article')}>
          <Popover
            content={
              <>
                <span>Articles</span>
                <span className="shortcut-indicator">
                  <span className="shortcut-key">⌘</span>
                  <span className="shortcut-key">⇧</span>
                  <span className="shortcut-key">h</span>
                </span>
              </>
            }
            overlayClassName="minimal-popover"
            trigger="hover"
          >
            <i className="fal fa-file-alt" />
          </Popover>
        </span>
        <span onClick={showOmniSearch}>
          <Popover
            content={
              <>
                <span>Command center</span>
                <span className="shortcut-indicator">
                  <span className="shortcut-key">⌘</span>
                  <span className="shortcut-key">k</span>
                </span>
              </>
            }
            overlayClassName="minimal-popover"
            trigger="hover"
          >
            <i className="fal fa-bolt" />
          </Popover>
        </span>
      </div>
    ),
    cid = currentConversationId,
    currentReplyAttachments =
      replyAttachmentQueues[currentConversation?.conversation_identifier],
    currentNoteAttachments =
      noteAttachmentQueues[currentConversation?.conversation_identifier];
  return (
    <>
      <Tabs
        tabBarExtraContent={operations}
        tabBarGutter={0}
        className={`${
          tabActiveKey === 'note'
            ? isEditorEditModeOn.current &&
              editableMessageRef.current.message_type === 'note'
              ? ' editable-note'
              : 'note'
            : isEditorEditModeOn.current &&
              editableMessageRef.current.message_type !== 'note'
            ? ' editable-reply'
            : ''
        }
        ${isEditorFocused ? 'is-focused' : ''}
        `}
        defaultActiveKey={messageState === 'reply' ? 'reply' : 'note'}
        onChange={onComposerTabChange}
        activeKey={tabActiveKey}
        animated={false}
      >
        <TabPane
          tab={
            isEditorEditModeOn.current &&
            editableMessageRef.current.message_type !== 'note'
              ? 'Edit'
              : 'Reply'
          }
          key="reply"
          size="small"
        >
          <TextEditor
            id={`cid-reply-${currentConversation.id}`}
            config={replyEditorConfig}
            model={replyMsg}
            onModelChange={onReplyValueChange}
          />

          <div className="editor-footer">
            {aiAssistReplyLoader[
              currentConversation.conversation_identifier
            ] && <TypingBubble isFromAiAssist={true} />}
            <div className="attachments-preview">
              {currentReplyAttachments &&
                currentReplyAttachments.map((attachment, index) => (
                  <Tooltip
                    title={attachment.file_attachment.name}
                    placement={'top'}
                    key={index}
                  >
                    <div className="tag">
                      <div className="file-name">
                        {attachment.file_attachment.name}
                      </div>
                      {replySending[cid] && <Icon type="loading" />}
                      <i
                        className="fas fa-xmark"
                        onClick={() => removeReplyAttachment(cid, index)}
                      />
                    </div>
                  </Tooltip>
                ))}
            </div>

            <div className="send-buttons">
              {replyMsg &&
                !isEditorEditModeOn.current &&
                currentConversation.status !== 'closed' && (
                  <Button
                    className="btn btn-secondary-outline btn-xs"
                    onClick={() => sendReply('closed')}
                    loading={!!(replySending[cid] && messageStatus)}
                    disabled={isOffline || replySending[cid]}
                  >
                    Send & Close
                  </Button>
                )}
              {replyMsg &&
                !isEditorEditModeOn.current &&
                currentConversation.status === 'open' && (
                  <>
                    <span
                      className="ant-btn btn btn-secondary-outline btn-xs send-snooze-button"
                      ref={snoozeRefTwo}
                      onClick={() => handleSnoozeMenu(true)}
                    >
                      <i className={'fas fa-clock'} />
                    </span>

                    <Snooze
                      anchorRef={snoozeRefTwo}
                      isOpen={displaySnoozeMenu}
                      handleSnoozeMenu={handleSnoozeMenu}
                      sendAndSnooze={true}
                      sendReply={sendReply}
                      currentConversationId={currentConversationId}
                    />
                  </>
                )}

              {isEditorEditModeOn.current && (
                <Button
                  className={'btn btn-secondary-outline btn-xs'}
                  type={'secondary'}
                  onClick={cancelReplyEdit}
                >
                  Cancel
                </Button>
              )}
              <Button
                className={'btn btn-primary btn-xs'}
                onClick={() => sendReply('open')}
                loading={replySending[cid] && !messageStatus}
                disabled={isOffline || replyLoader || replySending[cid]}
              >
                {currentConversation.status === 'open'
                  ? replySending[cid] && !messageStatus
                    ? 'Sending'
                    : 'Send'
                  : 'Send & Reopen'}
              </Button>
            </div>
          </div>
        </TabPane>
        <TabPane
          tab={
            isEditorEditModeOn.current &&
            editableMessageRef.current.message_type === 'note'
              ? 'Edit'
              : 'Note'
          }
          key="note"
          size="small"
        >
          <TextEditor
            id={`cid-note-${currentConversation.id}`}
            messageState={messageState}
            config={noteEditorConfig}
            model={noteMsg}
            onModelChange={onNoteValueChange}
          />

          <div className="editor-footer">
            {aiAssistNoteLoader[
              currentConversation.conversation_identifier
            ] && <TypingBubble isFromAiAssist={true} />}
            <div className="attachments-preview">
              {currentNoteAttachments &&
                currentNoteAttachments.map((attachment, index) => (
                  <Tooltip
                    title={attachment.file_attachment.name}
                    placement={'top'}
                    key={index}
                  >
                    <div className="tag">
                      <div className="file-name">
                        {attachment.file_attachment.name}
                      </div>
                      {noteSending[cid] && <Icon type="loading" />}
                      <i
                        className="fas fa-xmark"
                        onClick={() => removeNoteAttachment(cid, index)}
                      />
                    </div>
                  </Tooltip>
                ))}
            </div>

            <div className="send-buttons">
              {isEditorEditModeOn.current && (
                <Button
                  className="btn btn-secondary-outline btn-xs"
                  onClick={cancelNoteEdit}
                >
                  Cancel
                </Button>
              )}

              <Button
                className="btn btn-primary btn-xs"
                onClick={sendNotes}
                loading={noteSending[cid]}
                disabled={isOffline || noteLoader}
              >
                {noteSending[cid] ? 'Sending' : 'Add note'}
              </Button>
            </div>
          </div>
        </TabPane>
      </Tabs>
    </>
  );
};

export default MessageComposer;
