export const menu = {
  knowledgeBase: 'knowledge_base',
  routingRules: 'routing_rules',
  domainWhitelisting: 'domain_whitelisting',
  conversationalBots: 'conversational_bots',
  qualificationBots: 'qualification_bots',
  automatedBotsBookings: 'automated_bots_bookings',
  eventVisualiser: 'event_visualiser',
  autoMessages: 'auto_messages',
  broadcasts: 'broadcasts',
  campaigns: 'campaigns',
  emailStatsAndGoals: 'email_stats_and_goals',
  customEmailTemplates: 'custom_email_templates',
  marketingAutomation: 'marketing_automation',
  marketingWorkflow: 'marketing_workflow',
  outboundEmails: 'outbound_emails',
  inboundEmails: 'inbound_emails',
  brandingRemoved: 'branding_removed',
  googleAnalyticsIntegration: 'google_analytics_integration',
  facebookIntegration: 'facebook_integration',
  webhooksIntegration: 'webhooks_integration',
  stripeIntegration: 'stripe_integration',
  zoomIntegration: 'zoom_integration',
  teamMates: 'teammates',
  eventClick: 'click_event_tracking',
  fromCustomEvent:'from_custom_event_tracking',
  meetingScheduler: 'meeting_scheduling',
  teamInbox: 'team_inbox',
  twitterIntegration: 'twitter',
  agentInboxReports: 'agent_inbox_reports',
  facebookCustomAudience: 'fb_custom_audience_integration',
  eCommerceIntegration: 'ecommerce_integration',
  proactiveMessaging: 'proactive_messaging',
  emailVisualBuilder: 'email_visual_builder',
  sendEmailOnRecipientTimezone: 'send_email_on_recipient_timezone',
  chatBot: 'chat_bot',
  chatOneOffMessage: 'one_off_chat',
  customBotIcon: 'bot_custom_icon',
  postOneOff: 'one_off_announcement',
  announcementTemplate: 'announcement_template',
  projectRole: 'project_role',
  supportBot: 'support_bot',
  callIntegration: 'call_integration',
  inboxView: 'inbox_view',
  developerApp: 'developer_app',
  emailReports: 'email_reports',
  surveys: 'surveys',
  tours: 'tours',
  inboxSpamView: 'spam_inbox',
  emailLinkEdit: 'email_link_edit',
  kbThemes: 'kb_themes',
  sneakPeek: 'sneak_peek',
  aiAssist: 'ai_assist_inbox',
  identityVerification: 'identity_verification',
  woocommerceIntegration: 'woocommerce_integration',
  shopifyIntegration: 'shopify_integration',
  inboxAdvancedSearch: 'inbox_advanced_search',
  inboxTable: 'inbox_table',
  liveView: 'live_view',
};

export const restrictedModule = [
  {
    key: 'broadcasts',
    value: 'Emails',
    restriction: 'broadcasts',
    message: 'Upgrade to send them out',
  },
  {
    key: 'behavioral',
    value: 'Emails',
    restriction: 'auto_messages',
    message: 'Upgrade to set them live',
  },
  {
    key: 'campaigns',
    value: 'Campaigns',
    restriction: 'campaigns',
    message: 'Upgrade to set them live',
  },
  {
    key: 'workflows',
    value: 'Workflows',
    restriction: 'marketing_workflow',
    message: 'Upgrade to set them live',
  },
  {
    key: 'rules',
    value: 'Automation Rules',
    restriction: 'marketing_automation',
    message: 'Upgrade to set them live',
  },
  {
    key: 'email-template',
    value: 'Templates',
    restriction: 'custom_email_templates',
    message: 'Upgrade to use them',
  },
  {
    key: 'article',
    value: 'Articles',
    restriction: 'knowledge_base',
    message: 'Upgrade to publish them',
  },
  {
    key: 'answers',
    value: 'Answers',
    restriction: 'support_bot',
    message: 'Upgrade to set them live',
  },
  {
    key: 'bots',
    value: 'Bots',
    restriction: 'chat_bot',
    message: 'Upgrade to set them live',
  },
  {
    key: 'surveys',
    value: 'Surveys',
    restriction: 'surveys',
    message: 'Upgrade to set them live',
  },
  {
    key: 'inbox_automation',
    value: 'Inbox Automation',
    restriction: 'routing_rules',
    message: 'Upgrade to set them live',
  },
];

// Purpose for whitelabel
export const restrictedPage = {
  signUp: 'sign_up',
  conversation: 'conversation',
  chatBot: 'chat_bot',
  liveView: 'live_view',
  knowledgeBase: 'knowledge_base',
  conversationReport: 'conversation_report',
  qualificationBot: 'qualification_bots',
  changePlan: 'change_plan',
  dataTracking: 'data_and_tracking',
  forms: 'forms',
  workspace: 'workspace',
};

export const subscriptionAddons = [
  {
    key: 'support-bot-addon',
    restriction: 'support_bot',
    message: 'To include additional emma feature.',
  },
];

export const userRestriction = {
  workspaceSettings: 'can_manage_workspace_settings',
  teammates: 'can_manage_teammates',
  teams: 'can_manage_teams',
  trackingDomains: 'can_manage_tracking_domains',
  messengerSettings: 'can_manage_messenger_settings',
  kbSettings: 'can_manage_kb_settings',
  marketingSettings: 'can_manage_marketing_settings',
  inboxSettings: 'can_manage_inbox_settings',
  dealSettings: 'can_manage_deal_settings',
  apiKey: 'can_access_api_key',
  appsAndIntegrations: 'can_manage_apps_and_integrations',
  billingSettings: 'can_manage_billing_settings',
  inbox: 'can_manage_inbox', // can also be "assigned_only" or "assigned_teams_only"
  UnassignedInbox: 'can_manage_unassigned_inbox', // un assigned inbox"
  reassignConversations: 'can_reassign_conversations',
  conversationMessages: 'can_manage_conversation_messages',
  tags: 'can_manage_tags',
  savedReplies: 'can_manage_saved_replies',
  inboxRules: 'can_manage_inbox_rules',
  liveView: 'can_access_live_view',
  articles: 'can_manage_articles', // can also be "read" or "write"
  messages: 'can_manage_messages', // can also be "read" or "write"
  bots: 'can_manage_bots', // can also be "read" or "write"
  contacts: 'can_manage_contacts', // can also be "view" or "edit"
  properties: 'can_manage_properties', // can also be "view" or "edit"
  deleteContacts: 'can_bulk_actions_contacts',
  importContacts: 'can_import_contacts',
  exportContacts: 'can_export_contacts',
  communicateWithContacts: 'can_communicate_with_contacts',
  automations: 'can_manage_automations', // can also be "read" or "write"
  events: 'can_manage_events',
  deals: 'can_manage_deals',
  reporting: 'can_access_reporting',
  support: 'can_manage_support',
  emmaSetup: 'can_access_emma_setting',
  emmaManageCustomAnswers: 'can_manage_emma_custom_answers',
  emmaManageContent: 'can_manage_emma_content',
  inboxAppAccess: 'can_manage_inbox_apps',
  customApps: 'can_manage_custom_apps',
};

export const defaultUserPermissions = {
  can_manage_workspace_settings: true,
  can_manage_teammates: false,
  can_manage_teams: false,
  can_manage_tracking_domains: true,
  can_manage_messenger_settings: true,
  can_manage_kb_settings: true,
  can_manage_marketing_settings: true,
  can_manage_inbox_settings: true,
  can_manage_deal_settings: true,
  can_access_api_key: true,
  can_manage_apps_and_integrations: true,
  can_manage_billing_settings: false,

  can_manage_contacts: true,
  can_communicate_with_contacts: true,
  can_manage_properties: true,
  can_bulk_actions_contacts: true,
  can_import_contacts: true,
  can_export_contacts: true,

  can_manage_inbox: 'all', // can also be "assigned_only" or "assigned_teams_only" or "no_access"
  can_reassign_conversations: true,
  can_manage_conversation_messages: true,
  can_manage_tags: true,
  can_manage_saved_replies: true,
  can_manage_inbox_rules: true,
  can_access_live_view: true,
  can_manage_articles: 'all', // can also be "write" or "no_access"
  can_access_reporting: true,
  can_access_emma_setting: true,
  can_access_emma_custom_answers: true,
  can_manage_emma_custom_answers: 'all',
  can_access_emma_content: true,
  can_manage_emma_content: 'all',
  can_manage_inbox_apps: 'all',
  can_manage_custom_apps: [],

  can_manage_messages: 'all', // can also be "write" or "no_access"
  can_manage_bots: 'all', // can also be "write" or "no_access"
  can_manage_automations: 'all', // can also be "write" or "no_access"
  can_manage_events: true,
  can_manage_deals: 'all', // can also be "assigned_only" or "no_access"

  can_manage_support: false,
};

export const supportPermission = {
  can_manage_inbox: 'no_access', // can also be "assigned_only" or "assigned_teams_only" or "no_access"
  can_reassign_conversations: false,
  can_manage_conversation_messages: false,
  can_manage_tags: false,
  can_manage_saved_replies: false,
  can_manage_inbox_rules: false,
  can_access_live_view: false,
  can_manage_articles: 'no_access', // can also be "write" or "no_access"
  can_access_reporting: false,
  can_manage_messenger_settings: false,
  can_manage_kb_settings: false,
  can_manage_inbox_settings: false,
  can_access_emma_setting: false,
  can_manage_emma_custom_answers: 'no_access',
  can_manage_emma_content: 'no_access',
  can_manage_inbox_apps: 'no_access',
};

export const supportBotAddonDetails = [
  {
    title: 'Powered by your support content',
    features: [
      'Your Gist Articles',
      'Conversation data (coming soon)',
      'Any public URL (coming soon)',
      'Multiple sources (coming soon)',
      'Multiple public URLs (coming soon)',
      'Automated content sync',
      'Manual content sync',
      'No training, no configuration',
    ],
  },
  {
    title: 'Fully customizable',
    features: ['Rename Bot', 'Customize Bot icon', 'Personalize Bot tone'],
  },
  {
    title: 'Conversational by design',
    features: [
      'Powered by GPT-4',
      'Holds complete conversations',
      'Asks clarifying questions',
      'Multilingual',
    ],
  },
  {
    title: 'Intelligent controls',
    features: [
      'Preview Bot Flows before setting live',
      'Custom Answers for important questions',
      'Configure multiple AI bots  (coming soon)',
      'Inbox view for Bot conversations',
      'Rules and audience targeting',
      'Resolution limits with notifications',
      'Handoff to support teams',
      'Handoff to other bots',
    ],
  },
  {
    title: 'Measurement and Reporting',
    features: [
      'Usage metrics',
      'Performance/ROI metrics',
      'CSV download (coming soon)',
      'Article-level metrics (coming soon)',
      'Content score (coming soon)',
    ],
  },
];
