import settings from '../../settings/index';
import { store } from '../../redux/store';
import { socialMediaRestriction } from '../../components/Chat/Messages/MessageComposer';
import {fetchHiddenFeatures, fetchRestrictedFeatures} from '../../helpers/utility';
import { menu } from '../../components/TopNavBar/constants';

export const socketChannels = {
  reconnectAttempt: 'reconnect_attempt',
  connect: 'connect',
  chatMessage: 'chat_message',
  conversationAssignment: 'conversation_assignment',
  changeChatRoom: 'change_chat_room',
  typingStarted: 'typing_started',
  typingStopped: 'typing_stopped',
  agentTypingStarted: 'agent_typing_started',
  agentTypingStopped: 'agent_typing_stopped',
  reloadConversation: 'reload_conversation',
  removeConversation: 'remove_conversation',
  deleteConversation: 'delete_conversation',
  blockUser: 'block_user',
  unblockUser: 'unblock_user',
  qualificationProperty: 'qualification_property',
  leadBotAttributeMapping: 'lead_bot_attribute_mapping',
  botGoalReached: 'bot_goal_reached',
  botConversationEnded: 'bot_conversation_ended',
  meetingScheduler: 'meeting_scheduler',
  online: 'online',
  offline: 'offline',
  changeConversationStatus: 'change_conversation_status',
  userPropertyUpdate: 'user_property_update',
  availabilityStatus: 'availability_status',
  priority: 'priority',
  notificationSettings: 'notification_settings',
  integrationStatus: 'integration_status',
  contactsLiveUpdate: 'contacts_live_update',
  chatSettings: 'chat_settings',
  contactsAvailableStatus: 'contacts_available_status',
  satisfactionRating: 'satisfaction_rating',
  connectError: 'connect_error',
  conversationStatusUpdate: 'conversation_status_update',
  webPermissions: 'web_permissions',
  joinCall: 'join_call',
  conversationTitle: 'conversation_title',
  conversationTagsUpdated: 'conversation_tag_updated',
  conversationPropertyUpdated: 'conversation_property_updated',
  workflowActivityUpdate: 'workflow_activity_update',
};

export const PAGE_TITLES = {
  '/assistants': 'Assistants',
  '/chat-template/choose-template': 'Choose Template - Chat',
  '/events': 'Events',
  '/import_csv': 'Import CSV',
  '/knowledge-base/articles': 'All Articles',
  '/knowledge-base/collections': 'Collections',
  '/live-view': 'Live View',
  '/meetings': 'Meetings',
  '/messages/bots': 'Bots',
  '/messages/chat': 'Chats',
  '/messages/email': 'Email',
  '/messages/forms': 'Forms',
  '/messages/post': 'Posts',
  '/messages/rules': 'Rules',
  '/messages/surveys': 'Surveys',
  '/messages/tours': 'Tours',
  '/messages/workflows': 'Workflows',
  '/notifications': 'Notifications',
  '/preferences': 'Preferences',
  '/post-template/choose-template': 'Choose Template - Post',
  '/post-templates': 'Post Templates',
  '/post/choose-template': 'Choose Template - Post',
  '/profile': 'Profile',
  '/reports/conversations': 'Conversation Reports',
  '/reports/conversations/responsiveness': 'Responsiveness Reports',
  '/reports/conversations/team-performance': 'Team Performance Reports',
  '/reports/marketing/email-health': 'Email Health Reports',
  '/reports/support/article-feedback': 'Article Feedback Reports',
  '/reports/support/conversations': 'Conversation Reports',
  '/reports/support/emma': 'Emma Reports',
  '/reports/support/responsiveness': 'Responsiveness Reports',
  '/reports/support/customer-satisfaction': 'Customer Satisfaction Reports',
  '/settings/advanced': 'Advanced - Settings',
  '/settings/api-key': 'API key - Settings',
  '/settings/appearance': 'Appearance - Settings',
  '/settings/availability': 'Availability - Settings',
  '/settings/bot-identity': 'Bot Identity - Settings',
  '/settings/company-settings': 'Company - Settings',
  '/settings/contact-properties': 'Contact Properties - Settings',
  '/settings/contact-segments': 'Contact Segments - Settings',
  '/settings/contact-tags': 'Contact Tags - Settings',
  '/settings/conversation-properties': 'Conversation Properties - Settings',
  '/settings/conversation-tags': 'Conversation Tags - Settings',
  '/settings/data-and-privacy': 'Data and Privacy - Settings',
  '/settings/deal-properties': 'Deal Properties - Settings',
  '/settings/email': 'Email - Settings',
  '/settings/email-sending-domain': 'Email Sending Domain - Settings',
  '/settings/email-templates': 'Custom Email Templates - Settings',
  '/settings/exports': 'Exports - Settings',
  '/settings/forms': 'Forms - Settings',
  '/settings/imports': 'Imports - Settings',
  '/settings/invoice': 'Invoice - Settings',
  '/settings/kb-themes': 'KB Themes - Settings',
  '/settings/knowledge-base': 'Knowledge Base - Settings',
  '/settings/language': 'Language - Settings',
  '/settings/message-tags': 'Message Tags - Settings',
  '/settings/messenger-apps': 'Messenger Apps - Settings',
  '/settings/pipelines-and-stages': 'Pipelines and Stages - Settings',
  '/settings/qualification-bot': 'Qualification Bot - Settings',
  '/settings/qualification-data': 'Qualification Data - Settings',
  '/settings/roles': 'Roles - Settings',
  '/settings/security': 'Security - Settings',
  '/settings/email-forwarding': 'Email Forwarding - Settings',
  '/settings/snippets': 'Snippets - Settings',
  '/settings/spam': 'Spam - Settings',
  '/settings/subscription': 'Subscription - Settings',
  '/settings/subscription-types': 'Subscription Types - Settings',
  '/settings/teams': 'Teams - Settings',
  '/settings/tracking-code': 'Tracking Code - Settings',
  '/settings/users': 'Teammates - Settings',
  '/settings/visibility': 'Visibility - Settings',
  '/settings/webhooks': 'Webhooks - Settings',
  '/settings/workspace-defaults': 'Workspace Defaults - Settings',
  '/support/emma/answers': 'Answers - Emma',
  '/support/emma/contents': 'Content Library - Emma',
  '/support/emma/setup': 'Setup - Emma',
  '/support/emma/unanswered': 'Unanswered Questions - Emma',
  '/support/emma/unhelpful': 'Unhelpful Answers - Emma',
  '/survey-templates': 'Survey Templates',
  '/survey/choose-template': 'Choose Template - Surveys',
  '/updates': 'Updates',
  '/workflow-template/choose-template': 'Choose Template - Workflows',
  '/workflow-templates': 'Workflow Templates',
  '/workflow/choose-template': 'Choose Template - Workflows',
};

export const newMessageSound = new Audio(
  `${settings.STATIC_HOST}/audios/newMessage.mp3`,
);
export const newConversationSound = new Audio(
  `${settings.STATIC_HOST}/audios/newConversation.mp3`,
);

// Determine the OS key, either 'command' for Mac or 'ctrl' for other platforms
export const osCTRLkey = window.navigator.appVersion.includes('Mac')
  ? 'command'
  : 'ctrl';

const baseKeys = [
  'esc',
  'g',
  'l',
  'n',
  'p',
  'a',
  'i',
  'j',
  'k',
  'r',
  't',
  'x',
  'z',
  '[',
  ']',
  '\\',
  'shift+/',
  'shift+r',
  'shift+t',
];

const platformSpecificKeys = [
  `${osCTRLkey}+e`,
  `${osCTRLkey}+j`,
  `${osCTRLkey}+k`,
  `${osCTRLkey}+p`,
  `${osCTRLkey}+shift+a`,
  `${osCTRLkey}+shift+e`,
  `${osCTRLkey}+shift+h`,
  `${osCTRLkey}+shift+i`,
  `${osCTRLkey}+shift+m`,
  `${osCTRLkey}+shift+o`,
  `${osCTRLkey}+shift+y`,
];

export const shortcutKeys = [...baseKeys, ...platformSpecificKeys].join(',');

export const shortcutKeyCodes = {
  A: 65,
  C: 67,
  D: 68,
  E: 69,
  F: 70,
  G: 71,
  H: 72,
  I: 73,
  J: 74,
  K: 75,
  L: 76,
  M: 77,
  N: 78,
  O: 79,
  P: 80,
  R: 82,
  S: 83,
  T: 84,
  U: 85,
  W: 87,
  X: 88,
  Y: 89,
  Z: 90,
  ESC: 27,
  '[': 160,
  BACK_SLASH: 220,
  ']': 221,
};

const commonRoutesMappedToAction = {
  navigate_contacts: 'contacts', // c
  navigate_deals: 'deals', // d
  navigate_forms: 'forms', // f
  navigate_knowledgebase: 'knowledge-base/articles', // k
  navigate_liveview: 'live-view', // l
  navigate_messages: 'messages/email', // m
  navigate_settings: 'settings', // s,
  navigate_integrations: 'apps',
  navigate_workflows: 'workflows', // w
};

export const shortcutRoutesMappedToAction = {
  ...commonRoutesMappedToAction,
  navigate_all_inbox: 'conversations/latest', // i
  navigate_assigned_inbox: 'conversations/latest', // a
  navigate_unassigned_inbox: 'conversations/latest', // u
  navigate_spam_inbox: 'conversations/latest', // j
  navigate_to_mentioned_inbox: 'conversations/latest', //m
};

export const shortcutInboxTableRoutesMappedToAction = {
  ...commonRoutesMappedToAction,
  navigate_all_inbox: 'conversations/table', // i
  navigate_assigned_inbox: 'conversations/table', // a
  navigate_unassigned_inbox: 'conversations/table', // u
  navigate_spam_inbox: 'conversations/table', // j
  navigate_to_mentioned_inbox: 'conversations/table', //m
};

const os = window.navigator.appVersion.includes('Mac')
  ? { ctrl: '⌘', alt: '⌥' }
  : { ctrl: 'ctrl', alt: 'alt' };

export const shortcutsListGroups = [
  {
    id: 0,
    label: 'General',
    show_in_omni_search: false,
    show_in_keyboard_list: true,
  },
  {
    id: 1,
    label: 'Conversation',
    show_in_omni_search: true,
    show_in_keyboard_list: true,
  },
  {
    id: 4,
    label: 'Composer',
    show_in_omni_search: true,
    show_in_keyboard_list: true,
  },
  {
    id: 2,
    label: 'Navigation',
    show_in_omni_search: true,
    show_in_keyboard_list: false,
  },
  {
    id: 3,
    label: 'Creation',
    show_in_omni_search: true,
    show_in_keyboard_list: false,
  },
  {
    id: 5,
    label: 'Help & support',
    show_in_omni_search: true,
    show_in_keyboard_list: false,
  },
  {
    id: 6,
    label: 'Change Layout',
    show_in_omni_search: true,
    show_in_keyboard_list: true,
  },
];

const GeneralShortcuts = [
  {
    groupId: 0,
    name: 'Open command menu',
    action: '',
    shortcut_key: [os.ctrl, 'k'],
    icon: '',
    show_in_omni_search: false,
  },
  {
    groupId: 0,
    name: 'Go to',
    action: '',
    shortcut_key:['G'],
    icon: '',
    show_in_omni_search: false,
  },
  {
    groupId: 0,
    name: 'New',
    action: '',
    shortcut_key:['N'],
    icon: '',
    show_in_omni_search: false,
  },
];

const ConversationShortcuts = [
  {
    groupId: 1,
    name: 'Ask Emma',
    action: 'ask_emma',
    shortcut_key: [os.ctrl, 'e'],
    icon: 'AskEmma',
    show_in_omni_search: () =>
      !fetchHiddenFeatures().includes(menu.supportBot) &&
      !fetchRestrictedFeatures().includes(menu.supportBot),
  },
  {
    groupId: 1,
    name: 'Close',
    action: 'close_conversation',
    shortcut_key: [os.ctrl, '⇧', 'y'],
    icon: 'Close',
    show_in_omni_search: true,
  },
  {
    groupId: 1,
    name: 'Reopen',
    action: 'reopen_conversation',
    shortcut_key: [os.ctrl, '⇧', 'o'],
    icon: 'Reopen',
    show_in_omni_search: true,
  },
  {
    groupId: 1,
    name: 'Snooze',
    action: 'snooze_conversation',
    shortcut_key: ['z'],
    icon: 'Snooze',
    show_in_omni_search: true,
  },
  {
    groupId: 1,
    name: 'Change priority',
    action: 'change_priority',
    shortcut_key: ['p'],
    icon: 'Priority',
    show_in_omni_search: true,
  },
  {
    groupId: 1,
    name: 'Assign',
    action: 'assign_to',
    shortcut_key: ['a'],
    icon: 'AssignTo',
    show_in_omni_search: true,
  },
  {
    groupId: 1,
    name: 'Assign to me',
    action: 'assign_to_me',
    shortcut_key: ['i'],
    icon: 'AssignToMe',
    show_in_omni_search: true,
  },
  {
    groupId: 1,
    name: 'Search conversations',
    action: 'search_conversation',
    shortcut_key: [os.ctrl, 'p'],
    icon: 'SearchConversation',
    show_in_omni_search: () =>
      !fetchHiddenFeatures().includes(menu.inboxAdvancedSearch) &&
      !fetchRestrictedFeatures().includes(menu.inboxAdvancedSearch),
  },
  {
    groupId: 1,
    name: 'Previous conversation',
    action: '',
    shortcut_key: ['j'],
    icon: '',
    show_in_omni_search: false,
  },
  {
    groupId: 1,
    name: 'Next conversation',
    action: '',
    shortcut_key: ['k'],
    icon: '',
    show_in_omni_search: false,
  },
  {
    groupId: 1,
    name: 'Select conversation',
    action: '',
    shortcut_key: ['x'],
    icon: '',
    show_in_omni_search: false,
  },
  {
    groupId: 1,
    name: 'Tag last reply',
    action: 'tag_conversation',
    shortcut_key: ['t'],
    icon: 'TagMessage',
    show_in_omni_search: true,
  },
  {
    groupId: 1,
    name: 'Tag contact',
    action: 'tag_contact',
    shortcut_key: ['⇧', 't'],
    icon: 'TagContact',
    show_in_omni_search: true,
  },
  {
    groupId: 1,
    name: 'Hide/show left menu',
    action: 'toggle_filter_menu',
    shortcut_key: ['['],
    icon: '',
    show_in_omni_search: false,
  },
  {
    groupId: 1,
    name: 'Hide/show right menu',
    action: '',
    shortcut_key: [']'],
    icon: '',
    show_in_omni_search: false,
  },
  {
    groupId: 1,
    name: 'Hide/show events',
    action: '',
    shortcut_key: [os.ctrl, '⇧', 'e'],
    icon: '',
    show_in_omni_search: false,
  },
  {
    groupId: 1,
    name: 'Switch Inbox layout',
    action: 'toggle_inbox_layout',
    shortcut_key: ['l'],
    icon: 'SwitchInboxLayout',
    show_in_omni_search: true,
  },
];

export const NavigationShortcuts = [
  {
    groupId: 2,
    name: 'Go to assigned inbox',
    action: 'navigate_assigned_inbox',
    shortcut_key: [],
    icon: 'GoTo',
    show_in_omni_search: true,
  },
  {
    groupId: 2,
    name: 'Go to mentions inbox',
    action: 'navigate_to_mentioned_inbox',
    shortcut_key: [],
    icon: 'GoTo',
    show_in_omni_search: true,
  },
  {
    groupId: 2,
    name: 'Go to unassigned inbox',
    action: 'navigate_unassigned_inbox',
    shortcut_key: [],
    icon: 'GoTo',
    show_in_omni_search: true,
  },
  {
    groupId: 2,
    name: 'Go to all inbox',
    action: 'navigate_all_inbox',
    shortcut_key: [],
    icon: 'GoTo',
    show_in_omni_search: true,
  },
  {
    groupId: 2,
    name: 'Go to spam inbox',
    action: 'navigate_spam_inbox',
    shortcut_key: [],
    icon: 'GoTo',
    show_in_omni_search: true,
  },
  {
    groupId: 2,
    name: 'Go to contacts',
    action: 'navigate_contacts',
    shortcut_key: [],
    icon: 'GoTo',
    show_in_omni_search: true,
  },
  {
    groupId: 2,
    name: 'Go to messages',
    action: 'navigate_messages',
    shortcut_key: [],
    icon: 'GoTo',
    show_in_omni_search: true,
  },
  {
    groupId: 2,
    name: 'Go to workflows',
    action: 'navigate_workflows',
    shortcut_key: [],
    icon: 'GoTo',
    show_in_omni_search: true,
  },
  {
    groupId: 2,
    name: 'Go to knowledge base',
    action: 'navigate_knowledgebase',
    shortcut_key: [],
    icon: 'GoTo',
    show_in_omni_search: true,
  },
  {
    groupId: 2,
    name: 'Go to deals',
    action: 'navigate_deals',
    shortcut_key: [],
    icon: 'GoTo',
    show_in_omni_search: true,
  },
  {
    groupId: 2,
    name: 'Go to live view',
    action: 'navigate_liveview',
    shortcut_key: [],
    icon: 'GoTo',
    show_in_omni_search: () =>
      !fetchHiddenFeatures().includes(menu.liveView) &&
      !fetchRestrictedFeatures().includes(menu.liveView),
  },
  {
    groupId: 2,
    name: 'Go to forms',
    action: 'navigate_forms',
    shortcut_key: [],
    icon: 'GoTo',
    show_in_omni_search: true,
  },
  {
    groupId: 2,
    name: 'Go to workspace settings',
    action: 'navigate_settings',
    shortcut_key: [],
    icon: 'GoTo',
    show_in_omni_search: true,
  },
  {
    groupId: 2,
    name: 'Go to apps & integrations',
    action: 'navigate_integrations',
    shortcut_key: [],
    icon: 'GoTo',
    show_in_omni_search: true,
  },
];

export const CreationShortcuts = [
  {
    groupId: 3,
    name: 'New contact',
    action: 'create_contact',
    shortcut_key: [],
    icon: 'New',
    show_in_omni_search: true,
  },
  {
    groupId: 3,
    name: 'New conversation',
    action: 'create_message',
    shortcut_key: [],
    icon: 'New',
    show_in_omni_search: true,
  },
  {
    groupId: 3,
    name: 'New workflow',
    action: 'create_workflow',
    shortcut_key: [],
    icon: 'New',
    show_in_omni_search: true,
  },
  {
    groupId: 3,
    name: 'New article',
    action: 'create_article',
    shortcut_key: [],
    icon: 'New',
    show_in_omni_search: true,
  },
  {
    groupId: 3,
    name: 'New deal',
    action: 'create_deal',
    shortcut_key: [],
    icon: 'New',
    show_in_omni_search: true,
  },
  {
    groupId: 3,
    name: 'New form',
    action: 'create_form',
    shortcut_key: [],
    icon: 'New',
    show_in_omni_search: true,
  },
];

const HelpSupportShortcuts = [
  {
    groupId: 5,
    name: 'Open support center',
    action: 'help_center',
    url: 'https://docs.getgist.com',
    shortcut_key: [],
    icon: 'Help',
    show_in_omni_search: true,
  },
  {
    groupId: 5,
    name: 'Open API documentation',
    action: 'api',
    url: 'https://developers.getgist.com',
    shortcut_key: [],
    icon: 'API',
    show_in_omni_search: true,
  },
];

const ComposerShortcuts = [
  {
    groupId: 4,
    name: 'Compose reply',
    action: 'write_reply',
    shortcut_key: ['r'],
    icon: 'WriteReply',
    show_in_omni_search: true,
  },
  {
    groupId: 4,
    name: 'Compose note',
    action: 'add_note',
    shortcut_key: ['⇧', 'r'],
    icon: 'AddNote',
    show_in_omni_search: true,
  },
  {
    groupId: 4,
    name: 'Send',
    action: '',
    shortcut_key: [os.ctrl, 'enter'],
    icon: '',
    show_in_omni_search: false,
  },
  {
    groupId: 4,
    name: 'Send & close',
    action: '',
    shortcut_key: [os.ctrl, '⇧', 'enter'],
    icon: '',
    show_in_omni_search: false,
  },
  {
    groupId: 4,
    name: 'Send & snooze',
    action: '',
    shortcut_key: [os.ctrl, os.alt, 'enter'],
    icon: '',
    show_in_omni_search: false,
  },
  {
    groupId: 4,
    name: 'Insert attachment',
    action: 'upload_attachment',
    shortcut_key: [os.ctrl, '⇧', 'a'],
    icon: 'Attachment',
    show_in_omni_search: true,
  },
  {
    groupId: 4,
    name: 'Insert image',
    action: 'upload_image',
    shortcut_key: [os.ctrl, '⇧', 'i'],
    icon: 'Image',
    show_in_omni_search: true,
  },
  {
    groupId: 4,
    name: 'Insert snippet',
    action: 'snippet',
    shortcut_key: ['\\'],
    icon: 'InsertSnippet',
    show_in_omni_search: true,
  },
  {
    groupId: 4,
    name: 'Insert article',
    action: 'article',
    shortcut_key: [os.ctrl, '⇧', 'h'],
    icon: 'InsertArticle',
    show_in_omni_search: true,
  },
  {
    groupId: 4,
    name: 'Schedule meeting',
    action: 'meeting',
    shortcut_key: [os.ctrl, '⇧', 'm'],
    icon: 'Calendar',
    show_in_omni_search: () => {
      const { selectedChatRoomMessages } = store.getState().Chat;
      let chatRoomMessage = selectedChatRoomMessages;
      return socialMediaRestriction(chatRoomMessage);
    },
  },
  {
    groupId: 4,
    name: 'AI compose',
    action: 'ai_assist',
    shortcut_key: [os.ctrl, 'j'],
    icon: 'AiAssist',
    show_in_omni_search: () =>
      !fetchHiddenFeatures().includes(menu.aiAssist) &&
      !fetchRestrictedFeatures().includes(menu.aiAssist),
  },
  {
    groupId: 4,
    name: 'Bold',
    action: '',
    shortcut_key: [os.ctrl, 'b'],
    icon: '',
    show_in_omni_search: false,
  },
  {
    groupId: 4,
    name: 'Italic',
    action: '',
    shortcut_key: [os.ctrl, 'i'],
    icon: '',
    show_in_omni_search: false,
  },
  {
    groupId: 4,
    name: 'Underline',
    action: '',
    shortcut_key: [os.ctrl, 'u'],
    icon: '',
    show_in_omni_search: false,
  },
  {
    groupId: 4,
    name: 'Hyperlink',
    action: '',
    shortcut_key: [os.ctrl, 'k'],
    icon: '',
    show_in_omni_search: false,
  },
  {
    groupId: 4,
    name: 'Undo',
    action: '',
    shortcut_key: [os.ctrl, 'z'],
    icon: '',
    show_in_omni_search: false,
  },
  {
    groupId: 4,
    name: 'Redo',
    action: '',
    shortcut_key: [os.ctrl, '⇧', 'z'],
    icon: '',
    show_in_omni_search: false,
  },
];


export const shortcutsList = [
  ...GeneralShortcuts,
  ...ConversationShortcuts,
  ...NavigationShortcuts,
  ...CreationShortcuts,
  ...HelpSupportShortcuts,
  ...ComposerShortcuts,
];

export const translateActions = [
  {
    name: 'English',
    action: 'translate_to',
    lang_code: 'en',
  },
  {
    name: 'Korean',
    action: 'translate_to',
    lang_code: 'ko',
  },
  {
    name: 'Chinese',
    action: 'translate_to',
    lang_code: 'zh',
  },
  {
    name: 'Japanese',
    action: 'translate_to',
    lang_code: 'ja',
  },
  {
    name: 'Spanish',
    action: 'translate_to',
    lang_code: 'es',
  },
  {
    name: 'Russian',
    action: 'translate_to',
    lang_code: 'ru',
  },
  {
    name: 'French',
    action: 'translate_to',
    lang_code: 'fr',
  },
  {
    name: 'Portuguese',
    action: 'translate_to',
    lang_code: 'pt',
  },
  {
    name: 'German',
    action: 'translate_to',
    lang_code: 'de',
  },
  {
    name: 'Italian',
    action: 'translate_to',
    lang_code: 'it',
  },
  {
    name: 'Dutch',
    action: 'translate_to',
    lang_code: 'nl',
  },
  {
    name: 'Indonesian',
    action: 'translate_to',
    lang_code: 'id',
  },
  {
    name: 'Filipino',
    action: 'translate_to',
    lang_code: 'tl',
  },
  {
    name: 'Vietnamese',
    action: 'translate_to',
    lang_code: 'vi',
  },
];

export const toneActions = [
  {
    name: 'Professional',
    action: 'change_tone_to',
    tone: 'professional',
  },
  {
    name: 'Helpful',
    action: 'change_tone_to',
    tone: 'helpful',
  },
  {
    name: 'Straightforward',
    action: 'change_tone_to',
    tone: 'straightforward',
  },
  {
    name: 'Empathetic',
    action: 'change_tone_to',
    tone: 'empathetic',
  },
  {
    name: 'Friendly',
    action: 'change_tone_to',
    tone: 'friendly',
  },
];

export const MOST_USED_TAGS_LIMIT = 5;

export const inboxShortcutGroupId = [1, 4];

export const languagesMasterList = [
  { name: 'English', id: 1, key: 'en' },
  { name: 'Spanish', id: 2, key: 'es' },
  { name: 'French', id: 3, key: 'fr' },
  { name: 'German', id: 4, key: 'de' },
  { name: 'Russian', id: 5, key: 'ru' },
  { name: 'Polish', id: 6, key: 'pl' },
  { name: 'Hebrew', id: 7, key: 'he' },
  { name: 'Greek', id: 8, key: 'el' },
  { name: 'Turkish', id: 9, key: 'tr' },
  { name: 'Italian', id: 10, key: 'it' },
  { name: 'Bahasa (Indonesian)', id: 11, key: 'id' },
  { name: 'Norwegian', id: 12, key: 'nn' },
  { name: 'Danish', id: 13, key: 'da' },
  { name: 'Dutch', id: 14, key: 'nl' },
  { name: 'Bulgarian', id: 15, key: 'bg' },
  { name: 'Czech', id: 16, key: 'cs' },
  { name: 'Slovak', id: 17, key: 'sk' },
  { name: 'Portuguese', id: 18, key: 'pt' },
  { name: 'Swedish', id: 19, key: 'sv' },
  { name: 'Croatian', id: 20, key: 'hr' },
  { name: 'Bosnian', id: 21, key: 'bs' },
  { name: 'Chinese (Simplified)', id: 22, key: 'zh-hans' },
  { name: 'Chinese (Traditional)', id: 23, key: 'zh-hant' },
  { name: 'Arabic', id: 24, key: 'ar' },
  { name: 'Estonian', id: 25, key: 'et' },
  { name: 'Finnish', id: 26, key: 'fi' },
  { name: 'Hungarian', id: 27, key: 'hu' },
  { name: 'Latvian', id: 28, key: 'lv' },
  { name: 'Lithuanian', id: 29, key: 'lt' },
  { name: 'Mongolian', id: 30, key: 'mn' },
  { name: 'Serbian', id: 31, key: 'sr' },
  { name: 'Slovenian', id: 32, key: 'sl' },
  { name: 'Japanese', id: 33, key: 'ja' },
  { name: 'Korean', id: 34, key: 'ko' },
  { name: 'Vietnamese', id: 35, key: 'vi' },
  { name: 'Thai', id: 36, key: 'th' },
  { name: 'Malay', id: 37, key: 'ms' },
  { name: 'Catalan', id: 38, key: 'ca' },
  { name: 'Afrikaans', id: 39, key: 'af' },
  { name: 'Hindi', id: 40, key: 'hi' },
  { name: 'Albanian', id: 41, key: 'sq' },
  { name: 'Bengali', id: 42, key: 'bn' },
  { name: 'Icelandic', id: 43, key: 'is' },
  { name: 'Georgian', id: 44, key: 'ge' },
];
