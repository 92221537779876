import { all, call, put, takeEvery } from 'redux-saga/effects';
import actions from './actions';
import settings from '../../../settings';
import {
  deletePromise,
  fetchProjectSecretKey,
  getPromise,
  postPromise,
  patchPromise,
  updateBrowserHistory,
  resetWorkflowLoader,
  unAuthorisedRedirection,
  isTemplateEnabled,
  trackEvent,
  unProcessableEntity,
  getQueryParamsValue,
} from '../../../helpers/utility';
import { message } from 'antd';
import { store } from '../../../redux/store';
import {
  deletePlaceholder,
  extractNodesAndEdges,
  insertForkNode,
  insertNode,
  refresh,
  setAnalytics,
  updateNode,
  updateTriggerNode,
  extractNodes,
  handleHighlightConnections,
  updateSkipNodeStatus,
  insertSplitNode,
} from '../../../containers/Automation/Workflow/helper';
import ReactDOM from 'react-dom';
import { JsPlumbToolkitMiniviewComponent } from 'jsplumbtoolkit-react';
import React from 'react';
import { filterVariables } from '../../constants';
import filterActions from '../../filters/actions';
import workflowTemplateActions from '../workflowTemplate/actions';
import emailActions from '../../email/actions';
import broadcastActions from '../../email/broadcast/actions';
import { delay } from 'redux-saga';
import { discardedPathConfig } from '../../../containers/Automation/Workflow/helper';
export function* fetchWorkflowList(params) {
  try {
    let searchValue = '';
    let sortKey = '';
    let sortValue = '';
    let status = '';
    if (params.searchValue) {
      searchValue = params.searchValue;
    } else {
      searchValue = store.getState().Workflow.searchValue;
    }
    if (params.status) {
      status = params.status;
    } else {
      status = store.getState().Workflow.workflowStatus;
    }
    if (params.sortKey && params.sortOrder) {
      sortKey = params.sortKey;
      sortValue = params.sortOrder;
    } else {
      sortKey = store.getState().Workflow.sortKey;
      sortValue = store.getState().Workflow.sortValue;
    }
    const url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/workflows?status=${status}&sort_key=${sortKey}&sort_type=${sortValue}&search_text=${encodeURIComponent(
      searchValue,
    )}`;
    const data = yield call(() => getPromise(url));
    yield put({
      type: actions.FETCH_WORKFLOW_LIST_SUCCESS,
      payload: data.data,
    });
  } catch (error) {
    unAuthorisedRedirection(error);
    message.error('Fetch workflow list failed');
  }
}

export function* createWorkflow(params) {
  try {
    yield put({
      type: workflowTemplateActions.SET_LOADER,
      payload: {
        useTemplateLoading: true,
      },
    });
    const url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/workflows`;
    const data = yield call(() => postPromise(url, params.object));
    trackEvent('Created workflow');
    yield put({
      type: actions.SET_WORKFLOW_ID,
      id: data.data.workflow.id,
    });
    yield put({
      type: workflowTemplateActions.SET_LOADER,
      payload: {
        useTemplateLoading: false,
      },
    });
    message.success('The workflow has been created');
    updateBrowserHistory(`workflows/${data.data.workflow.id}`);
    yield put({
      type: actions.CREATE_WORKFLOW_SUCCESS,
    });
  } catch (error) {
    resetWorkflowLoader(false);
    unAuthorisedRedirection(error);
    yield put({
      type: workflowTemplateActions.SET_LOADER,
      payload: {
        useTemplateLoading: false,
      },
    });
    yield put({
      type: actions.CREATE_WORKFLOW_FAILURE,
      payload: params.object.workflow.name,
    });
    yield put({
      type: workflowTemplateActions.SET_CHOOSE_WORKFLOW_TEMPLATE_SEARCH_VALUE,
      payload: '',
    });
    yield put({
      type: workflowTemplateActions.SET_FILTER_CATEGORY_ID,
      payload: '',
    });
    if (error.response && error.response.status === 422) {
      message.error(error.response.data.errors.join());
    } else {
      message.error('The workflow creation has been failed');
    }
  }
}

export function* deleteWorkflow(params) {
  try {
    const url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/workflows/${params.id}`;
    yield call(() => deletePromise(url));
    if (params.isNotHome) {
      updateBrowserHistory('workflows');
      store.dispatch({
        type: actions.SET_WORKFLOW_DETAIL,
        payload: null,
      });
    } else {
      yield put({
        type: actions.FETCH_WORKFLOW_LIST,
        status: store.getState().Workflow.workflowStatus,
      });
    }
    message.success('Workflow deleted successfully');
  } catch (error) {
    resetWorkflowLoader(false);
    unAuthorisedRedirection(error);
    if (error.response && error.response.status === 422) {
      message.error(error.response.data.errors.join());
    } else {
      message.error('Delete workflow failed');
    }
  }
}

export function* appendWorkflowList(params) {
  try {
    let searchValue = '';
    let sortKey = '';
    let sortValue = '';
    let status = '';
    const { payload } = params;
    if (params.searchValue) {
      searchValue = params.searchValue;
    } else {
      searchValue = store.getState().Workflow.searchValue;
    }
    if (
      store.getState().Workflow.sortKey &&
      store.getState().Workflow.sortValue
    ) {
      sortKey = store.getState().Workflow.sortKey;
      sortValue = store.getState().Workflow.sortValue;
    }
    let lastWorkflowID = '';
    if (payload && payload.data.lastWorkflowID) {
      lastWorkflowID = payload.data.lastWorkflowID;
    }
    if (params.status) {
      status = params.status;
    } else {
      status = store.getState().Workflow.workflowStatus;
    }
    const url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/workflows?status=${status}&sort_key=${sortKey}&sort_type=${sortValue}&search_text=${encodeURIComponent(
      searchValue,
    )}&last_workflow_id=${lastWorkflowID}`;
    const data = yield call(() => getPromise(url));
    yield put({
      type: actions.APPEND_WORKFLOW_LIST_SUCCESS,
      payload: data.data,
    });
  } catch (error) {
    unAuthorisedRedirection(error);
    message.error('Fetch workflows failed');
  }
}

export function* fetchTriggerList(params) {
  try {
    const url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/workflow_triggers`;
    const data = yield call(() => getPromise(url));
    yield put({
      type: actions.FETCH_WORKFLOW_TRIGGER_LIST_SUCCESS,
      payload: data.data,
    });
  } catch (error) {
    unAuthorisedRedirection(error);
    message.error('Fetch triggered list failed');
  }
}

export function* fetchActionList(params) {
  try {
    const url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/wf_actions`;
    const data = yield call(() => getPromise(url));
    yield put({
      type: actions.FETCH_WORKFLOW_ACTION_LIST_SUCCESS,
      payload: data.data,
    });
  } catch (error) {
    unAuthorisedRedirection(error);
    message.error('Fetch action list failed');
  }
}

export function* fetchCampaignList(params) {
  try {
    const url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/campaigns_list`;
    const data = yield call(() => getPromise(url));
    yield put({
      type: actions.FETCH_WORKFLOW_CAMPAIGN_LIST_SUCCESS,
      payload: data.data,
    });
  } catch (error) {
    unAuthorisedRedirection(error);
    yield put({
      type: actions.FETCH_WORKFLOW_CAMPAIGN_LIST_FAILURE,
    });
    message.error('Fetch campaign list failed');
  }
}

export function* fetchFormList(params) {
  try {
    const url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/forms_list`;
    const data = yield call(() => getPromise(url));
    yield put({
      type: actions.FETCH_WORKFLOW_FORM_LIST_SUCCESS,
      payload: data.data,
    });
  } catch (error) {
    unAuthorisedRedirection(error);
    yield put({
      type: actions.FETCH_WORKFLOW_FORM_LIST_FAILURE,
    });
    message.error('Fetch form list failed');
  }
}

export function* fetchEmailList(params) {
  let campaignID = '';
  if (params.campaignID) {
    campaignID = params.campaignID;
  }
  try {
    const url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/emails_list?mail_type=${
      params.mailType
    }&campaign_id=${campaignID}`;
    const data = yield call(() => getPromise(url));
    yield put({
      type: actions.FETCH_WORKFLOW_EMAIL_LIST_SUCCESS,
      payload: { data: data.data, type: params.mailType },
    });
  } catch (error) {
    unAuthorisedRedirection(error);
    message.error('Fetch email list failed');
  }
}

export function* createComponent(params) {
  try {
    let workflowID = store.getState().Workflow.workflowID;
    if (workflowID === '') {
      workflowID = window.location.href.split('/').reverse()[0];
    }
    const url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/workflows/${workflowID}/components`;
    const data = yield call(() => postPromise(url, params.object));
    message.success('Component has been created');
    yield put({
      type: actions.DELAY_CREATED,
      payload: { isEdited: false, isAppliedExistingContact: 'disabled' },
    });
    if (params.object.workflow_component.component_type === 'trigger') {
      yield put({
        type: actions.SET_WORKFLOW_TRIGGER_FORM_DRAWER_STATUS,
        payload: { status: false, data: null },
      });
      yield put({
        type: actions.SET_WORKFLOW_TRIGGER_MATCHES_A_CUSTOM_AUDIENCE,
        payload: { status: false, data: null },
      });
      store.dispatch({
        type: filterActions.SET_FILTERS,
        payload: [],
        filterName: filterVariables.workflowTriggerKey,
      });
      updateTriggerNode(data.data.workflow_components, 'add');
      refresh();
    } else if (params.object.workflow_component.component_type === 'fork') {
      yield put({
        type: actions.SET_WORKFLOW_FORK_DRAWER_STATUS,
        payload: false,
      });
      deletePlaceholder();
      insertForkNode(
        data.data.workflow_components,
        params.object.workflow_component.add_direction,
      );
      refresh();
    } else if (
      params.object.workflow_component.component_type === 'primary_split'
    ) {
      yield put({
        type: actions.SET_WORKFLOW_SPLIT_DRAWER_STATUS,
        payload: false,
        data: null,
      });
      insertSplitNode(
        data.data.workflow_components,
        params.object.workflow_component.add_direction,
      );
    } else if (params.object.workflow_component.component_type === 'delay') {
      yield put({
        type: actions.SET_WORKFLOW_DELAY_DRAWER_STATUS,
        payload: { status: false, data: null },
      });
      store.dispatch({
        type: filterActions.SET_FILTERS,
        payload: [],
        filterName: filterVariables.delayKey,
      });
      deletePlaceholder();
      insertNode(data.data.workflow_components[0]);
      refresh();
    } else if (params.object.workflow_component.component_type === 'decision') {
      yield put({
        type: actions.SET_WORKFLOW_DECISION_DRAWER_STATUS,
        payload: { status: false, data: null, drawerData: {} },
      });
      store.dispatch({
        type: filterActions.SET_FILTERS,
        payload: [],
        filterName: filterVariables.workflowDecisionKey,
      });
      deletePlaceholder();
      insertForkNode(
        data.data.workflow_components,
        params.object.workflow_component.add_direction,
      );
      refresh();
    } else if (params.object.workflow_component.component_type === 'exit') {
      window.toolkit.clear();
      store.dispatch({ type: actions.FETCH_WORKFLOW_EDITOR_COMPONENTS });
      yield put({
        type: actions.SET_WORKFLOW_EXIT_DRAWER_STATUS,
        payload: false,
      });
    } else if (params.object.workflow_component.component_type === 'goto') {
      window.toolkit.clear();
      store.dispatch({ type: actions.FETCH_WORKFLOW_EDITOR_COMPONENTS });
      yield put({
        type: actions.SET_WORKFLOW_GOTO_DRAWER_STATUS,
        payload: false,
      });
    } else if (params.object.workflow_component.component_type === 'goal') {
      yield put({
        type: actions.SET_WORKFLOW_GOAL_DRAWER,
        payload: { status: false, data: null },
      });
      store.dispatch({
        type: filterActions.SET_FILTERS,
        payload: [],
        filterName: filterVariables.goalKey,
      });
      deletePlaceholder();
      insertNode(data.data.workflow_components[0]);
      refresh();
    } else {
      yield put({
        type: actions.SET_WORKFLOW_ACTION_CREATE_AND_EDIT_DRAWER_STATUS,
        payload: { status: false, data: null },
      });

      if (
        params.object.workflow_component.properties.action_type ===
        'send_an_email'
      ) {
        yield put({
          type: actions.RESET_BROADCAST_DETAILS,
        });
        yield put({
          type: broadcastActions.RESET_EMAIL_COMPOSER_FORM,
        });
        yield put({
          type: broadcastActions.SET_SELECTED_TEMPLATE_ID,
          templateId: '',
        });
        yield put({
          type: filterActions.FILTER_FETCH_FILTERS_DROP_DOWN,
        });
      }
      if (
        params.object.workflow_component.properties.action_type ===
        'send_chat_message'
      ) {
        yield put({
          type: actions.SET_WORKFLOW_CHAT_DRAWER_STATUS,
          payload: { status: false, data: null, chatDetails: null },
        });
      }
      if (
        params.object.workflow_component.properties.action_type ===
        'send_announcement_message'
      ) {
        yield put({
          type: actions.SET_WORKFLOW_POST_DRAWER_STATUS,
          payload: { status: false, data: null, postDetails: null },
        });
      }
      if (
        params.object.workflow_component.properties.action_type ===
        'trigger_webhook'
      ) {
        yield put({
          type: actions.UPDATE_TRIGGER_SELECTED_WEBHOOK_DATA,
          payload: {},
        });
      }
      if (
        params.object.workflow_component.properties.action_type ===
        'track_custom_event'
      ) {
        yield put({
          type: actions.UPDATE_ACTION_SELECTED_TRACK_CUSTOM_EVENT_DATA,
          payload: [],
        });
      }
      store.dispatch({
        type: actions.WORKFLOW_CREATE_BROADCAST_DRAWER,
        payload: false,
      });
      store.dispatch({
        type: broadcastActions.SET_BROADCAST_NAME,
        payload: {
          name: '',
          type: '',
        },
      });
      store.dispatch({
        type: actions.EMAIL_ACTION_VISIBLE,
        payload: false,
      });
      deletePlaceholder();
      insertNode(data.data.workflow_components[0]);
      refresh();
    }
    setAnalytics(
      JSON.parse(localStorage.getItem('isDisplayedWorkflowAnalytics')),
    );
    setTimeout(() => {
      resetWorkflowLoader(false);
    }, 1000);
  } catch (error) {
    setTimeout(() => {
      resetWorkflowLoader(false);
    }, 1000);
    unAuthorisedRedirection(error);
    if (error.response && error.response.status === 422) {
      message.error(error.response.data.errors.join());
    } else {
      message.error('Component creation failed');
    }
  }
}

export function* updateComponent(params) {
  try {
    const { object, componentID } = params;
    const { component_type } = object.workflow_component;
    let workflowID = store.getState().Workflow.workflowID;
    if (workflowID === '') {
      workflowID = window.location.href.split('/').reverse()[0];
    }
    const url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/workflows/${workflowID}/components/${componentID}`;
    const data = yield call(() => patchPromise(url, object));
    message.success('Component has been updated');
    yield put({
      type: actions.SET_ALL_COMPONENTS_CONFIGURED,
      payload: data.data.all_components_configured,
    });
    yield put({
      type: actions.DELAY_CREATED,
      payload: { isEdited: false, isAppliedExistingContact: 'disabled' },
    });
    if (component_type === 'trigger') {
      yield put({
        type: actions.SET_WORKFLOW_TRIGGER_FORM_DRAWER_STATUS,
        payload: { status: false, data: null },
      });
      yield put({
        type: actions.SET_WORKFLOW_TRIGGER_MATCHES_A_CUSTOM_AUDIENCE,
        payload: { status: false, data: null },
      });
      store.dispatch({
        type: filterActions.SET_FILTERS,
        payload: [],
        filterName: filterVariables.workflowTriggerKey,
      });
      updateTriggerNode(data.data.workflow_component, 'update');
      refresh();
    } else if (component_type === 'action') {
      if (
        params.object.workflow_component.properties.action_type ===
        'send_an_email'
      ) {
        // yield put({
        //   type: filterActions.FILTER_FETCH_FILTERS_DROP_DOWN,
        // });
        yield put({
          type: broadcastActions.RESET_EMAIL_COMPOSER_FORM,
        });
        yield put({
          type: broadcastActions.SET_SELECTED_TEMPLATE_ID,
          templateId: '',
        });
        store.dispatch({
          type: actions.RESET_BROADCAST_DETAILS,
        });
      }
      if (
        params.object.workflow_component.properties.action_type ===
        'send_chat_message'
      ) {
        // yield put({
        //   type: actions.SET_WORKFLOW_CHAT_DRAWER_STATUS,
        //   payload: { status: false, data: null, chatDetails: null },
        // });
      }
      if (
        params.object.workflow_component.properties.action_type ===
        'send_announcement_message'
      ) {
        // yield put({
        //   type: actions.SET_WORKFLOW_POST_DRAWER_STATUS,
        //   payload: { status: false, data: null, postDetails: null },
        // });
      }
      // yield put({
      //   type: actions.EMAIL_ACTION_VISIBLE,
      //   payload: false,
      // });
      if (
        ![
          'send_chat_message',
          'send_an_email',
          'send_announcement_message',
        ].includes(params.object.workflow_component.properties.action_type)
      ) {
        yield put({
          type: actions.SET_WORKFLOW_ACTION_CREATE_AND_EDIT_DRAWER_STATUS,
          payload: { status: false, data: null },
        });
      }
      if (
        params.object.workflow_component.properties.action_type ===
        'trigger_webhook'
      ) {
        yield put({
          type: actions.UPDATE_TRIGGER_SELECTED_WEBHOOK_DATA,
          payload: {},
        });
      }
      if (
        params.object.workflow_component.properties.action_type ===
        'track_custom_event'
      ) {
        yield put({
          type: actions.UPDATE_ACTION_SELECTED_TRACK_CUSTOM_EVENT_DATA,
          payload: [],
        });
      }
      updateNode(data.data.workflow_component);
    } else if (component_type === 'delay') {
      yield put({
        type: actions.SET_WORKFLOW_DELAY_DRAWER_STATUS,
        payload: { status: false, data: null },
      });
      store.dispatch({
        type: filterActions.SET_FILTERS,
        payload: [],
        filterName: filterVariables.delayKey,
      });
      updateNode(data.data.workflow_component);
    } else if (component_type === 'decision') {
      yield put({
        type: actions.SET_WORKFLOW_DECISION_DRAWER_STATUS,
        payload: { status: false, data: null, drawerData: {} },
      });
      store.dispatch({
        type: filterActions.SET_FILTERS,
        payload: [],
        filterName: filterVariables.workflowDecisionKey,
      });
      updateNode(data.data.workflow_component);
      if (params.callback) {
        params.callback();
      }
    } else if (component_type === 'goal') {
      yield put({
        type: actions.SET_WORKFLOW_GOAL_DRAWER,
        payload: { status: false, data: null },
      });
      store.dispatch({
        type: filterActions.SET_FILTERS,
        payload: [],
        filterName: filterVariables.goalKey,
      });
      updateNode(data.data.workflow_component);
    } else if (component_type === 'primary_split') {
      window.toolkit.clear();
      yield put({ type: actions.FETCH_WORKFLOW_EDITOR_COMPONENTS });
      yield put({
        type: actions.SET_WORKFLOW_SPLIT_DRAWER_STATUS,
        payload: false,
        data: null,
      });
      updateNode(data.data.workflow_component);
    }
    setTimeout(() => {
      resetWorkflowLoader(false);
    }, 1000);
  } catch (error) {
    setTimeout(() => {
      resetWorkflowLoader(false);
    }, 1000);
    unAuthorisedRedirection(error);
    if (error.response && error.response.status === 422) {
      message.error(error.response.data.errors.join());
    } else {
      message.error('Component update failed');
    }
  }
}

export function* getTagList() {
  try {
    const url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/tags_list`;
    const data = yield call(() => getPromise(url));
    yield put({
      type: actions.FETCH_WORKFLOW_TAG_LIST_SUCCESS,
      payload: data.data,
    });
  } catch (error) {
    unAuthorisedRedirection(error);
    yield put({
      type: actions.FETCH_WORKFLOW_TAG_LIST_FAILURE,
    });
    message.error('Fetch tag list failed');
  }
}

export function* getWorkflowList(params) {
  try {
    const url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/workflows_list?skip_workflow_id=${
      params.id
    }`;
    const data = yield call(() => getPromise(url));
    yield put({
      type: actions.FETCH_ACTION_WORKFLOW_LIST_SUCCESS,
      payload: data.data,
    });
  } catch (error) {
    unAuthorisedRedirection(error);
    yield put({
      type: actions.FETCH_ACTION_WORKFLOW_LIST_FAILURE,
    });
    message.error('Fetch workflow list failed');
  }
}

export function* getEmailLinkList(params) {
  try {
    let mailType = '';
    if (params.mailType) {
      mailType = params.mailType;
    }
    let queryParams = '';
    if (params.emailID) {
      queryParams += `&mail_filter_id=${params.emailID}`;
    }
    if (params.campaignID) {
      queryParams += `&campaign_id=${params.campaignID}`;
    }
    const url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/email_links?mail_type=${mailType}${queryParams}`;
    const data = yield call(() => getPromise(url));
    yield put({
      type: actions.FETCH_WORKFLOW_EMAIL_LINK_LIST_SUCCESS,
      payload: data.data.links,
    });
  } catch (error) {
    unAuthorisedRedirection(error);
    message.error('Fetch email links list failed');
  }
}

export function* fetchProperties(params) {
  try {
    let url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/properties_list`;
    let data = yield call(() => getPromise(url));
    yield put({
      type: actions.FETCH_WORKFLOW_PROPERTIES_SUCCESS,
      payload: data.data,
    });
  } catch (error) {
    unAuthorisedRedirection(error);
    yield put({
      type: actions.FETCH_WORKFLOW_PROPERTIES_FAILURE,
    });
    message.error('Properties fetch failed');
  }
}

export function* fetchPropertiesMetaData() {
  try {
    let url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/property-meta-data`;
    let data = yield call(() => getPromise(url));
    yield put({
      type: actions.FETCH_WORKFLOW_PROPERTIES_META_DATA_SUCCESS,
      payload: data.data,
    });
  } catch (error) {
    unAuthorisedRedirection(error);
    message.error('Properties meta data fetch failed');
  }
}

export function* getTeammateList(params) {
  try {
    let url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/teammates_list`;
    let data = yield call(() => getPromise(url));
    yield put({
      type: actions.FETCH_WORKFLOW_TEAMMATE_LIST_SUCCESS,
      payload: data.data,
      idSet: params.idSet ? params.idSet : undefined,
    });
  } catch (error) {
    unAuthorisedRedirection(error);
    message.error('Teammate details fetch failed');
  }
}

export function* getUsersList() {
  try {
    let url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/teams-users-list?include_current_user=true&sort_by=email`;
    let data = yield call(() => getPromise(url));
    yield put({
      type: actions.FETCH_WORKFLOW_USERS_LIST_SUCCESS,
      payload: data.team_mates,
    });
  } catch (error) {
    unAuthorisedRedirection(error);
    message.error('User details fetch failed');
  }
}

export function* fetchEditorComponents() {
  try {
    let workflowID = store.getState().Workflow.workflowID;
    let workflowTemplateID =
      store.getState().WorkflowTemplateReducer.workflowTemplateID;
    let workflowType = '',
      id = '';
    if (isTemplateEnabled()) {
      workflowType = 'workflow_templates';
      id = workflowTemplateID;
    } else {
      workflowType = 'workflows';
      id = workflowID;
    }
    if (id === '') {
      id = window.location.href.split('/').reverse()[0];
    }
    let url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/${workflowType}/${id}`;
    let data = yield call(() => getPromise(url));
    let workflowTypeData =
      workflowType === 'workflows'
        ? data.data.workflow
        : data.data.workflow_template;
    if (workflowType === 'workflows') {
      yield put({
        type: actions.SET_ALL_COMPONENTS_CONFIGURED,
        payload: data.data.all_components_configured,
      });
    }
    const graphData = extractNodesAndEdges(workflowTypeData);
    window.toolkit.load({ data: graphData });
    setTimeout(() => {
      const gotoComponents = workflowTypeData.non_roots.filter(
        (gotoComponent) => gotoComponent.component_type === 'goto',
      );
      gotoComponents.forEach((gotoComponent) => {
        const {disabled, discarded, goto} = gotoComponent.properties;
        if (gotoComponent.id && goto) {
          window.instance.connect({
            source: `goto-${gotoComponent.id}`,
            target: `goto-${goto}`,
            ...(disabled || discarded ? discardedPathConfig : {})
          });
        }
      });
    });
    ReactDOM.render(
      <JsPlumbToolkitMiniviewComponent surface={window.surface} />,
      document.querySelector('.mini-view'),
    );
    window.surface.centerOn(window.toolkit.getNodes()[0].id, {
      horizontal: true,
      vertical: false,
      doNotAnimate: true,
    });
    yield put({
      type: actions.SET_WORKFLOW_DETAIL,
      payload: workflowTypeData,
    });
    setAnalytics(
      JSON.parse(localStorage.getItem('isDisplayedWorkflowAnalytics')),
    );
    const flowId = getQueryParamsValue('flow_id');
    if (flowId !== 'null') {
      yield put({
        type: actions.FETCH_WORKFLOW_PERSON_ACTIVITIES,
        workflowId: id,
        flowId: flowId,
        isFromResumeTest: true,
      });
    }
  } catch (error) {
    unAuthorisedRedirection(error);
    message.error('Workflow editor data fetch failed');
  }
}

export function* deleteEditorComponent(params) {
  try {
    const { data, node } = params.payload,
      componentId = node.data.id;
    let workflowID = store.getState().Workflow.workflowID;
    if (workflowID === '') {
      workflowID = window.location.href.split('/').reverse()[0];
    }
    let queryPrams = `?removable_type=${data.removalType}`;
    if (data.removalType === 'retain_specific_path' && data.pathID) {
      queryPrams += `&path_id=${data.pathID}`;
    }
    if (node.data.type === 'trigger') {
      queryPrams = '';
    }
    let url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/workflows/${workflowID}/components/${componentId}${queryPrams}`;
    yield call(() => deletePromise(url));
    yield put({
      type: actions.SET_WORKFLOW_COMPONENT_DELETE_DRAWER_STATUS,
      payload: { status: false, data: null },
    });
    if (node.type === 'trigger') {
      updateTriggerNode(node, 'delete');
      refresh();
    } else {
      window.instance.deleteConnectionsForElement(
        window.instance.getSelector(`#goto-${componentId}`),
      );
      window.toolkit.clear();
      yield put({ type: actions.FETCH_WORKFLOW_EDITOR_COMPONENTS });
    }
    yield put({
      type: actions.DELETE_WORKFLOW_EDITOR_COMPONENT_SUCCESS,
    });
    message.success('Workflow editor component deleted successfully');
  } catch (error) {
    setTimeout(() => {
      resetWorkflowLoader(false);
    }, 1000);
    unAuthorisedRedirection(error);
    if (error.response && error.response.status === 422) {
      message.error(error.response.data.errors.join());
    } else {
      message.error('Workflow editor component deletion failed');
    }
  }
}

export function* getCustomFieldList() {
  try {
    let url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/custom_fields_list`;
    let data = yield call(() => getPromise(url));
    yield put({
      type: actions.FETCH_WORKFLOW_CUSTOM_FIELD_LIST_SUCCESS,
      payload: data.data,
    });
  } catch (error) {
    unAuthorisedRedirection(error);
    message.error('Custom fields fetch failed');
  }
}

export function* updateWorkflow(params) {
  let object = {
    workflow: {},
  };
  if (params.status) {
    object.workflow.status = params.status;
  }
  let workflowID = '';
  if (params.workflowID) {
    workflowID = params.workflowID;
  } else {
    workflowID = store.getState().Workflow.workflowID;
  }
  if (params.name) {
    object.workflow.name = params.name;
  }
  try {
    let url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/workflows/${workflowID}`;
    let data = yield call(() => patchPromise(url, object));
    if (params.status) {
      if (params.status === 'live') {
        message.success('Workflow has been started');
      } else {
        message.success('Workflow has been stopped');
      }
      yield put({
        type: actions.UPDATE_WORKFLOW_STATUS_CHANGE_LOADER,
        payload: false,
      });
    }
    if (params.name) {
      message.success('Workflow name updated successfully');
      yield put({
        type: actions.UPDATE_WORKFLOW_NAME_CHANGE_LOADER,
        payload: false,
      });
    }
    yield put({
      type: actions.UPDATE_WORKFLOW_SUCCESS,
      payload: data.data.workflow,
    });
  } catch (error) {
    yield put({
      type: actions.UPDATE_WORKFLOW_STATUS_CHANGE_LOADER,
      payload: false,
    });
    yield put({
      type: actions.UPDATE_WORKFLOW_NAME_CHANGE_LOADER,
      payload: false,
    });
    unAuthorisedRedirection(error);
    if (error.response && error.response.status === 422) {
      message.error(error.response.data.errors.join());
    } else {
      message.error('Workflow update failed');
    }
  }
}

export function* updateWorkflowTemplate(params) {
  try {
    let url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/workflow_templates/${params.id}`;
    let data = yield call(() => patchPromise(url, params.payload));
    yield put({
      type: actions.UPDATE_WORKFLOW_TEMPLATE_SUCCESS,
      payload: data.data.workflow_template,
    });
    message.success(data.message);
  } catch (error) {
    unAuthorisedRedirection(error);
    yield put({
      type: actions.UPDATE_WORKFLOW_TEMPLATE_FAILURE,
      changeStatus: params.changeStatus,
    });
    if (error.response && error.response.status === 422) {
      message.error(error.response.data.errors);
    } else {
      message.error('Workflow template update failed');
    }
  }
}

export function* duplicateWorkflow(params) {
  try {
    let url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/workflows/${params.id}/duplicate`;
    let data = yield call(() => postPromise(url, params.payload));
    yield put({
      type: actions.DUPLICATE_WORKFLOW_SUCCESS,
    });
    updateBrowserHistory(`workflows/${data.data.workflow.id}`);
    message.success(data.message);
  } catch (error) {
    unAuthorisedRedirection(error);
    yield put({
      type: actions.DUPLICATE_WORKFLOW_FAILURE,
    });
    message.error(error.response.data.errors);
  }
}

export function* fetchTemplates() {
  try {
    const url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/mail_filters/templates_list`;
    const data = yield call(() => getPromise(url));
    yield put({
      type: actions.WORKFLOW_FETCH_TEMPLATES_SUCCESS,
      payload: data.data,
    });
  } catch (error) {
    unAuthorisedRedirection(error);
    message.error('Fetch template failed');
  }
}

export function* editBroadCastDetails(params) {
  try {
    const url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/mail_filters/${
      params.id
    }/automation-mail-details`;
    const data = yield call(() => getPromise(url));
    yield put({
      type: actions.EDIT_BROADCAST_DETAILS_SUCCESS,
      payload: data.data,
      data: params.data,
    });
    yield put({
      type: broadcastActions.SET_BROADCAST_NAME,
      payload: {
        name: data.data.title,
        type: data.data.composer_type,
      },
    });
    yield put({
      type: broadcastActions.SET_EMAIL_TEMPLATES_DETAILS,
      payload: data.data,
    });
    yield put({
      type: broadcastActions.SET_SELECTED_TEMPLATE_ID,
      templateId:
        data.data.template_id || data.data.visual_builder_template_name,
    });
    yield put({
      type: actions.SET_SELECTED_TEMPLATE_CONTENT,
      payload: data.data.template_content,
    });
    if (data.data.composer_type === 'visual_builder') {
      yield put({
        type: broadcastActions.UPDATE_VISUAL_BUILDER_JSON,
        payload: data.data.visual_builder_json,
      });
    }
    store.dispatch({
      type: actions.EMAIL_ACTION_VISIBLE,
      payload: true,
    });
    store.dispatch({
      type: actions.FETCH_WORKFLOW_TEAMMATE_LIST,
    });
    store.dispatch({
      type: emailActions.CLEAR_PREVIEW_EMAIL_DATA,
    });
  } catch (error) {
    unAuthorisedRedirection(error);
    message.error('Fetch mail details failed');
  }
}

export function* validateEmailName(params) {
  try {
    const url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/mail_filters/validate_name?title=${
      params.title
    }`;
    const data = yield call(() => getPromise(url));
    if (store.getState().BroadcastEmail.openTemplateDrawer) {
      if (data.data.valid_name) {
        yield put({
          type: broadcastActions.OPEN_BROADCAST_TEMPLATE_DRAWER,
          payload: false,
        });
        store.dispatch({
          type: actions.EMAIL_ACTION_VISIBLE,
          payload: true,
        });
        store.dispatch({
          type: actions.WORKFLOW_CREATE_BROADCAST_DRAWER,
          payload: false,
        });
        store.dispatch({
          type: actions.VALIDATE_EMAIL_NAME_SUCCESS,
        });
      } else {
        store.dispatch({
          type: actions.VALIDATE_EMAIL_NAME_FAILURE,
        });
        message.error(data.data.error_message);
      }
    }
  } catch (error) {
    unAuthorisedRedirection(error);
    if (error.response && error.response.status === 422) {
      message.error(error.response.data.errors.join());
    } else {
      message.error('Email validation failed');
    }
  }
}

export function* getProductLists(params) {
  try {
    let url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/products_list`;
    if (params.storeId) {
      url += `?store_id=${params.storeId}`;
    }
    if (params.categoryId) {
      url += `&category_id=${params.categoryId}`;
    }
    if (params.page) {
      url += `&page=${params.page}`;
    }
    if (store.getState().Workflow.productSearch) {
      url += `&search_text=${store.getState().Workflow.productSearch}`;
    }
    let data = yield call(() => getPromise(url));
    if (params.page) {
      yield put({
        type: actions.UPDATE_PRODUCTS_LIST_SUCCESS,
        payload: data.data,
      });
    } else {
      yield put({
        type: actions.GET_PRODUCTS_LIST_SUCCESS,
        payload: data.data,
      });
    }
  } catch (error) {
    unAuthorisedRedirection(error);
    message.error('Products list fetch failed');
  }
}

export function* getProductCategoriesList(params) {
  try {
    let url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/product_categories_list`;
    if (params.storeId) {
      url += `?store_id=${params.storeId}`;
    }
    if (params.page) {
      url += `&page=${params.page}`;
    }
    if (store.getState().Workflow.categorySearch) {
      url += `&search_text=${store.getState().Workflow.categorySearch}`;
    }
    let data = yield call(() => getPromise(url));
    if (params.page) {
      yield put({
        type: actions.UPDATE_PRODUCTS_CATEGORIES_LIST_SUCCESS,
        payload: data.data,
      });
    } else {
      yield put({
        type: actions.GET_PRODUCTS_CATEGORIES_LIST_SUCCESS,
        payload: data.data,
      });
    }
  } catch (error) {
    unAuthorisedRedirection(error);
    message.error('Product categories list failed');
  }
}

export function* getWorkflowChatDetails(params) {
  try {
    const url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/triggered_chats/${params.id}`;
    const data = yield call(() => getPromise(url));
    yield put({
      type: actions.GET_WORKFLOW_CHAT_MESSAGE_DETAILS_SUCCESS,
      payload: data.data.triggered_chat,
    });
  } catch (error) {
    unAuthorisedRedirection(error);
    yield put({
      type: actions.GET_WORKFLOW_CHAT_MESSAGE_DETAILS_FAILURE,
    });
    message.error('Fetch chats failed');
  }
}
export function* validateChatMessageName(params) {
  try {
    const url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/triggered_chats/validate_name?name=${
      params.data.name
    }`;
    const data = yield call(() => getPromise(url));
    if (data.data.valid_name) {
      let value = {
        name: params.data.name,
        message: '<p>Hi {{ contact.first_name }},<br/></p>',
        message_type: 'workflow_one_off_chat',
        template: 'notification',
        reply_type: 'none',
        assign_to: null,
        triggered_user_id: null,
        isCreate: true,
      };
      yield put({
        type: actions.CREATE_WORKFLOW_CHAT_MESSAGE_SUCCESS,
        payload: value,
      });
    } else {
      message.error(data.data.error_message);
      yield put({ type: actions.CREATE_WORKFLOW_CHAT_MESSAGE_FAILURE });
    }
  } catch (error) {
    unAuthorisedRedirection(error);
    yield put({ type: actions.CREATE_WORKFLOW_CHAT_MESSAGE_FAILURE });
    if (error.response && error.response.status === 422) {
      message.error(error.response.data.errors);
    } else {
      message.error('Create chat failed');
    }
  }
}

export function* validatePostMessageName(params) {
  try {
    const url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/triggered_chats/validate_name?name=${
      params.data.name
    }`;
    const data = yield call(() => getPromise(url));
    if (data.data.valid_name) {
      let value = {
        name: params.data.name,
        message: '<p>Hi {{ contact.first_name }},<br/></p>',
        message_type: 'send_announcement_message',
        template: 'small',
        assign_to: null,
        triggered_user_id: null,
        isCreate: true,
      };
      yield put({
        type: actions.CREATE_WORKFLOW_POST_MESSAGE_SUCCESS,
        payload: value,
      });
    } else {
      message.error(data.data.error_message);
      yield put({ type: actions.CREATE_WORKFLOW_POST_MESSAGE_FAILURE });
    }
  } catch (error) {
    unAuthorisedRedirection(error);
    yield put({ type: actions.CREATE_WORKFLOW_POST_MESSAGE_FAILURE });
    if (error.response && error.response.status === 422) {
      message.error(error.response.data.errors);
    } else {
      message.error('Create post failed');
    }
  }
}

export function* getWorkflowPostDetails(params) {
  try {
    const url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/triggered_chats/${params.id}`;
    const data = yield call(() => getPromise(url));
    yield put({
      type: actions.GET_WORKFLOW_POST_MESSAGE_DETAILS_SUCCESS,
      payload: data.data.triggered_chat,
    });
  } catch (error) {
    unAuthorisedRedirection(error);
    yield put({
      type: actions.GET_WORKFLOW_POST_MESSAGE_DETAILS_FAILURE,
    });
    message.error('Fetch post failed');
  }
}

export function* getHubSpotDetails(params) {
  try {
    const url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/settings/integration/hubspot_app/hubspot_details?wf_action=${
      params.payload
    }`;
    const data = yield call(() => getPromise(url));
    yield put({
      type: actions.GET_WORKFLOW_ACTIONS_HUBSPOT_DETAILS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    unAuthorisedRedirection(error);
    yield put({ type: actions.GET_WORKFLOW_ACTIONS_HUBSPOT_DETAILS_FAILURE });
  }
}

export function* getMessages(params) {
  try {
    const url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/triggered_chat_list?message_type=${
      params.messageType
    }`;
    const { data } = yield call(() => getPromise(url));
    yield put({
      type: actions.GET_MESSAGES_SUCCESS,
      payload: data,
    });
  } catch (error) {
    unAuthorisedRedirection(error);
    yield put({ type: actions.GET_MESSAGES_FAILURE });
  }
}
export function* getSurvey() {
  try {
    const url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/surveys_list?survey_type=on_going`;
    const { data } = yield call(() => getPromise(url));
    yield put({
      type: actions.GET_SURVEY_SUCCESS,
      payload: data,
    });
  } catch (error) {
    unAuthorisedRedirection(error);
    yield put({ type: actions.GET_SURVEY_FAILURE });
  }
}

export function* getTours() {
  try {
    const url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/tours_list?tour_type=on_going`;
    const { data } = yield call(() => getPromise(url));
    yield put({
      type: actions.GET_TOUR_SUCCESS,
      payload: data,
    });
  } catch (error) {
    unAuthorisedRedirection(error);
  }
}

export function* getForms() {
  try {
    const url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/forms_list`;
    const { data } = yield call(() => getPromise(url));
    yield put({
      type: actions.GET_FORM_SUCCESS,
      payload: data,
    });
  } catch (error) {
    unAuthorisedRedirection(error);
  }
}

export function* getWorkflows(params) {
  try {
    let url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/workflows_list`;
    if (params.skipWorkflowId) {
      url += `?skip_workflow_id=${params.skipWorkflowId}`;
    }
    const { data } = yield call(() => getPromise(url));
    yield put({
      type: actions.GET_WORKFLOW_SUCCESS,
      payload: data,
    });
  } catch (error) {
    unAuthorisedRedirection(error);
  }
}

export function* getBots(params) {
  try {
    const url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/bots_list?bot_type=${params.botType}`;
    const { data } = yield call(() => getPromise(url));
    yield put({
      type: actions.GET_BOTS_SUCCESS,
      payload: data,
      botType: params.botType,
    });
  } catch (error) {
    unAuthorisedRedirection(error);
  }
}

export function* getStages() {
  try {
    const url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/stages_list`;
    const { data } = yield call(() => getPromise(url));
    yield put({
      type: actions.GET_DEAL_STAGES_SUCCESS,
      payload: data,
    });
  } catch (error) {
    unAuthorisedRedirection(error);
  }
}
export function* startTroubleShoot(params) {
  try {
    const url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/workflows/${
      params.workflowId
    }/trigger_workflow?trigger_type=troubleshoot&person_id=${
      params.personId
    }&action_type=start_troubleshoot&skip_existing_flow=true`;
    const { data } = yield call(() => postPromise(url));
    if (data.workflow_person) {
      window.history.pushState(
        {},
        '',
        `${window.location.href}?flow_id=${data.workflow_person.id}&test_workflow=true`,
      );
      yield put({
        type: actions.START_TROUBLESHOOT_SUCCESS,
        workflowId: data.workflow_person.id,
      });
      yield put({
        type: actions.SET_CURRENT_TROUBLESHOOT_ID,
      });
    }
  } catch (error) {
    unAuthorisedRedirection(error);
    unProcessableEntity(error, 'Start TroubleShoot failed');
  }
}

export function* getPersonFlows(params) {
  try {
    const url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/workflows/${
      params.workflowId
    }/fetch_person_workflows?trigger_type=troubleshoot&person_id=${
      params.personId
    }`;
    const { data } = yield call(() => getPromise(url));
    yield put({
      type: actions.FETCH_PERSON_FLOWS_SUCCESS,
      payload: data.workflow_person,
    });
  } catch (error) {
    unAuthorisedRedirection(error);
    unProcessableEntity(error, 'Fetch person flows failed');
  }
}

export function* getWorkflowPersonActivities(params) {
  try {
    const url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/workflows/${
      params.workflowId
    }/workflow_person_activities?flow_id=${params.flowId}`;
    const { data } = yield call(() => getPromise(url));
    yield put({
      type: actions.FETCH_WORKFLOW_PERSON_ACTIVITIES_SUCCESS,
      payload: data.workflow_component_people,
      flowId: params.flowId,
    });
    if (!params.isFromResumeTest) {
      window.history.pushState(
        {},
        '',
        `${window.location.href}?flow_id=${params.flowId}&test_workflow=true`,
      );
    }
    let completedNodes = [];
    const { updatedNodes } = store.getState().Workflow;
    data.workflow_component_people.map((activity) => {
      const getNode = updatedNodes.find(
        (node) => node.id === activity.workflow_component_id.toString(),
      );
      if (getNode) {
        const { toolkit } = window,
          nodeId = getNode.id;
        completedNodes.push(nodeId);
        toolkit.updateNode(nodeId, {
          ...getNode,
          data: { ...getNode.data, nodeStatus: activity.status },
        });
      }
      return activity;
    });
    handleHighlightConnections(completedNodes, 'add');
    refresh();
  } catch (error) {
    unAuthorisedRedirection(error);
    unProcessableEntity(error, 'Fetch workflow person activities failed');
  }
}

export function* skipComponent(params) {
  try {
    const url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/workflows/${
      params.workflowId
    }/trigger_workflow?trigger_type=troubleshoot&flow_id=${
      params.flowId
    }&action_type=skip_component&workflow_component_id=${params.componentId}`;
    const { data } = yield call(() => postPromise(url));
    updateSkipNodeStatus(params.loaderData);
    yield delay(settings.WORKFLOW_SKIP_DELAY_INTERVAL);
    yield put({
      type: actions.SKIP_COMPONENT_SUCCESS,
      payload: data,
    });
    if (params.isPreview) {
      yield put({
        type: actions.FETCH_WORKFLOW_PERSON_ACTIVITIES,
        workflowId: params.workflowId,
        flowId: params.flowId,
        isFromResumeTest: true,
      });
    }
  } catch (error) {
    unAuthorisedRedirection(error);
    unProcessableEntity(error, 'Skip component failed');
  }
}

export function* fetchWorkflowPeopleReport(params) {
  try {
    let url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/workflows/${
      params.workflowId
    }/workflow_people_report?search_value=${encodeURIComponent(params.searchText)}&status=${
      params.status
    }`;
    if (params.lastId) {
      url += `&last_id=${params.lastId}`;
    }
    const { data } = yield call(() => getPromise(url));
    if (params.lastId) {
      yield put({
        type: actions.APPEND_WORKFLOW_CONTACT_REPORT_SUCCESS,
        payload: data,
      });
    } else {
      yield put({
        type: actions.FETCH_WORKFLOW_CONTACT_REPORT_SUCCESS,
        payload: data,
      });
    }
  } catch (error) {
    yield put({
      type: actions.FETCH_WORKFLOW_CONTACT_REPORT_FAILURE,
    });
    unAuthorisedRedirection(error);
    unProcessableEntity(error, 'Fetch workflow people report failed');
  }
}

export function* fetchComponentPeopleReport(params) {
  try {
    let url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/workflows/${
      params.workflowId
    }/workflow_component_people_report?workflow_component_id=${
      params.componentId
    }&status=${params.status}&search_value=${encodeURIComponent(params.searchText)}`;
    if (params.lastId) {
      url += `&last_id=${params.lastId}`;
    }
    const { data } = yield call(() => getPromise(url));
    if (params.lastId) {
      yield put({
        type: actions.APPEND_COMPONENT_CONTACT_REPORT_SUCCESS,
        payload: data,
      });
    } else {
      yield put({
        type: actions.FETCH_COMPONENT_CONTACT_REPORT_SUCCESS,
        payload: data,
      });
    }
  } catch (error) {
    yield put({
      type: actions.FETCH_COMPONENT_CONTACT_REPORT_FAILURE,
    });
    unAuthorisedRedirection(error);
    unProcessableEntity(error, 'Fetch component people report failed');
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.FETCH_WORKFLOW_LIST, fetchWorkflowList),
    takeEvery(actions.APPEND_WORKFLOW_LIST, appendWorkflowList),
    takeEvery(actions.CREATE_WORKFLOW, createWorkflow),
    takeEvery(actions.DELETE_WORKFLOW, deleteWorkflow),
    takeEvery(actions.FETCH_WORKFLOW_TRIGGER_LIST, fetchTriggerList),
    takeEvery(actions.FETCH_WORKFLOW_ACTION_LIST, fetchActionList),
    takeEvery(actions.FETCH_WORKFLOW_CAMPAIGN_LIST, fetchCampaignList),
    takeEvery(actions.FETCH_WORKFLOW_FORM_LIST, fetchFormList),
    takeEvery(actions.FETCH_WORKFLOW_EMAIL_LIST, fetchEmailList),
    takeEvery(actions.CREATE_COMPONENT, createComponent),
    takeEvery(actions.UPDATE_COMPONENT, updateComponent),
    takeEvery(actions.FETCH_WORKFLOW_TAG_LIST, getTagList),
    takeEvery(actions.FETCH_ACTION_WORKFLOW_LIST, getWorkflowList),
    takeEvery(actions.FETCH_WORKFLOW_EMAIL_LINK_LIST, getEmailLinkList),
    takeEvery(actions.FETCH_WORKFLOW_PROPERTIES, fetchProperties),
    takeEvery(
      actions.FETCH_WORKFLOW_PROPERTIES_META_DATA,
      fetchPropertiesMetaData,
    ),
    takeEvery(actions.FETCH_WORKFLOW_TEAMMATE_LIST, getTeammateList),
    takeEvery(actions.FETCH_WORKFLOW_USERS_LIST, getUsersList),
    takeEvery(actions.FETCH_WORKFLOW_EDITOR_COMPONENTS, fetchEditorComponents),
    takeEvery(actions.DELETE_WORKFLOW_EDITOR_COMPONENT, deleteEditorComponent),
    takeEvery(actions.FETCH_WORKFLOW_CUSTOM_FIELD_LIST, getCustomFieldList),
    takeEvery(actions.UPDATE_WORKFLOW, updateWorkflow),
    takeEvery(actions.UPDATE_WORKFLOW_TEMPLATE, updateWorkflowTemplate),
    takeEvery(actions.DUPLICATE_WORKFLOW, duplicateWorkflow),
    takeEvery(actions.WORKFLOW_FETCH_TEMPLATES, fetchTemplates),
    takeEvery(actions.EDIT_BROADCAST_DETAILS, editBroadCastDetails),
    takeEvery(actions.VALIDATE_EMAIL_NAME, validateEmailName),
    takeEvery(actions.GET_PRODUCTS_LIST, getProductLists),
    takeEvery(actions.GET_PRODUCTS_CATEGORIES_LIST, getProductCategoriesList),
    takeEvery(
      actions.GET_WORKFLOW_CHAT_MESSAGE_DETAILS,
      getWorkflowChatDetails,
    ),
    takeEvery(actions.CREATE_WORKFLOW_CHAT_MESSAGE, validateChatMessageName),
    takeEvery(actions.CREATE_WORKFLOW_POST_MESSAGE, validatePostMessageName),
    takeEvery(
      actions.GET_WORKFLOW_POST_MESSAGE_DETAILS,
      getWorkflowPostDetails,
    ),
    takeEvery(actions.GET_WORKFLOW_ACTIONS_HUBSPOT_DETAILS, getHubSpotDetails),
    takeEvery(actions.GET_MESSAGES, getMessages),
    takeEvery(actions.GET_SURVEY, getSurvey),
    takeEvery(actions.GET_TOUR, getTours),
    takeEvery(actions.GET_FORM, getForms),
    takeEvery(actions.GET_WORKFLOW, getWorkflows),
    takeEvery(actions.GET_BOTS, getBots),
    takeEvery(actions.GET_DEAL_STAGES, getStages),
    takeEvery(actions.START_TROUBLESHOOT, startTroubleShoot),
    takeEvery(actions.FETCH_PERSON_FLOWS, getPersonFlows),
    takeEvery(
      actions.FETCH_WORKFLOW_PERSON_ACTIVITIES,
      getWorkflowPersonActivities,
    ),
    takeEvery(actions.SKIP_COMPONENT, skipComponent),
    takeEvery(actions.FETCH_WORKFLOW_CONTACT_REPORT, fetchWorkflowPeopleReport),
    takeEvery(
      actions.FETCH_COMPONENT_CONTACT_REPORT,
      fetchComponentPeopleReport,
    ),
  ]);
}
