import { store } from '../../redux/store';
import authActions from '../../redux/auth/actions';
import settings from '../../settings';

export const analyticsScripts = () => {
  const { isAnalyticsScriptInstalled } = store.getState().Auth;
  if (!isAnalyticsScriptInstalled) {

    // Userback script start
    window.Userback = window.Userback || {};
    window.Userback.access_token = settings.USER_BACK_TOKEN;
    // Userback script end

    // Scripts updated on reducer
    store.dispatch({
      type: authActions.UPDATE_ANALYTICS_TRACK_SCRIPTS,
      payload: true,
    });
  }
};
