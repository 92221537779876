import { generateFilterActions } from '../../components/Filters/helper';
const filterActions = generateFilterActions().actions;
const actions = {
  FETCH_GLOBAL_LANGUAGES: 'FETCH_GLOBAL_LANGUAGES',
  FETCH_GLOBAL_LANGUAGES_SUCCESS: 'FETCH_GLOBAL_LANGUAGES_SUCCESS',
  FILTER_FETCH_SEGMENT: 'FILTER_FETCH_SEGMENT',
  FILTER_FETCH_SEGMENTS_SUCCESS: 'FILTER_FETCH_SEGMENTS_SUCCESS',
  FILTER_ON_SEGMENT_CHANGE: 'FILTER_ON_SEGMENT_CHANGE',
  FILTER_FETCH_PROPERTIES: 'FILTER_FETCH_PROPERTIES',
  FILTER_FETCH_PROPERTIES_META_DATA: 'FILTER_FETCH_PROPERTIES_META_DATA',
  FILTER_FETCH_EVENTS_META_DATA: 'FILTER_FETCH_EVENTS_META_DATA',
  FILTER_FETCH_EVENTS: 'FILTER_FETCH_EVENTS',
  FILTER_FETCH_FILTERS_DROP_DOWN: 'FILTER_FETCH_FILTERS_DROP_DOWN',
  FILTER_FETCH_PROPERTIES_SUCCESS: 'FILTER_FETCH_PROPERTIES_SUCCESS',
  FILTER_FETCH_PROPERTY_META_DATA_SUCCESS:
    'FILTER_FETCH_PROPERTY_META_DATA_SUCCESS',
  FILTER_FILTERS_DROP_DOWN_SUCCESS: 'FILTER_FILTERS_DROP_DOWN_SUCCESS',
  FILTER_FETCH_EVENTS_META_DATA_SUCCESS:
    'FILTER_FETCH_EVENTS_META_DATA_SUCCESS',
  FILTER_FETCH_EVENTS_SUCCESS: 'FILTER_FETCH_EVENTS_SUCCESS',
  FILTER_CLEAR_FILTER: 'FILTER_CLEAR_FILTER',
  FILTER_FETCH_RECIPIENTS_COUNT: 'FILTER_FETCH_RECIPIENTS_COUNT',
  FILTER_CREATE_RECIPIENTS_SUCCESS: 'FILTER_CREATE_RECIPIENTS_SUCCESS',
  FILTER_RECIPIENTS_CREATE_SUCCESS: 'FILTER_RECIPIENTS_CREATE_SUCCESS',
  FILTER_FETCH_RECIPIENTS_SUCCESS: 'FILTER_FETCH_RECIPIENTS_SUCCESS',
  FILTER_SET_RECIPIENT_LOADER: 'FILTER_SET_RECIPIENT_LOADER',
  SET_FILTER_VALIDATION: 'SET_FILTER_VALIDATION',
  SET_FILTERS: 'SET_FILTERS',
  FILTER_ON_SUBSCRIPTION_CHANGE: 'FILTER_ON_SUBSCRIPTION_CHANGE',
  GOAL_FILTER_VISIBILITY: 'GOAL_FILTER_VISIBILITY',
  RESET_CONTACTS_AUDIENCE_FILTERS: 'RESET_CONTACTS_AUDIENCE_FILTERS',
  ...filterActions,
};

export default actions;
