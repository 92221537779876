import React from 'react';

export const messagesCount = 25;

export const BROADCAST_MAIL_TYPE = 'manual';
export const AUTO_EMAIL_TYPE = 'auto';
export const CAMPAIGN_TYPE = 'campaign';
export const AUTOMATION_TYPE = 'automation';
export const COUNTRY_URL = 'https://restcountries.com/v3.1/all?fields=name';

export const serverErrorCodes = [500, 501, 502, 503, 504, 505];

export const unAuthorizedErrorCode = 401;

export const OnBoardingUserState = {
  companyDetails: 'company_details',
  signUp: 'sign_up',
  planDetail: 'plan_details',
  gettingStarted: 'getting_started',
};

export const webURLReExp = /^(?:(?:https?|ftp):\/\/)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/\S*)?$/;

export const passwordRedExp = /[!~@#$%^&*]/;

export const publicRoutes = ['forgot-password', 'signup', 'sign-up', 'sign_up'];

export const allUsersFilters = [
  { criteria: [{ key: 'email', condition: 'has_any_value' }] },
];

export const templateContent =
  '<html>\n' +
  '  <head>\n' +
  '    <style type="text/css">\n' +
  "      /* Add your styles here, we'll process and inline all of your styles before sending */\n" +
  '    </style>\n' +
  '    <meta name="x-apple-disable-message-reformatting" />\n' +
  '  </head>\n' +
  '  <body>\n' +
  "    <!-- We'll replace this content tag with whatever you write in your email -->\n" +
  '    {{ content }}\n' +
  '    <br />\n' +
  '    <br />\n' +
  '\n' +
  '    <!--\n' +
  "      You can use the Gist's unsubscribe link in all of your emails.\n" +
  '      We are able to track the unsubscribe to the specific campaign they\n' +
  '      unsubscribed from.\n' +
  '    -->\n' +
  '    <a href="{{ unsubscribe_url }}">Unsubscribe</a>\n' +
  '  </body>\n' +
  '</html>';

export const visualBuilderTemplateContent = {
  page: {
    body: {
      container: {
        style: {
          'background-color': '#FFFFFF',
        },
      },
      content: {
        computedStyle: {
          linkColor: '#0068A5',
          messageBackgroundColor: 'transparent',
          messageWidth: '600px',
        },
        style: {
          color: '#000000',
          'font-family': "Arial, 'Helvetica Neue', Helvetica, sans-serif",
        },
      },
      type: 'mailup-bee-page-properties',
      webFonts: [],
    },
    description: '',
    rows: [
      {
        columns: [
          {
            'grid-columns': 12,
            modules: [
              {
                descriptor: {
                  computedStyle: {
                    hideContentOnDesktop: false,
                    hideContentOnMobile: false,
                  },
                  style: {
                    'padding-bottom': '30px',
                    'padding-left': '50px',
                    'padding-right': '50px',
                    'padding-top': '30px',
                  },
                  text: {
                    computedStyle: {
                      linkColor: '#0068A5',
                    },
                    html:
                      '<div class="txtTinyMce-wrapper" style="font-size: 12px; line-height: 14px; font-family: inherit;" data-mce-style="font-size: 12px; line-height: 14px; font-family: inherit;"><p style="font-size: 14px; line-height: 16px; text-align: center; word-break: break-word;" data-mce-style="font-size: 14px; line-height: 16px; text-align: center; word-break: break-word;"><strong><span style="font-size: 22px; line-height: 26px;" data-mce-style="font-size: 22px; line-height: 26px;">Title</span></strong></p></div>',
                    style: {
                      color: '#231F20',
                      'font-family': 'inherit',
                      'line-height': '120%',
                    },
                  },
                },
                locked: false,
                type: 'mailup-bee-newsletter-modules-text',
                uuid: '8aaa4cf4-d4a4-4e18-8b3a-b6ebf9f0b981',
              },
              {
                type: 'mailup-bee-newsletter-modules-text',
                descriptor: {
                  text: {
                    html:
                      '<div class="txtTinyMce-wrapper" style="font-size: 12px; line-height: 14px;" data-mce-style="font-size: 12px; line-height: 14px;"><p style="font-size: 12px; line-height: 14px; word-break: break-word;" data-mce-style="font-size: 12px; line-height: 14px;">Here you can create your own template</p></div>',
                    style: {
                      color: '#555555',
                      'line-height': '120%',
                      'font-family': 'inherit',
                    },
                    computedStyle: {
                      linkColor: '#0068A5',
                    },
                  },
                  style: {
                    'padding-top': '10px',
                    'padding-right': '10px',
                    'padding-bottom': '10px',
                    'padding-left': '10px',
                  },
                  computedStyle: {
                    hideContentOnMobile: false,
                  },
                  mobileStyle: {},
                },
                uuid: 'f0c25007-e74f-4cf0-bb8e-bc7a38606399',
                locked: false,
              },
            ],
            style: {
              'background-color': 'transparent',
              'border-bottom': '0px dotted transparent',
              'border-left': '0px dotted transparent',
              'border-right': '0px dotted transparent',
              'border-top': '0px dotted transparent',
              'padding-bottom': '0px',
              'padding-left': '0px',
              'padding-right': '0px',
              'padding-top': '30px',
            },
            uuid: '049d5c0d-329f-4e44-b37e-e22429196bfb',
          },
        ],
        container: {
          style: {
            'background-color': 'transparent',
            'background-image': 'none',
            'background-position': 'top left',
            'background-repeat': 'no-repeat',
          },
        },
        content: {
          computedStyle: {
            hideContentOnDesktop: false,
            hideContentOnMobile: false,
            rowColStackOnMobile: true,
            rowReverseColStackOnMobile: false,
            verticalAlign: 'top',
          },
          style: {
            'background-color': 'transparent',
            'background-image': 'none',
            'background-position': 'top left',
            'background-repeat': 'no-repeat',
            color: '#000000',
            width: '600px',
          },
        },
        empty: false,
        locked: false,
        synced: false,
        type: 'row-1-columns-12',
        uuid: '332d2f30-ae94-42a7-97e3-e9f72b8c630f',
      },
    ],
    template: {
      name: 'template-base',
      type: 'basic',
      version: '2.0.0',
    },
    title: '',
  },
};

export const AllowRoutesOnBlockedProjects = [
  'account',
  'change-password',
  'updates',
  'subscription',
  'change-plan',
];

export const dateTimeFormat = 'DD/MM/YYYY HH:mm';

export const invalidHours = [undefined, null, '', NaN];

export const setUpGuides = [
  'help_desk',
  'knowledge_base',
  'ai_chatbot',
  'email_marketing',
  'outbound_messages',
];

export const gistPlans = [
  'marketing_pro',
  'marketing_premium',
  'support_pro',
  'support_premium',
  'all_in_one_pro',
  'all_in_one_premium',
  'startup_annual',
];

export const startup = {
  additional_contacts_count_for_charging: null,
  additional_contacts_price: null,
  additional_teammate_price: null,
  allowed_contacts: 5000,
  allowed_teammates: 10,
  name: 'Startup Annual',
  period_type: 'yearly',
  price: 468.0,
  price_breakup: [],
  slug_name: 'startup_annual',
};

export const whiteLabelPlans = [
  'marketing_premium_gistmailt',
  // 'marketing_premium_femailsend',
]

export const pricePerUser = 9;

export const tagModules = {
  workflowTag: 'workflow',
  rulesTag: 'rules',
  importTag: 'importContacts',
};
//global filter variables
export const filterVariables = {
  contactsKey: 'contacts',
  broadcastKey: 'broadcast',
  autoEmailKey: 'autoEmail',
  campaignEntryKey: 'campaignEntry',
  campaignExitKey: 'campaignExit',
  rulesKey: 'rules',
  workflowTriggerKey: 'workflowTrigger',
  workflowDecisionKey: 'workflowDecision',
  splitKey: 'split',
  goalKey: 'goal',
  delayKey: 'delay',
  oneOffChatKey: 'oneOffChat',
  botDecisionKey: 'botDecision',
  supportBotKey: 'supportBot',
  oneOffSurveyKey: 'oneOffSurvey',
  messengerAppPreferences: 'messengerAppPreferences',
  broadcastKeyGoal: 'broadcastGoal',
  surveyKeyGoal: 'surveyGoal',
  tourKeyGoal: 'tourGoal',
  autoEmailKeyGoal: 'autoEmailGoal',
  campaignKeyGoal: 'campaignGoal',
  oneOffChatKeyGoal: 'oneOffChatGoal',
  oneOffPostKeyGoal: 'oneOffPostGoal',
  formKeyGoal: 'formGoal',
  botsKeyGoal: 'botsGoal',
};

export const filterActionNames = {
  campaignEntry: 'CAMPAIGN_ENTRY',
  campaignExit: 'CAMPAIGN_EXIT',
  broadcast: 'BROADCAST',
  autoEmail: 'AUTO_EMAIL',
  contacts: 'CONTACTS',
  rules: 'RULES',
  workflowTrigger: 'WORKFLOW_TRIGGER',
  workflowDecision: 'WORKFLOW_DECISION',
  goal: 'GOAL',
  delay: 'DELAY',
  split: 'SPLIT',
  oneOffChat: 'ONE_OFF_CHAT',
  botDecision: 'BOT_DECISION',
  supportBot: 'SUPPORT_BOT',
  oneOffSurvey: 'ONE_OFF_SURVEY',
  messengerAppPreferences: 'MESSENGER_APP_PREFERENCES',
  broadcastGoal: 'BROADCAST_GOAL',
  surveyGoal: 'SURVEY_GOAL',
  tourGoal: 'TOUR_GOAL',
  autoEmailGoal: 'AUTOEMAIL_GOAL',
  campaignGoal: 'CAMPAIGN_GOAL',
  oneOffChatGoal: 'ONEOFF_CHAT_GOAL',
  oneOffPostGoal: 'ONEOFF_POST_GOAL',
  formGoal: 'FORM_GOAL',
  botsGoal: 'BOTS_GOAL',
};

export const filterDefaultActions = [
  'FILTER_ADD_PROPERTY',
  'FILTER_ADD_EVENT',
  'FILTER_ADD_INTERNAL_PROPERTY',
  'FILTER_REMOVE_INTERNAL_PROPERTY',
  'UPDATE_FILTER_DATA',
  'RESET_DATA',
];

export const addedOptInFields = [
  {
    property_name: 'email',
    property_type: 'string',
    placeholder: 'Email',
    required: true,
  },
];

export const allInOneProfessional = {
  Inbox: {
    available: [
      'Gist Messenger',
      'Team inbox',
      'Inbound email',
      'Facebook Integration',
      'Twitter Integration',
      'iOS & Android apps',
      'Assign and snooze conversations',
      'Saved replies',
      'Office hours',
      'Customizable Messenger',
      'Messenger visibility',
      'Satisfaction ratings',
      'Chat transcripts',
      'Support 44 languages',
    ],
    unavailable: [
      'Message sneak peek',
      'Live view',
      'Conversation volume reports',
      'Response times reports',
      'Team performance reports',
    ],
  },
  'Bots & Inbox Automation': {
    available: ['Targeted outbound chat', 'Lead qualification bot'],
    unavailable: [
      'Custom Bots',
      'Meeting booking bot',
      'Smart suggestions',
      'Assignment rules',
      'Round robin assignment',
    ],
  },
  'Knowledge Base': {
    available: [
      'Unlimited articles & collections',
      'Article search in Messenger',
      '44+ languages supported',
      'Article reactions & feedback',
      'Share articles in conversations',
      'Custom domain',
      'Content importer',
    ],
    unavailable: ['Custom branding'],
  },
  Meetings: {
    available: [
      'One-on-one meetings',
      'Availability control',
      'Pre-scheduling form',
      'Automated event notifications',
      'Control your branding',
      'Website embed',
      'Zoom integration',
      'Metrics & reports',
    ],
    unavailable: [],
  },
  Contacts: {
    available: [
      'Contact management',
      'Website & event tracking',
      'Contact segments & tags',
      'Standard & custom properties',
      'Bulk edit contacts',
      'Custom events',
    ],
    unavailable: ['Automatic event tracking'],
  },
  Email: {
    available: [
      'Visual email builder',
      'One-off emails',
      'Ongoing emails',
      'Drip campaigns',
      'Pre-built email templates',
      'Email personalization',
      'DKIM & SPF authentication',
      'Email folders',
      'Auto resend to non-openers',
      'Double opt-in customization',
    ],
    unavailable: [
      'Conditional content',
      'Email delivery windows',
      'Send per Timezone',
      'Custom unsubscribe',
      'Dedicated IP',
      'Full email analytics',
    ],
  },
  Automation: {
    available: [
      'Simple automation rules',
      'Link click automation',
      'Notification emails',
      'Increment contact property values',
    ],
    unavailable: [
      'Multi-step visual automations builder',
      'Facebook & Instagram ads',
      'Abandoned cart series',
      'Audience targeting',
    ],
  },
  Forms: {
    available: [
      'Drag & drop popup builder',
      'Embed forms anywhere',
      'Trigger email automations',
      'Merge tags & text replacement',
      'Exit intent trigger',
      'Page-level targeting',
    ],
    unavailable: [
      'Segment targeting',
      'Geo-location targeting',
      'Advanced Analytics',
    ],
  },
  'Additional Features': {
    available: [
      '50+ apps & integrations',
      'Javascript & REST API',
      'Webhooks',
      'Free migration service',
    ],
    unavailable: [],
  },
};
export const allInOneProfessionalNo = {
  left: [0, 1, 2, 3],
  right: [4, 5, 6, 7, 8],
};
export const supportProfessional = {
  Inbox: {
    available: [
      'Gist Messenger',
      'Team inbox',
      'Inbound email',
      'Facebook Integration',
      'Twitter Integration',
      'iOS & Android apps',
      'Assign and snooze conversations',
      'Saved replies',
      'Office hours',
      'Customizable Messenger',
      'Messenger visibility',
      'Satisfaction ratings',
      'Chat transcripts',
      'Support 44 languages',
    ],
    unavailable: [
      'Message sneak peek',
      'Live view',
      'Conversation volume reports',
      'Response times reports',
      'Team performance reports',
    ],
  },
  'Bots & Inbox Automation': {
    available: ['Targeted outbound chat', 'Lead qualification bot'],
    unavailable: [
      'Custom Bots',
      'Meeting booking bot',
      'Smart suggestions',
      'Assignment rules',
      'Round robin assignment',
    ],
  },
  'Knowledge Base': {
    available: [
      'Unlimited articles & collections',
      'Article search in Messenger',
      '44+ languages supported',
      'Article reactions & feedback',
      'Share articles in conversations',
      'Custom domain',
      'Content importer',
    ],
    unavailable: ['Custom branding'],
  },
  Meetings: {
    available: [
      'One-on-one meetings',
      'Availability control',
      'Pre-scheduling form',
      'Automated event notifications',
      'Control your branding',
      'Website embed',
      'Zoom integration',
      'Metrics & reports',
    ],
    unavailable: [],
  },
  'Additional Features': {
    available: [
      '50+ apps & integrations',
      'Javascript & REST API',
      'Webhooks',
      'Free migration service',
    ],
    unavailable: [],
  },
};
export const supportProfessionalNo = {
  left: [0, 2],
  right: [1, 3, 4],
};
export const marketingProfessional = {
  Contacts: {
    available: [
      'Contact management',
      'Website & event tracking',
      'Contact segments & tags',
      'Standard & custom properties',
      'Bulk edit contacts',
      'Custom events',
    ],
    unavailable: ['Automatic event tracking'],
  },
  Email: {
    available: [
      'Visual email builder',
      'One-off emails',
      'Ongoing emails',
      'Drip campaigns',
      'Pre-built email templates',
      'Email personalization',
      'DKIM & SPF authentication',
      'Email folders',
      'Auto resend to non-openers',
      'Double opt-in customization',
    ],
    unavailable: [
      'Conditional content',
      'Email delivery windows',
      'Send per Timezone',
      'Custom unsubscribe',
      'Dedicated IP',
      'Full email analytics',
    ],
  },
  Automation: {
    available: [
      'Simple automation rules',
      'Link click automation',
      'Notification emails',
      'Increment contact property values',
    ],
    unavailable: [
      'Multi-step visual automations builder',
      'Facebook & Instagram ads',
      'Abandoned cart series',
      'Audience targeting',
    ],
  },
  Forms: {
    available: [
      'Drag & drop popup builder',
      'Embed forms anywhere',
      'Trigger email automations',
      'Merge tags & text replacement',
      'Exit intent trigger',
      'Page-level targeting',
    ],
    unavailable: [
      'Segment targeting',
      'Geo-location targeting',
      'Advanced Analytics',
    ],
  },
  'Additional Features': {
    available: [
      '50+ apps & integrations',
      'Javascript & REST API',
      'Webhooks',
      'Free migration service',
    ],
    unavailable: [],
  },
};
export const marketingProfessionalNo = {
  left: [0, 2, 4, 5],
  right: [1, 3],
};
export const allInOnePremium = {
  Inbox: {
    available: [
      'Gist Messenger',
      'Team inbox',
      'Inbound email',
      'Facebook Integration',
      'Twitter Integration',
      'iOS & Android apps',
      'Assign and snooze conversations',
      'Saved replies',
      'Office hours',
      'Customizable Messenger',
      'Messenger visibility',
      'Satisfaction ratings',
      'Chat transcripts',
      'Support 44 languages',
      'Message sneak peek',
      'Live view',
      'Conversation volume reports',
      'Response times reports',
      'Team performance reports',
    ],
    unavailable: [],
  },
  'Bots & Inbox Automation': {
    available: [
      'Targeted outbound chat',
      'Lead qualification bot',
      'Custom Bots',
      'Meeting booking bot',
      'Smart suggestions',
      'Assignment rules',
      'Round robin assignment',
    ],
    unavailable: [],
  },
  'Knowledge Base': {
    available: [
      'Unlimited articles & collections',
      'Article search in Messenger',
      '44+ languages supported',
      'Article reactions & feedback',
      'Share articles in conversations',
      'Custom domain',
      'Content importer',
      'Custom branding',
    ],
    unavailable: [],
  },
  Meetings: {
    available: [
      'One-on-one meetings',
      'Availability control',
      'Pre-scheduling form',
      'Automated event notifications',
      'Control your branding',
      'Website embed',
      'Zoom integration',
      'Metrics & reports',
    ],
    unavailable: [],
  },
  Contacts: {
    available: [
      'Contact management',
      'Website & event tracking',
      'Contact segments & tags',
      'Standard & custom properties',
      'Bulk edit contacts',
      'Custom events',
      'Automatic event tracking',
    ],
    unavailable: [],
  },
  Email: {
    available: [
      'Visual email builder',
      'One-off emails',
      'Ongoing emails',
      'Drip campaigns',
      'Pre-built email templates',
      'Email personalization',
      'DKIM & SPF authentication',
      'Email folders',
      'Auto resend to non-openers',
      'Double opt-in customization',
      'Conditional content',
      'Email delivery windows',
      'Send per Timezone',
      'Custom unsubscribe',
      'Dedicated IP',
      'Full email analytics',
    ],
    unavailable: [],
  },
  Automation: {
    available: [
      'Simple automation rules',
      'Link click automation',
      'Notification emails',
      'Increment contact property values',
      'Multi-step visual automations builder',
      'Facebook & Instagram ads',
      'Abandoned cart series',
      'Audience targeting',
    ],
    unavailable: [],
  },
  Forms: {
    available: [
      'Drag & drop popup builder',
      'Embed forms anywhere',
      'Trigger email automations',
      'Merge tags & text replacement',
      'Exit intent trigger',
      'Page-level targeting',
      'Segment targeting',
      'Geo-location targeting',
      'Advanced Analytics',
    ],
    unavailable: [],
  },
  'Additional Features': {
    available: [
      '50+ apps & integrations',
      'Javascript & REST API',
      'Webhooks',
      'Free migration service',
    ],
    unavailable: [],
  },
};
export const allInOnePremiumNo = {
  left: [0, 1, 2, 3],
  right: [4, 5, 6, 7, 8],
};
export const supportPremium = {
  Inbox: {
    available: [
      'Gist Messenger',
      'Team inbox',
      'Inbound email',
      'Facebook Integration',
      'Twitter Integration',
      'iOS & Android apps',
      'Assign and snooze conversations',
      'Saved replies',
      'Office hours',
      'Customizable Messenger',
      'Messenger visibility',
      'Satisfaction ratings',
      'Chat transcripts',
      'Support 44 languages',
      'Message sneak peek',
      'Live view',
      'Conversation volume reports',
      'Response times reports',
      'Team performance reports',
    ],
    unavailable: [],
  },
  'Bots & Inbox Automation': {
    available: [
      'Targeted outbound chat',
      'Lead qualification bot',
      'Custom Bots',
      'Meeting booking bot',
      'Smart suggestions',
      'Assignment rules',
      'Round robin assignment',
    ],
    unavailable: [],
  },
  'Knowledge Base': {
    available: [
      'Unlimited articles & collections',
      'Article search in Messenger',
      '44+ languages supported',
      'Article reactions & feedback',
      'Share articles in conversations',
      'Custom domain',
      'Content importer',
      'Custom branding',
    ],
    unavailable: [],
  },
  Meetings: {
    available: [
      'One-on-one meetings',
      'Availability control',
      'Pre-scheduling form',
      'Automated event notifications',
      'Control your branding',
      'Website embed',
      'Zoom integration',
      'Metrics & reports',
    ],
    unavailable: [],
  },
  'Additional Features': {
    available: [
      '50+ apps & integrations',
      'Javascript & REST API',
      'Webhooks',
      'Free migration service',
    ],
    unavailable: [],
  },
};
export const supportPremiumNo = {
  left: [0, 2],
  right: [1, 3, 4],
};
export const marketingPremium = {
  Contacts: {
    available: [
      'Contact management',
      'Website & event tracking',
      'Contact segments & tags',
      'Standard & custom properties',
      'Bulk edit contacts',
      'Custom events',
      'Automatic event tracking',
    ],
    unavailable: [],
  },
  Email: {
    available: [
      'Visual email builder',
      'One-off emails',
      'Ongoing emails',
      'Drip campaigns',
      'Pre-built email templates',
      'Email personalization',
      'DKIM & SPF authentication',
      'Email folders',
      'Auto resend to non-openers',
      'Double opt-in customization',
      'Conditional content',
      'Email delivery windows',
      'Send per Timezone',
      'Custom unsubscribe',
      'Dedicated IP',
      'Full email analytics',
    ],
    unavailable: [],
  },
  Automation: {
    available: [
      'Simple automation rules',
      'Link click automation',
      'Notification emails',
      'Increment contact property values',
      'Multi-step visual automations builder',
      'Facebook & Instagram ads',
      'Abandoned cart series',
      'Audience targeting',
    ],
    unavailable: [],
  },
  Forms: {
    available: [
      'Drag & drop popup builder',
      'Embed forms anywhere',
      'Trigger email automations',
      'Merge tags & text replacement',
      'Exit intent trigger',
      'Page-level targeting',
      'Segment targeting',
      'Geo-location targeting',
      'Advanced Analytics',
    ],
    unavailable: [],
  },
  'Additional Features': {
    available: [
      '50+ apps & integrations',
      'Javascript & REST API',
      'Webhooks',
      'Free migration service',
    ],
    unavailable: [],
  },
};
export const marketingPremiumNo = {
  left: [0, 2, 4, 5],
  right: [1, 3],
};

export const discountPlans = [
  'woocommerce-offer',
  'exclusive-offer',
  'blackfriday',
  'prime',
];

export const searchIcon = <i className={'fal fa-magnifying-glass'} />;

export const gistShopifyPlans = [
  'support_premium_shopify',
  'marketing_premium_shopify',
  'all_in_one_premium_shopify',
];
