import React, { Component } from 'react';
import { Layout, Menu } from 'antd';
import { NavLink } from 'react-router-dom';
import {
  fetchHiddenFeatures,
  fetchProjectSecretKey,
  fetchRestrictedFeatures,
  isPrimeCouponWorkspace,
} from '../../helpers/utility';
import '../Shared/SideBar/style.scss';
import { store } from '../../redux/store';
import actions from '../../redux/settings/actions';
import { sideBarData } from './constants';
import { menu } from '../TopNavBar/constants';

const { Sider } = Layout,
  { SubMenu, Item } = Menu;

class ReportSideBar extends Component {
  onMenuClick = e => {
    store.dispatch({
      type: actions.SET_SIDEBAR_ACTIVE,
      key: e.id,
    });
  };

  render() {
    const isSupportBotVisible = isPrimeCouponWorkspace()
      ? !fetchRestrictedFeatures().includes(menu.supportBot)
      : true;
    return (
      <Sider className={'events-sider report-sider'}>
        <div className={'wrapper'}>
          <div className={'header'}>
            <div className={'title-bar'}>
              <h3>Reports</h3>
            </div>
          </div>
          <div className={'body'}>
            <Menu
              mode="inline"
              style={{ borderRight: 0 }}
              defaultOpenKeys={['support', 'marketing']}
              selectedKeys={[
                ['teams', 'teammates'].includes(this.props.sideBarActiveKey)
                  ? 'team-performance'
                  : this.props.sideBarActiveKey,
              ]}
            >
              {sideBarData.support && (
                <SubMenu key={'support'} title={'Support'}>
                  {sideBarData.support.map(data => {
                    if (['Support bot', 'Emma'].includes(data.name) &&
                      (fetchHiddenFeatures().includes(menu.supportBot) ||
                      !isSupportBotVisible)) {
                      return null;
                    }
                    return (
                      <Item
                        key={data.id.toString()}
                        onClick={() => this.onMenuClick(data)}
                      >
                        <NavLink
                          to={`/projects/${fetchProjectSecretKey()}/reports/support/${
                            data.path
                          }`}
                        >
                          <div>
                            <span className={'title'}>{data.name}</span>
                          </div>
                        </NavLink>
                      </Item>
                    );
                  })}
                </SubMenu>
              )}

              {sideBarData.marketing && (
                <SubMenu key={'marketing'} title={'Marketing'}>
                  {sideBarData.marketing.map(data => (
                    <Item
                      key={data.id.toString()}
                      onClick={() => this.onMenuClick(data)}
                    >
                      <NavLink
                        to={`/projects/${fetchProjectSecretKey()}/reports/marketing/${
                          data.path
                        }`}
                      >
                        <div>
                          <span className={'title'}>{data.name}</span>
                        </div>
                      </NavLink>
                    </Item>
                  ))}
                </SubMenu>
              )}
            </Menu>
          </div>
        </div>
      </Sider>
    );
  }
}

export default ReportSideBar;
